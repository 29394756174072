.app-c-not-found {
  position: relative;
  padding-bottom: 100px !important;
  @include screen-sm {
    padding-bottom: 40px !important;
  }
  &::before {
    display: none !important;
  }
  .app-c-card {
    min-height: 244px;
    background: $white;
    opacity: 0.5;
    @include boxShadow(0px -5px 8px 0px rgba(93, 39, 48, 0.08));
    @include screen-sm() {
      display: none;
      &:first-child {
        display: block;
        min-height: 152px;
      }
    }
  }
}
.app-c-not-found__msg {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 300px;
  @extend %flex-all-center-col;
  height: 170px;
  padding-bottom: 30px;
  text-align: center;
  p {
    line-height: 24px;
    font-size: $text-lg;
    font-family: $font-primary;
    margin: 0;
    color: $text-muted-dark;
  }
}

.not-found-icon {
  width: 43px;
  height: 43px;
  @extend %flex-all-center-col;
  margin-bottom: 10px;
}
