html {
  @media screen and (max-width: 767px) {
    overflow: auto;
    width: 100%;
    height: 100%;
  }
}

body {
  @media screen and (max-width: 767px) {
    height: 100%;
    width: 100%;
    position: fixed;
    // /* prevent overscroll bounce*/
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    &.search-result-body{
      position: unset;
      overflow-y: unset;
      overflow-x: unset;
      &.mob-overflow{
        overflow: hidden;
      }
    }
  }
  @include screen-md{    
    &.search-result-body{
      &.mob-overflow{
        overflow: hidden;
      }
    }
  }
}
.container {
  max-width: 1260px;
  @media screen and (max-width: 1279px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.pageLayout {
  min-height: 100vh;
  @include flexbox;
  flex-direction: column;
}

.app-l-main-container {
  height: 100vh;
  overflow: auto;
  @extend %flexbox;

  @media screen and (max-width: 767px) {
    padding-left: 0px;
  }
}

.app-l-base {
  padding-left: 80px;
  position: relative;
  z-index: 1;

  .rtl &,
  [dir='rtl'] & {
    padding-left: 0px;
    padding-right: 80px;
  }

  &.app-l-full-height {
    height: calc(100%);
    width: 100%;
    min-width: 0;

    @extend %flex-and-col;

    @media screen and (max-width: 767px) {
      height: auto;
      min-height: 100%;
    }
  }
}

.app-l-vertical-content-page {
  background: #fcfcfe;
  height: 100%;
  min-height: 0px;
  padding: 30px 40px;
  @extend %flex-and-col;
}

.app-l-screen-header {
  min-height: 0px;
  padding: 15px 16px 12px 16px;

  @extend %flex-and-row;
  @include flex-grow(0);
  @include flex-shrink(0);

  &.app-l-screen-header--white {
    background: $white;
  }

  .app-l-screen-head-controls {
    margin-left: auto;
  }
}

.app-l-breadcrumb-holder {
  max-width: 100%;
}

.app-l-screen-head-controls {
  max-width: 100%;

  @extend %flex-align-center-justify-end-row;

  @extend %flex-wrap;

  .app-l-round-icon-btn {
    background: $white;
    border: solid 1px $app-brand-secondary;
    width: 24px;
    height: 24px;
    margin: 4px 0px 4px 8px;
    outline: none;

    @extend %inline-flex-all-center;
    @include flex-shrink();
    @include border-radius(50%);

    > i {
      font-size: 12px;
      color: $app-brand-secondary;
      margin: auto;

      @include transition(color 0.3s ease-in-out);
    }

    &:hover {
      background-color: $app-brand-secondary;

      i {
        color: $white;
      }
    }
  }
}

.app-l-screen-holder {
  max-width: 100%;
  height: 100%;
  min-height: 0;

  @extend %flex-and-col;
}

.app-l-screen-contents {
  max-width: 100%;
  height: 100%;
  min-height: 0;
  overflow: auto;
  padding: 0;

  @extend %flex-and-col;
}

.app-l-common-layout {
  background: $white;
  height: 100%;

  @include border-radius(6px);
  @extend %flex-and-col;

  .app-l-common-layout__body {
    padding: 16px 24px;
    overflow: auto;

    @media screen and (max-width: 767px) {
      padding: 12px 15px;
    }
  }

  .app-l-common-layout__footer {
    border-top: $generic-border-color 1px solid;
    padding: 12px 24px;
    margin-top: auto;

    @extend %flexbox;
    @include flex-shrink(0);
    @include justify-content(flex-end);
  }
}

.app-l-heading--h3 {
  color: $text-color;
  font-weight: 500;
  font-size: 20px;
  margin: 0 0 15px;
  padding: 0;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.app-l-heading--h4 {
  color: $text-color;
  font-weight: 500;
  font-size: 15px;
  margin: 0 0 15px;
  padding: 0;

  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
}

.flex-align-justify-center {
  @extend %flex-all-center;
}

.app-l-progress {
  background: $white;
  height: 100%;

  @include border-radius(4px);
  @extend %flex-and-col;

  .app-l-progress-head {
    padding: 16px 40px;
    border-bottom: $generic-border-color 1px solid;

    @extend %flex-all-center;

    .app-l-progress__h-block {
      width: 100%;
      position: relative;
      max-width: 350px;

      @extend %flex-align-end-col;

      &:first-child {
        width: 20px;

        .app-l-ph__path {
          display: none;
        }
      }

      .app-l-ph__path {
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        height: 2px;
        background: lighten(desaturate($app-brand-primary, 61.41), 65.49);
        overflow: hidden;

        &:before {
          content: '';
          width: 0%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: $app-brand-primary;

          @include transition(width ease 0.2s);
        }
      }

      .app-l-ph__box {
        width: 30px;
        height: 30px;
        border: 1px solid lighten(desaturate($app-brand-primary, 61.41), 65.49);
        font-size: 16px;
        color: $text-dark-medium;
        cursor: pointer;
        background: $white;
        position: relative;
        z-index: 1;

        @include border-radius(3px);
        @extend %flex-all-center;
        @include transition(border ease 0.2s, background ease 0.2s, color ease 0.2s);

        i {
          display: block;
          line-height: normal;
        }
      }

      .app-l-ph__label {
        font-weight: 500;
        font-size: 14px;
        line-height: normal;
        color: $text-dark-medium;
        width: 140px;
        margin-right: -53px;
        text-align: center;
        margin-top: 2px;

        @include transition(color ease 0.2s);

        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      &.select {
        .app-l-ph__box {
          color: $white;
          background: $app-brand-primary;
          border-color: $app-brand-primary;
        }

        .app-l-ph__path {
          &:before {
            width: 100%;
          }
        }

        .app-l-ph__label {
          color: $app-brand-primary;
        }
      }
    }
  }
}

.app-l-progress-body {
  padding: 16px;
  max-height: 100%;
  min-height: 0;
  overflow: auto;

  .app-l-progress__block {
    display: none;

    &.active {
      display: block;
    }
  }
}

.app-l-progress__general {
  max-width: 1300px;

  @extend %flexbox;

  @media screen and (max-width: 991px) {
    display: block;
  }

  .app-l-progress__left {
    width: 100%;
    padding-right: 15px;

    @media screen and (max-width: 991px) {
      padding-right: 0;
    }

    .app-l-gn__tp {
      @extend %flexbox;
      @extend %flex-wrap;

      .form-group {
        margin-right: 16px;

        @media screen and (max-width: 480px) {
          margin-right: 0;
        }
      }
    }

    .app-l-gn__bt {
      padding-top: 16px;
      max-width: 760px;
      border-top: lighten(desaturate($app-brand-primary, 61.41), 65.49) 1px solid;

      @extend %flexbox;
      @extend %flex-wrap;

      .form-group {
        margin-right: 16px;

        @media screen and (max-width: 480px) {
          margin-right: 0;
        }
      }
    }
  }

  .app-l-progress__right {
    width: 370px;
    padding-left: 32px;
    border-left: lighten(desaturate($app-brand-primary, 61.41), 65.49) 1px solid;

    @include flex-shrink(0);

    @media screen and (max-width: 991px) {
      border-top: lighten(desaturate($app-brand-primary, 61.41), 65.49) 1px solid;
      border-left: 0;
      padding-top: 32px;
      padding-left: 0;
      max-width: 100%;
    }
  }
}

.app-l-color__theme {
  @extend %flex-align-center;

  .app-l-color__select {
    margin-right: 12px;

    @include flex-shrink(0);
    @include align-self(flex-start);
  }

  .app-l-color__pre {
    width: 100%;

    @extend %flex-and-align-center;
    @extend %flex-wrap;

    .app-l-color__sample {
      width: 18px;
      height: 18px;
      display: block;
      cursor: pointer;
      margin-right: 12px;
      position: relative;

      @include border-radius(50%);
      @include transition(background ease 0.2s);

      &.yellow {
        background: #f9ae19;
      }

      &.orange {
        background: #f26e26;
      }

      &.blue {
        background: #2b5a9b;
      }

      &.blue {
        background: #7f15a5;
      }

      &.purple {
        background: #7f15a5;
      }

      &.default {
        background: $app-brand-primary;
      }

      &.green {
        background: #44ab66;
      }

      &.active {
        &:before {
          content: '';
          width: 24px;
          height: 24px;
          border: 1px solid darken(desaturate($app-brand-primary, 61.1), 7.25%);
          position: absolute;
          top: -3px;
          left: -3px;
        }
      }
    }
  }
}

.app-l-company__logo {
  margin-bottom: 16px;

  .app-c-file-upload {
    margin-bottom: 10px;
  }

  .app-l-company-logo__holder {
    display: inline-block;
    padding: 10px;
    border: 1px solid lighten(desaturate($app-brand-primary, 61.41), 65.49);
  }
}

.app-l-short-logo {
  margin-bottom: 16px;

  .app-c-file-upload {
    margin-bottom: 10px;
  }

  .app-l-short-logo__holder {
    width: 60px;
    height: 60px;
    padding: 10px;
    border: 1px solid lighten(desaturate($app-brand-primary, 61.41), 65.49);

    @include border-radius(50%);
    @extend %flex-all-center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.app-l-local__form-block {
  .app-l-local__form {
    @extend %flexbox;
    @extend %flex-wrap;

    .form-group {
      margin-right: 16px;

      @media screen and (max-width: 480px) {
        margin-right: 0;
      }
    }
  }
}

.app-l-progress-email {
  max-width: 500px;

  .app-l-progress-email__form {
    @extend %flexbox;
    @extend %flex-wrap;

    .form-group {
      margin-right: 16px;

      @media screen and (max-width: 480px) {
        margin-right: 0;
      }
    }
  }
}

.app-l-pr__check {
  .app-c-checkbox {
    margin-top: 20px;

    @media screen and (max-width: 480px) {
      margin-top: 0;
    }
  }
}

.app-l-no-data-area {
  @extend %inline-flex-align-center-justify-center-col;

  min-height: 78vh;
  height: 100%;
  width: 100%;

  .app-l-no-data-img-wrap {
    max-width: 100%;
    min-width: 0;

    @extend %flex-all-center;

    img {
      max-width: 150px;
      width: 150px;
      max-height: 150px;
      height: 150px;
      min-height: 0;

      @media screen and (max-width: 767px) {
        max-width: 100px;
        width: 100px;
        max-height: 100px;
        height: 100px;
      }
    }
  }

  .app-l-no-data-text {
    max-width: 100%;
    min-width: 0;
    margin-top: 24px;
    margin-bottom: 20px;

    @extend %flex-all-center-col;

    &.no-pad-top {
      padding-top: 0;
    }

    span {
      font-weight: 600;
      font-size: 22px;
      line-height: normal;
      text-align: center;
      color: #caccd3;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  &.app-l-dashboard-charts {
    .app-l-no-data-text {
      span {
        font-size: 20px;
        font-weight: 400;

        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
}

.app-l-card-stats {
  color: #3b4458;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
}

// Page style

.app-l-page__header {
  @extend %flex-and-align-center;
  margin-bottom: 30px;

  .app-l-page__header__left {
    margin-right: auto;
  }

  .app-l-page__header__right {
    margin-left: auto;
    text-align: right;

    .app-l-page__header__right__info-box {
      @extend %flex-and-align-center;
      position: relative;
      top: 5px;
    }
  }
}

.app-l-page__header-text {
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #362f55;
  margin-top: 0px;
  margin-bottom: 0px;
  @extend %inline-flex-and-align-center;
}

.app-l-page__sub-header-text {
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: #7a8092;
  margin-top: 8px;
  margin-bottom: 0px;
}

.app-l-page__action-btn-block {
  @extend %inline-flex-and-align-center;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.app-l-approve-driver {
  .tab-content {
    height: 100%;

    .tab-pane {
      height: 100%;

      .app-l-form-page__grid {
        height: 100%;
      }
    }
  }
}
.app-c-validation-message {
  font-size: 12px;
  color: red;
  text-align: left;
  padding-left: 17px;
  margin-top: 5px;
  display: block;
  font-weight: $font-weight-normal;
  // @include screen-md() {
  //   padding-left: 5px;
  // }
  @include screen-sm() {
    margin-top: 1px;
    // padding-left: 16px;
  }
  &:empty {
    display: none;
  }
}

.is-text-hidden-sm,.is-text-hidden-lg{
  margin: 0;
  font-family: $font-primary;
}
@media (max-width: 376px){
  .is-text-hidden-sm{
    display: none;
  }
}
@media (min-width: 376px){
  .is-text-hidden-lg{
    display: none;
  }
}