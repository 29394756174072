@mixin noShadow {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
}

@mixin boxShadow($shadow...) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  -o-box-shadow: $shadow;
}
