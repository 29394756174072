$form-disabled-custom-color: #e5e5e5;

.form-control {
  &::-webkit-input-placeholder {
    /* Edge */
    color: $text-placeholder;
    font-family: $font-primary;
    font-size: $text-lg;
    font-weight: $font-weight-medium;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $text-placeholder;
    font-family: $font-primary;
    font-size: $text-lg;
    font-weight: $font-weight-medium;
  }

  &::placeholder {
    color: $text-placeholder;
    font-family: $font-primary;
    font-size: $text-lg;
    font-weight: $font-weight-medium;
  }
}

.app-c-from-wrapper {
  @media (max-width: 550px) {
    &.service-wrapper {
      .service-wrapper-row {
        flex-direction: column;
        justify-content: flex-start;

        &.form-input-flex {
          margin: 0px;
          width: 100%;

          .app-c-input-box {
            margin: 15px 0px 0;
          }

          >div {
            width: inherit;
          }
        }

        .service {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &.no-border {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px 20px 15px;

    .app-c-input-box {
      border: none;
      filter: drop-shadow(0px 4px 8px rgba(93, 39, 48, 0.1));
      -webkit-filter: drop-shadow(0px 4px 8px rgba(93, 39, 48, 0.1));
    }
  }

  .create-table-dropdown {
    border: none;
    filter: drop-shadow(0px 4px 8px rgba(93, 39, 48, 0.1));
    -webkit-filter: drop-shadow(0px 4px 8px rgba(93, 39, 48, 0.1));
  }

  .create-table-dropdown .creatable-dropdown .css-yk16xz-control {
    font-weight: normal;
  }
}

.form-input-flex {
  @extend %flex-align-start-justify-start-row;
  position: relative;

  // margin: 0px -8px;
  @include screen-md() {
    &.basic-update {
      margin: 0;

      //flex-direction: column;
      .app-c-input-box {
        margin: 15px 0 0;
        width: 100%;
      }
    }
  }
  @include screen-xs{
    &.mob-flex-col-m0.form-input-flex.equal{
      flex-direction: column;
      .equal-split{
        + .equal-split{
          margin: 0;
        }
      }
    }
  }

  &.basic-update {
    &.-modal-form-flex {
      >div {
        flex: 1 1;
        width: 100%;

        + div{
          margin-left: 10px;
          @media (max-width: 991px){
            margin-left: 0;
          }
        }
      }
    }

    .app-c-input-box {
      margin-top: 15px;
      position: relative;
    }
  }

  &.equal {
    @include screen-md() {
      // margin: 0px -8px;
    }

    .app-c-input-box {
      flex: 1;
      position: relative;

      &+.app-c-input-box {
        margin-left: 8px;

        @include screen-md() {
          margin: 8px 0 0;
        }
      }
    }

    .equal-split {
      flex: 1;
      width: 100%;

      &+.equal-split {
        margin: 0 0 0 8px;

        @include screen-md() {
          margin: 2px;
        }
      }

      // @include screen-md() {
      //   margin: 8px 8px 0 0;
      // }
    }
  }

  .app-c-input-box {

    // margin: 0px 8px;
    &.width40 {
      flex-shrink: 1;
      flex-basis: 100%;
    }
  }
}
.app-c-input__box{
  padding: 0!important;
  .app-c-checkbox__item{
    width: 100%;
    height: max-content;
    padding: 8px 10px 7px 17px;
    display: flex;
    flex-direction: row;
    min-height: 64px;
    cursor: pointer;
  }
}
.app-c-input-box {
  border: 1px solid $generic-border-color;
  @include border-radius(16px);
  padding: 0;
  margin-top: 16px;
  background: $white;
  font-size: $text-lg;
  padding: 8px 10px 7px 17px;



  &.lg-height {
    min-height: 64px;
    @extend %align-start-justify-center-col;
  }

  &.md-height {
    min-height: 54px;
    @extend %align-start-justify-center-col;
  }

  &.space-between {
    @extend %flex-align-center-justify-space-between-row;

    @include screen-sm() {
      flex-wrap: wrap;
      // margin-bottom: 100px;
      margin-bottom: 20px;

      min-height: 66px;

      .app-c-btn {
        top: 28px;
        position: relative;
        margin: auto;
      }
    }
  }

  .app-c-input__label {
    line-height: 22px;
    margin: 0;
    font-size: $text-md;
    font-family: $font-secondary;
    color: $text-label;
    display: block;
  }

  .form-control {
    border: none;
    @include noShadow();
    padding: 0;
    height: 24px;
    font-size: $text-lg;
    line-height: 24px;
    font-weight: $font-weight-semibold;
    color: $text-color;

    &.report-text {
      height: 74px;
    }

    &::placeholder {
      color: $text-input;
      font-size: $text-md;
    }
  }
}

.app-c-mw-form--inputs {
  &__form-control {
    width: 100%;

    em {
      position: absolute;
      top: 28px;
      right: 10px;
      cursor: help;

      @include screen-sm() {
        top: 21px;
      }
    }

    .form-group {
      margin: 0;
    }

    .form-control {
      @include border-radius(16px);
      min-height: 64px;
      color: $text-color;
      padding-left: 24px;
      padding-right: 30px;
      font-size: $text-lg;
      filter: drop-shadow(0px 4px 8px rgba(93, 39, 48, 0.1));
      -webkit-filter: drop-shadow(0px 4px 8px rgba(93, 39, 48, 0.1));
      letter-spacing: -0.04em;
      border: 0;

      &.no-shadow-form {
        filter: none;
        -webkit-filter: none;
        border: 1px solid #e6e8ec;
      }

      &:focus {
        @include boxShadow(0px 8px 16px rgba(26, 39, 126, 0.16));
        border-color: #d3d7dc;
      }

      @media (max-width: 992px) {
        min-height: 50px;
        @include border-radius(16px);

        &::placeholder {
          font-size: $text-md;
          color: $text-color;
          opacity: 0.6;
        }
      }
    }

    &+.app-c-mw-form--inputs__form-control {
      margin-top: 16px;

      @media (max-width: 992px) {
        margin-top: 24px;
      }
    }

    &.form-control--row {
      @extend %flex-align-start-row;

      .app-c-mw-form--inputs__form-control {
        margin: 0;

        &+.app-c-mw-form--inputs__form-control {
          margin-left: 16px;
        }
      }

      @media (max-width: 360px) {
        @include flex-direction(column);

        .app-c-mw-form--inputs__form-control {
          margin: 0;

          &+.app-c-mw-form--inputs__form-control {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }
    }

    &.has-error {
      .form-control {
        border: 1px solid red;
      }

      span {
        &.error-msg {
          color: red;
          font-size: $text-md;
          padding-left: 27px;
        }
      }
    }
  }

  &--mem-cr {
    .app-c-mw-form--inputs__form-control {
      .form-control {
        @media (max-width: 992px) {
          padding-left: 16px;
        }
      }
    }
  }
}

//search box
.search-option--form-default {
  margin-bottom: 30px;

  @include screen-md() {
    width: 100%;
    margin-top: 16px;
  }

  .app-c-form {
    padding-left: 56px;
    color: $text-color;
    min-height: 48px;
    filter: none;
    @include border-radius(8px);
    border-color: $lightGray !important;

    &::placeholder {
      font-size: $text-md;
      letter-spacing: -0.04em;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #091e3e;
      opacity: 0.75;
      font-size:14px;

    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #091e3e;
      opacity: 0.75;
      font-size:14px;

    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #091e3e;
      opacity: 0.75;
      font-size:14px;

    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #091e3e;
      opacity: 0.75;
      font-size:14px;

    }

    @include screen-md() {
      max-width: 100%;
      width: 100%;
      padding-left: 35px;

      &::placeholder {
        font-size: $text-md;
        letter-spacing: -0.04em;
      }
    }
  }

  .form-group {
    &::before {
      content: '\e956';
      font-family: $app-icons;
      position: absolute;
      top: 25px;
      @include transform(translate(0, -50%));
      z-index: 1;
      left: 23px;

      @include screen-md() {
        left: 11px;
        top: 25px;
        color: $text-color;
        font-size: 18px;
      }
    }
  }
}

.app-c-input-box.select-box.custom-label-my-orders{
  margin: 0 10px 10px 0;
  @include screen-sm(){
    width: 100%;
    margin-right: 0;

  }
}

//select-box
.app-c-input-box.select-box {
  height: 48px;
  margin: 0;
  background: white;
  min-width: 180px;
  border-radius: 8px;
  padding-left: 15px;
  padding-top: 8px;
  line-height: 16px;
  position: relative;

  &:focus {
    outline: none;
    border-color: $app-brand-primary;
  }

  &:after {
    content: '\e903';
    font-family: $app-icons;
    position: absolute;
    right: 18px;
    top: 15px;
    font-size: 10px;
    color: $app-brand-primary;
  }

  label {
    width: 100%;
    text-align: left;
    font-size: 12px;
    line-height: normal;
  }

  .selected-dropdown-value {
    width: 100%;
    text-align: left;
    font-size: 12px;
    font-weight: $font-weight-semibold;
    @extend %truncate;
  }

  &.plain {
    margin: 0 0 0 -3px;
    border: 0;
    padding: 0;
    font-weight: $font-weight-bold;
    width: 100%;
  }
}

//datepicker
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: $app-brand-primary !important;
  border-color: $app-brand-primary !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border-color: $app-brand-primary !important;
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  background-color: $app-brand-primary !important;
  border-color: $app-brand-primary !important;
}

.rs-calendar-month-dropdown-year-active,
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
  color: $text-color !important;
  font-weight: $font-weight-bold;
}

.rs-picker-date a.rs-btn.rs-picker-toggle .rs-picker-toggle-placeholder {
  font-family: $font-secondary;
  font-weight: $font-weight-normal;
}

.rs-picker-subtle .rs-btn:active:focus,
.rs-picker-subtle .rs-picker-toggle:active:focus {
  color: #272c36;
  background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px white inset;
  transition: none !important;
  background-image: none !important;
  -webkit-text-fill-color: $text-color !important;

  &:focus {
    box-shadow: 0 0 0px 1000px white inset !important;
    transition: none !important;
    background-image: none !important;
    -webkit-text-fill-color: $text-color !important;
  }
}

//new extra style
.rs-picker-date-menu {
  pointer-events: auto !important;
  .rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
    color: $text-color;
    font-size: $text-xl;
  }

  .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell,
  .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
    background-color: $app-brand-primary;
  }

  .rs-picker-toolbar {
    .rs-picker-toolbar-right-btn-ok {
      background-color: $app-brand-primary;

      &:hover,
      &focus {
        background-color: $app-brand-primary-dark;
      }
    }
  }
}

.autocomplete-dropdown-container:not(:empty) {
  top: auto;
  bottom: 100%;
  margin: 0px -12px 0 -16px;
  @include boxShadow(2px 3px 4px 3px #cdcdcd);
  background: $white;
  max-height: 300px;
  display: block;
  overflow: auto;

  .input-sugggestion {
    padding: 10px 6px 10px 30px;
    cursor: pointer;

    &:hover {
      background: $neutrals-color3-light;
    }

    &+.input-sugggestion {
      border-top: 1px solid #cdcdcd;
    }
  }
}

.select-wrapper-trunc {
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .select-box {
    min-width: auto;
  }

  @include screen-md() {
    max-width: 100%;
    width: 100%;
  }
}

.form-input-pd {
  padding: 24px 11px 9px;

  &:last-child {
    padding: 24px 11px 0;
  }

  .contact-title {
    font-size: $text-md;
    font-weight: $font-weight-normal;
  }

  .custom-control {
    @extend %flexbox;
    font-weight: $font-weight-medium;
  }

  .radio-wrapper {
    margin-top: 14px;
    @extend %flexbox;
    @extend %flex-wrap;

    @include screen-md() {
      flex-direction: column;
    }

    &>label {
      margin-right: 55px;

      @include screen-md() {
        margin: 10px;
      }
    }
  }
}

.mb-col {
  @include screen-md() {
    flex-direction: column;
  }
}
.form-input-gender{
  .radio-wrapper{
  label {
    margin-right: 20px!important;
  }
}
}
.time-range {
  .selected-time-range {
    font-family: $font-primary;
    font-size: $text-lg;
    color: $text-color;
    margin-left: 28px;
    font-weight: $font-weight-bold;
  }
}

.app-c-input-box {
  &.lg-height {
    &.time-range {
      &.disabled {
        cursor: not-allowed;
        border: 1px solid $form-disabled-custom-color;

        .app-c-radio-check__node {
          &.-new-radio {
            border: 1px solid $form-disabled-custom-color;

            &:after {
              background: $form-disabled-custom-color;
            }
          }
        }

        .app-c-radion-check input[type='radio']:checked+.app-c-radio-check__node,
        .app-c-radion-check input[type='checkbox']:checked+.app-c-radio-check__node {
          background: $form-disabled-custom-color;
        }

        .app-c-radion-check {
          color: #e5e5e5;
        }

        .selected-time-range {
          color: #e5e5e5;
        }
      }
    }

    &.attendee-counter {
      @extend %flex-align-center-justify-space-between-row;
      width: 100%;

      .attendee-label {
        font-size: 12px;
        white-space: nowrap;
      }

      .attendee-count {
        @extend %flexbox;

        &--btn {
          background: $app-brand-primary;
          border-radius: 50%;
          color: $white;
          min-width: 18px;
          height: 18px;
          width: 18px;
          font-size: $text-sm-plus;
          @extend %flex-align-center;
        }

        &--val {
          margin: 0 14px;
          line-height: normal;
          font-weight: $font-weight-bold;
        }
      }

      &.disabled {
        cursor: not-allowed;
        border: 1px solid $form-disabled-custom-color;

        .attendee-label {
          color: $form-disabled-custom-color;
        }

        .attendee-count {
          &--btn {
            background: $form-disabled-custom-color;
          }

          &--val {
            color: $form-disabled-custom-color;
          }
        }
      }
    }
  }

  sup {
    color: red;
  }
}

.d-form-block {
  >div {
    width: 100%;
  }
}

// DatePickerStyle
.app-c-datepicker {
  .rs-picker-toggle {
    position: absolute !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    padding: 27px 0 0 17px !important;
  }
}

.create-table-dropdown {
  border: 1px solid $generic-border-color;
  @include border-radius(16px);
  padding: 8px 10px 7px 17px;
  margin-top: 16px;
  background: $white;
  font-size: $text-lg;

  &.lg-height {
    min-height: 64px;
    @extend %align-start-justify-center-col;
  }

  &.space-between {
    @extend %flex-align-center-justify-space-between-row;

    @include screen-sm() {
      flex-wrap: wrap;
      margin-bottom: 100px;
      height: 66px;

      .app-c-btn {
        top: 28px;
        position: relative;
        margin: auto;
      }
    }
  }

  .form-label {
    line-height: 22px;
    margin: 0;
    font-size: $text-md;
    font-family: $font-secondary;
    color: $text-label;
    display: block;
  }

  .creatable-dropdown {
    .css-yk16xz-control {
      border: none;
      @include noShadow();
      font-size: $text-lg;
      font-weight: $font-weight-semibold;
      color: $text-color;

      .css-g1d714-ValueContainer {
        padding: 0;
      }
    }
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 0;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-g1d714-ValueContainer {
    min-height: 28px;
    padding: 0;
    border: none !important;
    box-shadow: none !important;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
}

.app-c-service-select {
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
}

.app-c-from-add-user {
  .app-c-input-box {
    border: 1px solid $text-color;
    @include border-radius(16px);
  }
}

.app-l-tags-input {
  position: relative;

  .info--tooltip {
    position: absolute;
    top: 16px;
    right: 13px;

    @media (max-width: 768px) {
      display: none !important;
    }
  }
}

.liveInPlace {
  position: relative;
  z-index: 9;
}

.react-tel-input .country-list .search-box {
  border: 1px solid #ced2d8;
  width: calc(100% - 10px);
  line-height: 25px;
  margin-left: 0;
  border-radius: 5px;
}

.slot-manage-btns {
  margin-top: 9%;
  margin-left: 7%;
}
.app-c-mw-form--inputs__form-control {
  input{
    font-weight: normal;
    letter-spacing: normal!important;
  }
}
.app-c-mw-form--inputs__form-control.search-option--form-default.custom-input-myorder{
  margin-left: 0;
}


.tooltip-body {
	flex-direction: column;
	padding: 0 0 7px 0;
}
.tooltip-body span {
	display: flex;
	align-items: center;
	padding: 8px 0px 0;
}
.tooltip-body span span {
	padding: 0;
}
.tooltip-body span div {
	margin-left: 7px;
	margin-right: 12px;
	font-size: 13px;
	text-align: left;
	line-height: normal;
}
.tooltip-body span svg {
	width: 35px;
	height: 35px;
	margin-right: 5px;
}
.tooltip-content label {
	width: 100%;
	text-align: left;
	display: flex;
	padding: 0 10px;
}
.tooltip-content label strong {
	margin-left: auto;
	padding-left: 20px !important;
}
.tippy-tooltip {
	background-color: #434242 !important;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.25) !important;
	border-radius: 6px !important;
	padding: 6px 10px !important;
	color: #fff !important;
	font-size: 14px !important;
  word-break: break-word;
}
.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-theme [x-circle]{
  background-color: #434242 !important;

}
.tippy-popper[x-placement^="top"] [x-arrow] {
	border-top: 7px solid #434242 !important;
}
.tippy-popper[x-placement^="left"] [x-arrow] {
	border-left: 7px solid #434242 !important;
}
.tippy-popper[x-placement^="bottom"] [x-arrow] {
	border-bottom: 7px solid #434242!important;
}
.tooltip-body span div {
	font-size: 14px !important;
}
.tippy-popper[x-placement^="right"] [x-arrow] {
	border-right: 7px solid #434242 !important;
}

.app-l-phone-input {
  sup {
    color: red !important;
  }
}


.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-theme [x-arrow] {
  border-bottom: 7px solid #fff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}
.tippy-popper[x-placement^=bottom] [x-arrow] {
  border-bottom: 7px solid #333;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  top: -7px;
  margin: 0 9px;
}
.tippy-popper[x-placement^=bottom] [x-arrow] {
  border-bottom: 7px solid #434242!important;
}
.tippy-tooltip [x-arrow] {
  position: absolute;
  width: 0;
  height: 0;
}