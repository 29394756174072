.invoice-body {
  padding: 0px;
  background-color: #ffffff;
  width: 100%;
}

.invoice-header-table {
  width: 98%;
  border: 0px;
  padding: 0px;
  align-items: center;
  background-color: #ffffff;
  border-spacing: 0px;
}

.invoice-header-first-inner-table {
  width: 600px;
  padding: 0px;
  border: 0px;
  align-items: center;
  background-color: #ffffff;
  border-spacing: 0px;
}

.invoice-header-second-inner-table {
  width: 480px;
  border: 0px;
  padding: 0px;
  align-items: center;
  border-spacing: 0px;
}

.invoice-header-third-inner-table {
  width: 220px;
  border: 0px;
  padding: 0px;
  align-items: left;
  border-spacing: 0px;
}

.invoice-header-third-inner-table-heading {
  font-size: 12px;
  color: #5b5b5b;
  font-family: 'Open Sans', sans-serif;
  line-height: 18px;
  vertical-align: top;
  text-align: left;
}

.invoice-header-fourth-inner-table {
  width: 220px;
  border: 0px;
  margin-top: -115px;
  margin-bottom: auto;
  margin-left: 800px;
  padding: 0px;
  margin-right: auto;
  align-items: right;
}

.invoice-heading {
  font-size: 21px;
  color: #ed8294;
  letter-spacing: -1px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1px;
  vertical-align: top;
  text-align: right;
}

.invoice-order-date {
  font-size: 12px;
  color: #5b5b5b;
  font-family: 'Open Sans', sans-serif;
  line-height: 18px;
  vertical-align: top;
}

.invoice-orders-table {
  width: '90%';
  height: '100px';
  margin-right: '50px';
  margin-left: '0px';
  margin-top: '90px';
  border: '0px';
  padding: '0px';
  border-spacing: '0px';
  align-items: 'center';
}

.invoice-orders-inner-table {
  width: '99%';
  margin-top: '10px';
  border: '0px';
  padding: '0px';
  border-spacing: '0px';
  align-items: 'center';
}

.invoice-orders-table-head {
  font-size: '12px';
  font-family: 'Open Sans', sans-serif;
  color: #1e2b33;
  font-weight: normal;
  vertical-align: top;
  padding: 0 10px 7px 0;
  align-items: left;
}

.invoice-orders-table-first-head {
  padding: 0 10px 7px 0;
}

.invoice-orders-table-next-head {
  padding: 0 5px 7px 0;
}

.invoice-orders-table-last-head {
  padding: 0 5px 7px 0;
}

.invoice-ordered-items {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  line-height: 18px;
  vertical-align: top;
  padding: 10px 0;
}

.invoice-ordered-product {
  color: #ed8294;
}

.invoice-ordered-product-details {
  color: #646a6e;
}

.invoice-orders-total {
  color: #1e2b33;
}

.invoice-total-main-table {
  width: 90%;
  margin-right: 50px;
  border: 0px;
  padding: 0px;
  border-spacing: 0px;
  align-items: center;
  background-color: #ffffff;
}

.invoice-total-first-inner-table {
  width: 99%;
  border: 0px;
  padding: 0px;
  border-spacing: 0px;
  align-items: center;
}

.invoice-total-inner-table {
  width: 95%;
  border: 0px;
  padding: 0px;
  border-spacing: 0px;
  align-items: right;
}

.invoice-total {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  line-height: 22px;
  vertical-align: top;
  text-align: right;
}

.invoice-grand-total {
  padding-left: 90px;
  padding-right: auto;
}
