body {
    @include screen-sm() {
        overflow-x: hidden;
    }
}

.screen-graphics1 {
    background: url(/assets/images/graphics-bg-1.svg) no-repeat top center;
    background-size: cover;
    height: 605px;
    position: absolute;
    top: -1px;
    // left: 0;
    // right: 0;
    margin: auto;
    // max-width: 1684px;
    width: 100%;
    z-index: -1;
    &.landingpage {
        background: url(/assets/images/sliceLandingPage.png) no-repeat top center;
        height: 820px;
        @include screen-xxl() {
            background-size: 100%;
        }
        @include screen-md() {
            height: 100%;
        }
    }
    @include screen-lg() {
        background-size: cover;
    }
    @include screen-md() {
        display: none;
    }
    &.learn-more-bg {
        @include screen-md() {
            display: inherit !important;
        }
    }
}

.pattern1 {
    display: block;
    position: absolute;
    width: 355px;
    height: 335px;
    @include border-radius(50%);
    background: url(/assets/images/pattern1.svg) no-repeat center center;
    top: 9px;
    left: -110px;
    z-index: -1;
    @include screen-sm() {
        width: 228px;
        height: 228px;
        overflow: hidden;
        top: 117px;
        left: -55px;
    }
}

.graphics-line1 {
    position: absolute;
    left: -280px;
    bottom: 15px;
    display: block;
    width: 386.63px;
    height: 241px;
    background: url(/assets/images/graphics-line1.svg) no-repeat;
    z-index: -1;
    @include screen-sm() {
        left: -215px;
        bottom: 74px;
    }
}

.graphics-line2 {
    position: absolute;
    right: -232px;
    bottom: 40px;
    display: block;
    width: 750.5px;
    height: 115px;
    background: url(/assets/images/graphics-line2.svg) no-repeat;
    z-index: -1;
    @include screen-sm() {
        right: inherit;
        left: 215px;
        bottom: 96px;
    }
}

.graphics-box2 {
    max-width: 1278px;
    margin: auto;
    padding: 0;
    position: relative;
    @extend %flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    //z-index: 1;
    @include screen-xl {
        max-width: 90%;
    }
    @include screen-sm() {
        max-width: 100%;
        padding: 30px 0px;
        @include align-items(flex-start);
        .overflow-hidden--skew-1 {}
        .overflow-hidden--skew-2 {
            overflow: hidden;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
    .overflow-hidden--skew-2 {
        width: 100%;
    }
    .inner-skew1 {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #ccc;
        @include border-radius(40px);
        background: rgba(253, 243, 244, 1);
        transform: matrix(1, -0.03, 0.04, 1, 0, 0);
        z-index: 2;
        overflow: hidden;
        @include screen-sm() {
            width: 120%;
            @include border-radius(0px);
            left: -10px;
        }
        &::before {
            width: 100%;
            height: 100%;
            position: absolute;
            right: 20px;
            top: 0;
            content: '';
            background: url(/assets/images/inner-skew-graphics1.svg) no-repeat top right;
        }
        &::after {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 80px;
            top: 0;
            content: '';
            background: url(/assets/images/inner-skew-graphics2.svg) no-repeat;
        }
    }
    .inner-skew2 {
        position: absolute;
        width: 90%;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        height: 100%;
        @include border-radius(40px);
        transform: matrix(1, -0.09, 0.04, 1, 0, 0);
        z-index: 2;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/$neutrals-color1+1,ffb85c+49,ed8294+49,ed8294+100 */
        background: $neutrals-color1;
        /* Old browsers */
        background: -moz-linear-gradient(left, $neutrals-color1 1%, $neutrals-color1 49%, $neutrals-color3 49%, $neutrals-color3 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $neutrals-color1 1%, $neutrals-color1 49%, $neutrals-color3 49%, $neutrals-color3 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $neutrals-color1 1%, $neutrals-color1 49%, $neutrals-color3 49%, $neutrals-color3 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='$neutrals-color1', endColorstr='$neutrals-color3', GradientType=1);
        /* IE6-9 */
        @include screen-sm() {
            width: 105%;
            //transform: matrix(1, -0.11, 0.04, 1, 0, 0);
            transform: matrix(1, -0.14, 0.04, 1, -54, 0);
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/$neutrals-color3+0,ed8294+100 */
            background: $neutrals-color3;
            /* Old browsers */
            background: -moz-linear-gradient(left, $neutrals-color3 0%, $neutrals-color3 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(left, $neutrals-color3 0%, $neutrals-color3 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, $neutrals-color3 0%, $neutrals-color3 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='$neutrals-color3', endColorstr='$neutrals-color3', GradientType=1);
            /* IE6-9 */
        }
        @include screen-xxs() {
            width: 100%;
        }
    }
}

.graphics-box3 {
    max-width: 1278px;
    margin: auto;
    padding: 0;
    position: relative;
    @extend %flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    //z-index: 1;
    @include screen-xl {
        max-width: 90%;
    }
    @include screen-sm() {
        max-width: 100%;
        padding: 30px 0px;
        @include align-items(flex-start);
    }
    .outer-graphics {
        &:before {
            width: 100%;
            height: 100%;
            position: absolute;
            right: -90px;
            top: -150px;
            content: '';
            background: url(/assets/images/locations/pattern2.png) no-repeat top right;
            z-index: -1;
        }
        @include screen-md() {
            display: none;
        }
    }
    .inner-skew1 {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #ccc;
        @include border-radius(40px);
        background: $neutrals-color1-light;
        transform: matrix(1, -0.03, 0.04, 1, 0, 0);
        z-index: 2;
        overflow: hidden;
        @include screen-sm() {
            width: 120%;
            @include border-radius(0px);
            left: -14px;
        }
        &::before {
            width: 100%;
            height: 100%;
            position: absolute;
            right: 145px;
            top: 100px;
            content: '';
            background: url(/assets/images/locations/pattern1.svg) no-repeat top right;
            @include screen-md() {
                background: url(/assets/images/locations/pattern1.svg) no-repeat 22% 3%;
                right: 0;
                top: 0;
            }
            @include screen-sm() {
                background: url(/assets/images/locations/pattern1.svg) no-repeat 6% 8%;
                right: 0;
                top: 0;
            }
        }
    }
    .inner-skew2 {
        position: absolute;
        width: 90%;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        height: 100%;
        @include border-radius(40px);
        transform: matrix(1, -0.09, 0.04, 1, 0, 0);
        z-index: 2;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/$neutrals-color1+1,ffb85c+49,ed8294+49,ed8294+100 */
        background: $neutrals-color4;
        /* Old browsers */
        background: -moz-linear-gradient(bottom, $neutrals-color4 1%, $neutrals-color1 49%, $neutrals-color1 49%, $neutrals-color1 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(bottom, $neutrals-color4 1%, $neutrals-color1 49%, $neutrals-color1 49%, $neutrals-color1 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $neutrals-color4 1%, $neutrals-color1 49%, $neutrals-color1 49%, $neutrals-color1 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='$neutrals-color4', endColorstr='$neutrals-color1', GradientType=1);
        /* IE6-9 */
        @include screen-sm() {
            width: 115%;
            transform: matrix(1, -0.14, 0.04, 1, -54, 0);
            background: $neutrals-color4;
            /* Old browsers */
            background: -moz-linear-gradient(bottom, $neutrals-color4 1%, $neutrals-color4 49%, $neutrals-color4 49%, $neutrals-color4 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(bottom, $neutrals-color4 1%, $neutrals-color4 49%, $neutrals-color4 49%, $neutrals-color4 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, $neutrals-color4 1%, $neutrals-color4 49%, $neutrals-color4 49%, $neutrals-color4 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='$neutrals-color4', endColorstr='$neutrals-color4', GradientType=1);
            /* IE6-9 */
        }
    }
}

.app-c-link-btn--change {
    &:hover {
        &+.memorial-header-bg {
            &:after {
                opacity: 1;
            }
        }
    }
}

.overflow-hidden--bg-area {
    @include screen-sm {
        position: absolute;
        width: 100%;
        height: 350px;
        overflow: hidden;
    }
}

.memorial-header-bg {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 1300px;
    overflow: hidden;
    @extend %flex-align-start-justify-center-row;
    @include border-radius(0 0 100px 100px);
    background: $neutrals-color2-light no-repeat;
    background-size: cover;
    @include transform(translate(-8px, -100px) rotate(-2deg));
    height: 350px;
    width: 100%;
    background-position: center;
    min-width: auto;
    &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #50505026;
        content: '';
        opacity: 0;
        z-index: -1;
        @include cm-transition;
    }
    @media (max-width: 1204px) {
        top: 50px;
    }
    @include screen-sm() {
        background-position: center;
        left: 0;
        right: inherit;
        width: 120%;
        top: 56px;
        @include transform(translate(-6px, -100px) rotate(-3deg));
        @include border-radius(0 0px 0px 100px);
    }
    @include screen-xs() {
        top: 84px;
    }
    &.less-height {
        @include screen-sm() {
            top: -71px;
        }
    }
}