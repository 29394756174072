.app-l-dashboard-base {
  overflow-x: hidden;
  position: relative;
  z-index: 0;
  .app-l-dashboard-base-wrapper {
    @extend %flexbox;
    margin-top: 40px;
    @include screen-md() {
      flex-direction: column;
      margin-top: 10px;
    }
    .app-l-dashboard {
      &--left {
        .app-l-dashboard {
          &__navigation {
            position: relative;
            @include screen-md() {
              &.more {
                &:before {
                  content: '';
                  position: absolute;
                  height: 100%;
                  width: 50px;
                  background: #fdf3f4;
                  right: -20px;
                  z-index: -1;
                }
              }
            }
            .nav {
              background: $neutrals-color3-light;
              width: 265px;
              padding: 32px;
              @include border-radius(16px);
              margin-bottom: 16px;
              @include screen-md() {
                width: 100%;
                padding: 24px 12px;
                overflow-x: auto;
                &::-webkit-scrollbar {
                  background: $bg-scroll;
                  @include border-radius(100px);
                  height: 4px;
                }
                &::-webkit-scrollbar-thumb {
                  background: $app-brand-primary;
                  @include border-radius(100px);
                  height: 4px;
                }
              }
            }
            ul {
              @extend %flex-and-col;
              @include screen-md() {
                margin-bottom: -10px;
                flex-direction: row;
                width: 100%;
              }
              li {
                a {
                  text-decoration: none;
                  font-family: $font-primary;
                  font-size: $text-md-plus;
                  line-height: 16px;
                  font-weight: $font-weight-semibold;
                  padding: 8px 24px 8px 25px;
                  display: inline-block;
                  min-height: 32px;
                  white-space: nowrap;
                  color: $app-brand-primary;
                  @include border-radius(16px);
                  &.active,
                  &:hover {
                    z-index: 1;
                    background: $neutrals-color3;
                    @include transition(0.2s all);
                    @include boxShadow(0px 4px 4px rgba(237, 130, 148, 0.2), inset 0px 1px 2px #ffabb9);
                    color: $white;
                    text-decoration: none;
                  }
                }

                & + li {
                  @include screen-md() {
                    margin-left: 12px;
                  }
                  a {
                    margin-top: 21px;
                    @include screen-md() {
                      margin-top: 0;
                    }
                  }
                }
              }
            }
          }
          &__account-preview {
            background: $card-bg2;
            padding: 32px;
            width: 265px;
            @include border-radius(16px);
            margin-bottom: 16px;
            @extend %flex-and-col;
            @include screen-md() {
              flex-direction: row;
              width: 100%;
              padding: 17px 24px 17px;
              margin-bottom: 56px;
            }
            @media (max-width: 365px) {
              flex-direction: column;
            }
            //common
            .ap-icon {
              color: $neutrals-color4;
              margin-right: 8px;
              font-size: $text-md-plus;
              line-height: normal;
              @include screen-md() {
                margin-right: 6px;
              }
            }
            .ap-title {
              font-size: $text-lg;
              line-height: 28px;
              text-align: center;
              @extend %common-text-prop-normal;
              @extend %truncate;
              max-width: 200px;
              display: inline-block;
              @media screen and (max-width: 992px) and (min-width: 768px) {
                max-width: 180px;
              }
              @include screen-sm() {
                max-width: 140px;
              }
            }
            &--title {
              color: $text-color;
              letter-spacing: -0.03em;
              font-family: $font-primary;
              font-weight: $font-weight-bold;
              font-size: $text-lg;
              line-height: 24px;
              margin-bottom: 24px;
              @include screen-md() {
                font-size: $text-md;
                margin-bottom: 6px;
              }
            }
            &--group {
              @include screen-md() {
                margin-left: 0;
              }
              @media (max-width: 365px) {
                margin-left: 0;
                margin-top: 10px;
              }
            }
            &--email,
            &--phone {
              @extend %flex-and-align-center;
            }
            &--profile {
              margin-bottom: 40px;
              position: relative;
              @include screen-md() {
                margin-bottom: 0;
                margin-top: 10px;
              }
              &:after {
                background: url(/assets/images/avatar/avatar-bg.svg);
                content: '';
                position: absolute;
                height: 104px;
                width: 104px;
                top: 18px;
                left: 20px;
                z-index: 0;
                @include screen-md() {
                  height: 108px;
                  width: 108px;
                }
              }
              img {
                height: 104px;
                width: 104px;
                object-fit: contain;
                z-index: 1;
                position: relative;
                @include screen-md() {
                  height: 108px;
                  width: 108px;
                }
              }
            }
            &--fullName {
              color: $text-color;
              letter-spacing: -0.03em;
              font-family: $font-primary;
              font-weight: $font-weight-bold;
              font-size: $text-xl;
              line-height: 24px;
              margin-bottom: 8px;
              max-width: 200px;
              @extend %truncate;
              @include screen-md() {
                margin-bottom: 0;
                max-width: 100%;
              }
            }
            &--contact {
              border: 0;
              background-color: transparent;
              @extend %flex-and-align-center;
              margin-top: 16px;
              padding: 0;
              @include screen-md() {
                margin-top: 7px;
              }
              .ap-icon {
                color: $app-brand-primary;
                font-size: $text-xl;
              }
              .ap-title {
                font-size: $text-lg;
                color: $app-brand-primary;
                font-weight: $font-weight-bold;
              }
              &:hover {
                .ap-title,
                .ap-icon {
                  color: $app-brand-primary-dark;
                }
              }
            }
          }
        }
      }
      &--right {
        margin-left: 56px;
        width: 100%;
        @include screen-md() {
          margin-left: 0;
        }
        &__wrapper {
          .dr-title {
            margin-bottom: 28px;
            &.orbit-title {
              margin-bottom: 14px;
            }
          }
          .btn-save {
            min-width: 155px;
          }
          &--top {
            .dr-title {
              margin-bottom: 0;
            }

            @extend %flex-and-align-center;
            .dr-action {
              margin-left: auto;
              @extend %flexbox;

              &.left {
                margin: 0 auto 0 40px;
                @include screen-md() {
                  margin: 0 auto;
                  padding-bottom: 16px;
                  width: 100%;
                }
              }
            }
            margin-bottom: 32px;
            @include screen-md() {
              @include flex-direction(column);
              @include align-items(flex-start);
              margin-bottom: 0;
              &.activity-top {
                margin-top: 24px;
                .dr-title {
                  margin-bottom: 9px;
                }
                .app-c-input-box.select-box.select-memorial {
                  margin: 0;
                  width: 100%;
                }
                .dr-action {
                  margin: 24px 0 44px;
                  @include justify-content(center);
                  width: 100%;
                }
              }
              .dr-title {
                margin-bottom: 24px;
                font-size: 26px;
              }
              .ml-only {
                display: none;
              }
              .app-c-input-box.select-box {
                width: 100%;
                min-width: auto;
              }
            }
            &.card-below {
              margin-bottom: 18px;
            }
          }
          &--profile {
            .dr-profile-wrapper {
              position: relative;
              @extend %flexbox;
              align-items: center;
              margin-bottom: 15px;
              &--pic {
                @extend %flex-all-center;
                height: 104px;
                width: 104px;
                background: $neutrals-color4;
                @include border-radius(16px);
                &.green {
                  background: $neutrals-color2;
                }
                img {
                  height: 104px;
                  width: 104px;
                  object-fit: cover;
                  position: relative;
                  @include border-radius(16px);
                }
                .initials {
                  text-align: center;
                  font-weight: $font-weight-bold;
                  text-transform: uppercase;
                  font-size: 24px;
                  line-height: 40px;
                  font-family: $font-primary;
                  letter-spacing: -0.03em;
                  color: $white;
                }
              }
              &--action {
                @extend %flexbox;
              }
              &--upload {
                margin-left: 32px;
                cursor: pointer;
                input {
                  display: none;
                }
                &:hover {
                  em,
                  span {
                    color: $neutrals-color3-dark;
                  }
                  em {
                    color: $white;
                  }
                }
                em {
                  background: $app-brand-primary;
                  color: $white;
                  width: 21px;
                  height: 21px;
                  border-radius: 50px;
                  font-size: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  line-height: normal;
                  margin-right: 5px;
                }
                span {
                  font-family: $font-primary;
                  font-weight: $font-weight-bold;
                  font-size: $text-md;
                  line-height: 15px;
                  @extend %inline-flex;
                  @include align-items(center);
                  letter-spacing: 0.03em;
                  color: $app-brand-primary;
                }
              }
            }
            .dr-profile-input {
              .app-c-input-box {
                width: 100%;
                &.psw {
                  @extend %flex-and-align-center;
                  min-height: 64px;
                }
                &.website-url {
                  position: relative;
                  .prefix-proto {
                    color: rgba(9, 30, 62, 0.36);
                    position: absolute;
                    top: 32px;
                    left: 24px;
                    font-weight: $font-weight-bold;
                  }
                  .form-control {
                    padding-left: 53px;
                  }
                }
                .form-control {
                  color: $text-color;
                  font-weight: $font-weight-bold;
                  font-size: $text-lg;
                }
                textarea {
                  &.form-control {
                    min-height: 220px !important;
                    max-height: 220px !important;
                  }
                }
              }
              &-wrapper {
                @extend %flexbox;
                & > div {
                  width: 100%;
                  & + div {
                    margin-left: 16px;
                    @include screen-md() {
                      margin-left: 0;
                    }
                  }
                }
                & + .dr-profile-input-wrapper {
                  margin-bottom: 16px;
                }
                @include screen-md() {
                  @include flex-direction(column);
                }
              }
            }
            & + .app-l-dashboard--right__wrapper--profile {
              margin-top: 40px;
              @include screen-md() {
                margin-top: 56px;
              }
            }
            &.no-logo {
              .dr-title {
                margin-bottom: 14px;
              }
            }
            .search-result-wrapper {
              margin-bottom: 120px;
              .app-c-card-flex {
                padding: 0;
                margin-left: -1.5%;
                margin-right: -1.5%;
                background: none;
                @include screen-lg() {
                  margin-left: 0;
                  margin-right: 0;
                }
                &:before {
                  background: none;
                }
                
              }
            }
            .dr-status {
              @extend %flexbox;
              margin-bottom: 56px;
              @include flex-flow(row wrap);
              &__item {
                min-width: 215px;
                @media (max-width: 1226px) {
                  min-width: auto;
                  border-bottom: 1px dashed #e6e6e6;
                  @include flex-basis(calc(100% / 2));
                  padding-bottom: 24px;
                  padding-top: 16px;
                }
                &--count {
                  .currency {
                    font-size: $lgs;
                    color: $text-color;
                    line-height: normal;
                  }
                  @include screen-md() {
                    font-size: 32px;
                  }
                }
                &--subtitle {
                  font-weight: $font-weight-semibold;
                  font-size: $text-md;
                  line-height: 20px;
                  letter-spacing: -0.03em;
                  color: #536278;
                  @include screen-md() {
                    font-size: $text-lg;
                  }
                }
                & + .dr-status__item {
                  padding-left: 32px;
                  border-left: 1px dashed #e6e6e6;
                  @media (max-width: 1226px) {
                    padding-left: 0;
                    border-left: 0;
                  }
                }
              }
            }
            .app-l-dr-activity {
              @include border-radius(8px);
              border: 1px solid #e7e9ec;
              padding: 22px 32px 10px;
              margin-bottom: 100px;
              @include screen-md() {
                padding: 22px 20px 10px;
                margin: 0 -20px 100px;
                overflow-x: auto;
              }
              &--header {
                .app-l-dr-activity--items {
                  &--rows {
                    font-family: $font-primary;
                    font-weight: $font-weight-medium;
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    color: $text-color;
                    opacity: 0.6;
                    word-break: break-word;
                    padding: 12.5px 0px;
                  }
                }
              }
              &--items {
                @extend %flexbox;
                &--rows {
                  padding: 25px 0px;
                  font-size: $text-md-plus;
                  line-height: 24px;
                  // @include flex-basis(200px);
                  min-width: auto;
                  width: 100%;
                  color: $text-color;
                  letter-spacing: -0.03em;
                  font-family: $font-secondary;
                  text-transform: capitalize;
                  & + .app-l-dr-activity--items--rows {
                    padding-left: 10px;
                  }
                  @include screen-md() {
                    @include flex-basis(auto);
                    min-width: 120px;
                  }
                }
                &--row-1 {
                  opacity: 0.6;

                  @include screen-md() {
                    min-width: 120px;
                  }
                }
                &--row-2 {
                  font-weight: $font-weight-semibold;
                  word-break: break-all;
                  @media screen and (max-width: 992px) and (min-width: 768px) {
                    min-width: 120px;
                  }
                  @media (max-width: 767px) {
                    min-width: 120px;
                  }
                }
                &--row-3 {
                  font-weight: $font-weight-semibold;
                  @include flex-basis(auto);
                  @media (max-width: 767px) {
                    min-width: 120px;
                    max-width: 120px;
                  }
                  .icon-label {
                    position: relative;
                    top: 1px;
                    margin-right: 9px;
                    em.icon-tree-colored {
                      .path1:before,
                      .path2:before {
                        font-family: $app-icons;
                      }
                    }
                    .icon-heart {
                      color: $app-brand-primary;
                    }
                    .i-title {
                      font-size: $text-md;
                    }
                  }
                }
                & + .app-l-dr-activity--items .app-l-dr-activity--items--rows {
                  border-top: 1px dashed #d9d9d9;
                }
              }
              &--body {
                .app-l-dr-activity--items {
                  &:first-child {
                    .app-l-dr-activity--items {
                      &--row-1,
                      &--row-2,
                      &--row-3 {
                        padding: 12.5px 0px 25px;
                        & + .app-l-dr-activity--items--rows {
                          padding-left: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &--bottom {
            @extend %flexbox;
            .dr-action {
              margin-left: auto;
              @include screen-md() {
                margin: 0 auto;
              }
            }
            margin-top: 56px;
            margin-bottom: 72px;
          }
        }
      }
    }
  }
}
.no-result {
  padding: 44px 24px !important;
  box-shadow: 0px 4px 8px 0px rgba(93, 39, 48, 0.1);
  border: 1px solid $light-grayish-blue;
  @include border-radius(16px);
  margin-bottom: 30px;
  img {
    height: 90px;
    margin-bottom: 15px;
  }
}
.my-memorial-search {
  margin-bottom: 32px;
}
//common
.memlo__alert--memorial {
  background: $neutrals-color1;
  padding: 15px 17px;
  color: $white;
  &.mr-only {
    margin: 0 -20px;
  }
  .memorial-flex {
    padding: 0 10px;
    @extend %flexbox;
    em {
      margin-top: 6px;
      display: block;
    }
    .alert-msg {
      line-height: 24px;
      font-weight: $font-weight-medium;
      font-size: $text-md;
      letter-spacing: -0.03em;
    }
    .sendout {
      display: block;
      margin-left: 0;
    }
  }
  em {
    margin-right: 12px;
  }
  .sendout {
    text-decoration: underline;
    margin-left: 6px;
  }
}
.app-c-input-box.select-box.select-memorial {
  margin-left: 35px;
  min-width: 225px;
  margin-right: 20px;
}
.search-option--form-default.ob {
  margin-bottom: 0;
  margin-left: 8px;
  .form-control {
    border: 1px solid #edeef0;
  }
  @include screen-md() {
    margin-top: 0;
    margin-left: 20px;
    .form-group {
      &::before {
        font-size: $text-lg-plus;
      }
    }
  }
  .app-c-form {
    min-width: 480px;

    @media (max-width: 1640px) {
      min-width: 300px;
    }
    @include screen-md() {
      min-width: auto;
    }
  }
}
.thankyou-letter {
  .app-c-input-box {
    padding: 8px 10px 7px 10px;
    @include border-radius(16px);
    margin-bottom: 28px;
  }
  textarea {
    border: 1px solid #ebedf0;
    &.form-control {
      min-height: 290px !important;
      max-height: 290px !important;
      color: $text-color;
      font-weight: $font-weight-semibold;
      font-size: $text-lg;
    }
  }
  .ty-btn {
    margin-left: auto;
    display: block;
  }
}
.change-password-text {
  color: $neutrals-color3-dark;
  margin-top: 30px;
  .cp-text-title {
    font-weight: $font-weight-bold;
  }
  i {
    margin-left: 10px;
    top: 3px;
    position: relative;
    transition: 0.3s transform;
    display: inline-block;
    &.active {
      transform: rotate(180deg);
    }
  }
}
.dr__action {
  margin: 0 0 0 0 !important;
  margin-left: auto !important;
}
.dr-status-holder {
  justify-content: space-between;
  flex-wrap: nowrap;
  .dr-status__item {
    flex: 1;
    text-align: center;
    min-width: auto !important;
    padding-left: 0 !important;
  }
  @media (max-width: 592px) {
    flex-direction: column !important;
  }
}
.my-order-filter {
  @include flexbox;
  @include align-items(center);

  margin-bottom: 30px;
  .app-c-input__label {
    margin-top: -17px;
    color: rgba(9, 30, 62, 0.6);
    @include screen-sm(){
      margin-top: 0
    }
    @media(min-width:540px){
      margin-top: -17px
    }
}
  .rs-picker-toggle-value {
    margin-top: 22px;
    margin-left: 16px;
    color: $text-color;
  }
  .rs-picker-toggle-clean {
    right: 30px !important;
    top: 15px !important;
    font-size: 9px;
    background: $red;
    border-radius: 50px;
    width: 15px;
    height: 15px;
    color: $white;
    display: FLEX;
    align-items: center;
    justify-content: center;
    opacity: 1;
    font-weight: bold;
    &:hover{
      color: $white;

    }
  }

  
  .app-c-input-box.select-box:after {
    right: 10px;
  }

  .selected-dropdown-value {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 48px;
    .rs-picker-date,
    a {
      width: 100%;
      height: 46px;
      border: none;
      background: transparent !important;
    }
    .rs-picker-toggle-placeholder {
      margin-top: 2px;
      margin-left: 0;
    }
  }
  .rs-picker-toggle-placeholder {
    top: 20px;
    position: absolute;
    left: 16px;
    color: rgba(9, 30, 62, 0.6);
  }
  @media (max-width: 480px) {
    @include flex-direction(column);
    .app-c-input-box {
      width: 100%;
      margin-bottom: 10px;
    }
    .search-option--form-default {
      margin-left: 0;
    }
  }
}

.truncate-text {
    max-width: 205px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
