.app-c-check-group {
  &.btm-brdr {
    border-bottom: 1px dashed $generic-border-color;
  }
  &.horizontal {
    @extend %flex-align-center-justify-start-row;
    @extend %flex-wrap;
    .app-c-checkbox__item {
      @extend %inline-flex;
      margin-right: 16px;
      margin-bottom: 24px;
    }
  }
}
.app-c-checkbox__item {
  @extend %flex-align-center-justify-start-row;
}
.app-c-checkbox {
  position: relative;
  width: $radio-width;
  height: $radio-height;

  input[type='checkbox'] {
    width: $radio-width;
    height: $radio-height;
    position: relative;
    opacity: 0;

    &:checked + .app-c-checkbox__node {
      background: $app-brand-primary;
      border: 1px solid $app-brand-primary;
      &::after {
        opacity: 1;
      }
    }
  }
  .app-c-checkbox__node {
    position: absolute;
    top: 0px;
    left: 0px;
    width: $radio-width;
    height: $radio-height;
    content: '';
    background: $white;

    border: 1px solid $generic-border-color;
    display: block;
    @include cm-transition();
    @include border-radius(2px);
    pointer-events: none;
    &::after {
      content: '\e910';
      font-family: 'memlo';
      color: $white;
      font-size: 7px;
      text-align: center;
      width: 100%;
      height: 100%;
      display: block;
      line-height: 14px;
      opacity: 0;
      font-weight: bold;
      @include cm-transition();
    }
  }
}

.app-c-check-label {
  display: inline-block;
  margin-left: 12px;
  font-weight: $font-weight-bold;
  font-size: $text-md;
  font-family: $font-primary;
  color: $text-color;
  line-height: 14.63px;
  position: relative;
  top: 1px;
  cursor: pointer;
  &.not-selected {
    opacity: 0.6;
    font-weight: $font-weight-normal;
  }
}
.custom-checkbox {
  .custom-control-input {
    &:focus {
      &:not(:checked) ~ .custom-control-label::before {
        border-color: $neutrals-color3;
      }
      ~ .custom-control-label {
        &::before {
          @include boxShadow(none);
        }
      }
    }
    &:checked ~ .custom-control-label {
      &::before {
        border-color: $app-brand-primary;
        background-color: $app-brand-primary;
      }
    }
    &:not(:disabled):active ~ .custom-control-label::before {
      background-color: #ed82949e;
      border-color: $app-brand-primary;
    }
  }
}
.custom-radio {
  .custom-control-input {
    &:focus {
      &:not(:checked) ~ .custom-control-label::before {
        border-color: $neutrals-color3;
      }
      ~ .custom-control-label {
        &::before {
          @include boxShadow(none);
        }
      }
    }
    &:checked ~ .custom-control-label {
      &::before {
        border-color: $app-brand-primary;
        background-color: $app-brand-primary;
      }
    }
    &:not(:disabled):active ~ .custom-control-label::before {
      background-color: #ed82949e;
      border-color: $app-brand-primary;
    }
  }
  .custom-control-label {
    &::before {
      border-color: $app-brand-primary;
    }
  }
}
.custom-control-label::after{
  cursor: pointer;
}