.memorial-page-main {
  margin-top: 2px;
  position: relative;
  z-index: 2;
  padding-bottom: 50px;
}

.memorial-page-wapper {
  @extend %flex-align-start-justify-start-row;
  @media (max-width: 1250px) {
    @include flex-wrap(wrap);
    display: block;
  }
}

.memorial-page-content {
  @extend %flex-grow-1;
  // overflow: hidden;
  // padding-right: 5px;
  .app-mem-card {
    display: none;
    @include screen-sm() {
      display: block;
    }
  }
}

.memorial-profile-card {
  @extend %flex-align-start-justify-start-row;
  &.set-mid {
    @extend %flex-all-center-col;
    .memorial-profile__texting {
      text-align: center;
      overflow: hidden;
      padding-left: 0;
      padding-top: 32px;
      &.review-texting {
        justify-content: center;
        display: flex;
        flex-direction: column;
        min-height: auto;
        max-height: max-content;
      }
    }
  }
  @include screen-sm() {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

.app-c-password-change {
  position: relative;
}

.app-c-password-change .form-control {
  width: 85%;
}

.app-c-password-change .icon-label-eye-change {
  position: absolute;
  right: 26px;
  top: 35px;
  font-size: 20px;
  cursor: pointer;
}

.memorial-profile__avatar {
  min-width: 180px;
  width: 180px;
  height: 180px;
  position: relative;
  @include border-radius(16px);
  text-align: center;
  background: $white;
  @include cm-transition;
  @include screen-lg() {
    min-width: 160px;
    width: 160px;
    height: 160px;
  }
  &.uploaded-image-change {
    cursor: pointer;
    &::after {
      content: '\e90c';
      position: absolute;
      @include cm-transition;
      width: 100%;
      height: 100%;
      font-family: 'memlo', sans-serif;
      top: 0;
      left: 0;
      @extend %flex-all-center;
      font-size: 39px;
      color: $white;
      opacity: 0;
      background: #0000006e;
      @include border-radius(17px);
      z-index: -1;
    }
  }
  &:hover {
    .app-c-link-btn__text {
      color: rgb(245, 245, 245);
    }
    &.uploaded-image-change {
      &::after {
        opacity: 1;
        @extend %flexbox;
        z-index: 0;
      }
    }
  }
  @include screen-sm() {
    width: 106px;
    height: 106px;
    min-width: 106px;
  }
  &.no-shrink {
    width: 180px;
    height: 180px;
    margin-top: 9px;
    &::before {
      display: block;
    }
  }
  &.bg1 {
    background: $neutrals-color2;
  }
  &.partner {
    // min-width: 120px;
    // width: 120px;
    // min-height: 120px;
    // height: 120px;
    // margin: auto;
    // bottom: 29px;
    // left: 16px;
    background: #e28fa3;
    @extend %flex-all-center;
    min-width: 80px;
    width: 80px;
    min-height: 80px;
    height: 80px;
    margin: 30px 0 0 auto;
    .memorial-profile__name {
      margin: auto;
    }
    &.-review {
      margin-top: 0;
      height: 100%;
      width: 100%;
      background: $neutrals-color2;
    }
    &:before {
      display: none;
    }
  }
  .img-stamp {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &::before {
    background: url(/assets/images/dotted-overlay.png) repeat;
    position: absolute;
    content: '';
    top: 16px;
    left: 16px;
    @include border-radius(16px);
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1;
    @include screen-sm() {
      display: none;
    }
  }
  img {
    width: 100%;
    height: 100%;
    @include border-radius(16px);
    object-fit: cover;
    pointer-events: none;
  }
  &-video {
    width: 26px;
    height: 26px;
    @include border-radius(50px);
    object-fit: cover;
    pointer-events: none;
  }
  .memorial-profile__avatar-pic {
    position: absolute;
    top: 0;
    left: 0;
    //z-index: 1;
  }
  .memorial-profile__avatar-video {
    position: absolute;
    top: 0;
    left: 0;
    //z-index: 1;
  }
  .before {
    position: absolute;
    right: -39px;
    top: -42px;
    background: url(/assets/images/profile-before.svg) no-repeat;
    background-size: contain;
    display: block;
    width: 54px;
    height: 80px;
    z-index: 1;
    @include screen-sm() {
      width: 33px;
      height: 46px;
      right: -12px;
      top: -12px;
    }
  }
  .after {
    position: absolute;
    left: -33px;
    bottom: -28px;
    background: url(/assets/images/profile-after.svg) no-repeat;
    background-size: contain;
    display: block;
    width: 56px;
    height: 98px;
    @include screen-sm() {
      width: 32px;
      height: 58px;
      left: -11px;
      bottom: -16px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    @include border-radius(16px);
  }
  .app-c-link-btn {
    color: $white;
    margin-top: 16px;
    @include screen-sm() {
      margin-top: -4px;
      width: 87px;
      white-space: normal;
    }
  }
}

.memorial-profile__name {
  font-size: 56px;
  color: $white;
  font-weight: $font-weight-bold;
  font-family: $font-primary;
  // width: 60px;
  height: 64px;
  text-align: center;
  margin: auto;
  display: block;
  line-height: 64px;
  margin-top: 56px;
  @include screen-lg() {
    margin-top: 36px;
    font-size: 50px;
  }
  @include screen-sm() {
    font-size: 32px;
    // width: 34px;
    height: 48px;
    margin-top: 10px;
  }
  .spinner-border {
    font-size: $text-md;
  }
}

.memorial-profile__texting {
  max-width: 630px;
  padding: 0;
  padding-left: 60px;
  padding-top: 10px;
  flex-grow: 1;
  overflow: hidden;
  @include screen-lg() {
    padding: 0px 0px 0px 42px;
  }
  h2 {
    display: flex;
    align-items: center;
    &.align-text {
      justify-content: center;
    }
  }
  .memorial-profile-name {
    max-width: calc(100% - 60px);
    font-size: 32px;
    font-weight: $font-weight-bold;
    font-family: $font-primary;
    color: $white;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    @include screen-sm() {
      max-width: calc(100% - 30px);
      line-height: normal;
      font-size: 12px;
      white-space: normal;
      margin-bottom: 3px;
    }
  }
  @include screen-sm() {
    padding-left: 25px;
    padding-top: 0;
    min-height: 210px;
    overflow-y: auto;
    max-height: 210px;
    &.-no-mr-height {
      min-height: auto;
    }
    .memorial__location {
      margin-right: 0 !important;
      @include flexbox;
      &-address {
        word-break: break-word;
        white-space: normal;
        line-height: normal;
      }
    }
  }
  @include screen-xs() {
    padding-left: 25px;
    padding-top: 0;
    min-height: 210px;
    overflow-y: auto;
    max-height: 210px;
    .memorial__location {
      margin-right: 0 !important;
      @include flexbox;
      &-address {
        word-break: break-word;
        white-space: normal;
        line-height: normal;
      }
    }
  }
  .h5 {
    color: $white;
    margin-bottom: 0;
    @include screen-sm() {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .memorial__date {
    color: $neutrals-color1;
    display: block;
    font-size: $text-xl;
    line-height: 22px;
    margin-bottom: 5px;
    @include screen-sm() {
      font-size: $text-md;
      line-height: normal;
      margin-bottom: 0;
    }
  }
  .memorial__location-wrapper {
    @extend %flex-vert-center;
    @include screen-sm() {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .memorial__location {
    color: $white;
    display: inline-block;
    vertical-align: middle;
    font-size: $text-lg;
    line-height: 28px;
    margin: 0;
    margin-right: 8px;
    @include screen-sm() {
      font-size: $text-md;
      line-height: 24px;
      margin-bottom: 6px;
    }
    @include screen-xs() {
      margin-bottom: 2px;
    }
    &.-address {
      font-size: $text-lg;
      @extend %truncate;
      max-width: 240px;
      text-transform: capitalize;
      word-break: break-word;
      @include screen-md() {
        max-width: 180px;
      }
      @include screen-sm() {
        font-size: $text-md;
      }
      button {
        max-width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .icon-location,
    .icon-home {
      color: $neutrals-color1;
      font-size: 15px;
      margin-right: 6px;
      position: relative;
      top: 2px;
    }
  }
  .memorial__tagging {
    ul {
      list-style: none;
      @extend %flexbox;
      @extend %flex-wrap;
      li {
        font-size: $text-md;
        color: $white;
        margin-top: 5px;
        margin-right: 6px;
        &:before {
          content: '#';
        }
      }
    }
  }
  .memorial__edit-button {
    @include screen-md() {
      margin-bottom: 0;
      padding: 0;
    }
  }
  &.invert {
    h2 {
      color: $text-color;
      .memorial-profile-name {
        color: $text-color;
        @include screen-sm() {
          line-height: 28px;
          font-size: 24px;
        }
      }
    }
    .memorial__date {
      color: $neutrals-color2;
    }
    .memorial__location {
      color: $text-color;
      span {
        &.icon-location,
        &.icon-home {
          color: $neutrals-color2;
        }
        &.color4 {
          $neutrals-color4: #503e9d;
          color: $neutrals-color4;
        }
      }
    }
    .app-c-votes {
      &.mobile {
        .app-c-votes__text {
          color: $text-color;
        }
      }
    }
  }
  .memorial__edit-button {
    margin-top: 10px;
  }
}

.app-c-votes {
  @extend %flex-align-center-justify-start-row;
  margin-top: 12px;
  margin-left: -3px;
  @include screen-sm() {
    display: none;
  }
  &.mobile {
    display: none;
    margin-top: 15px;
    margin-left: 0;
    @include screen-sm() {
      display: flex;
      margin: 0;
      max-width: 100%;
      @include flex-wrap(wrap);
    }
    .app-c-votes__text {
      color: $white;
    }
  }
  .app-c-votes__item {
    @extend %inline-flex;
    @extend %flex-align-center-justify-start-row;
    margin-right: 25px;
    @include screen-lg() {
      margin-right: 10px;
    }
  }
  button {
    all: unset;
    margin-right: 8px;
    &.calendar {
      em {
        color: $neutrals-color6;
      }
    }
  }
  i {
    font-family: 'memlo';
    font-style: normal;
  }
  .app-c-votes__text {
    font-size: 13px;
    font-weight: $font-weight-semibold;
  }
}

.memorial-services-tabs {
  padding: 24px 0px;
  position: relative;
  .service-title-mr-only {
    display: none;
  }
  @include screen-md() {
    // .service-title-ml-only {
    //   margin-bottom: -30px;
    // }
    .service-title-mr-only {
      display: block;
      order: 2;
      margin-bottom: 5px;
    }
    .app-c-tab-wrapper {
      display: flex;
      flex-direction: column;
    }
    .cs-scroll {
      order: 1;
    }
    .service-details-wrapper {
      order: 3;
    }
  }
  &.dashed-b-border {
    @include screen-sm() {
      border-bottom: none;
    }
  }
  &.pull-top {
    margin-top: -33px;
    @include screen-sm() {
      margin-top: 21px;
    }
  }
  .nav {
    padding-left: 280px;
    @include screen-sm() {
      padding-left: 0;
      margin-bottom: 25px;
    }
  }
  .left-card {
    img {
      min-width: 88px;
      height: 88px;
      margin-top: 8px;
      @include border-radius(8px);
      @include screen-sm() {
        max-height: 67px;
      }
    }
  }
  h5 {
    width: 280px;
    text-align: right !important;
    padding-right: 50px;
    @media (max-width: 1200px) {
      padding-right: 20px;
    }
    @include screen-md() {
      text-align: right;
    }
    @include screen-sm() {
      text-align: left !important;
    }
  }
}

.for-desktop {
  @include screen-sm() {
    display: none;
  }
}

.for-mobile {
  display: none;
  @include screen-sm() {
    display: block;
  }
}

.memorial-page-content-boxes {
  border-bottom: 1px dashed $generic-border-color;
  @extend %flex-align-start-justify-start-row;
  &.no-btm-border {
    border-bottom: none;
  }
  padding: 24px 0px;
  &.wrap {
    @extend %flex-wrap;
  }
  @include screen-sm() {
    flex-wrap: wrap;
  }
  .content-boxes__left {
    min-width: 280px;
    max-width: 280px;
    text-align: right;
    padding: 0;
    padding-right: 50px;
    border: none;
    background: none;
    @include screen-lg() {
      padding-right: 20px;
    }
    @include screen-sm() {
      margin-left: 0;
      padding-right: 0px;
      min-width: 100%;
      max-width: 100%;
      display: flex;
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(center);
      margin-bottom: 9px;
    }
    h5 {
      width: 100%;
      text-align: right;
      @include screen-md() {
        text-align: right;
      }
      @include screen-sm() {
        text-align: left;
      }
    }
  }
  .content-boxes__right {
    flex-grow: 1;
    position: relative;
    //padding-left: 15px;
    @include screen-sm() {
      padding-left: 0;
    }
    &.full-window {
      // margin-left: -20px;
      @include screen-sm() {
        margin-right: -40px;
      }
      @include screen-xs() {
        margin-right: -20px;
      }
    }
  }
  p {
    line-height: 24px;
  }
}

.content-boxes__full {
  flex-shrink: 0;
  flex-basis: 100%;
  padding: 0px 0px;
  @extend %flex-align-start-justify-start-row;
  margin-top: 15px;
  @include screen-sm() {
    flex-wrap: wrap;
    display: block;
    margin-top: 0px;
  }
  .left-card {
    min-width: 88px;
    height: 88px;
    margin-top: 20px;
    img {
      @include screen-sm() {
        max-height: 67px;
      }
    }
  }
  .content-boxes__left {
    min-width: 280px;
    max-width: 280px;
    text-align: right;
    padding-right: 50px;
    border: none;
    @include screen-lg() {
      padding-right: 20px !important;
    }
    @include screen-sm() {
      min-width: 100%;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      @include flex-direction(row);
      @include justify-content(flex-start);
      @include align-items(center);
    }
    h5 {
      margin-bottom: 0;
    }
    .sub {
      margin-top: 30px;
    }
  }
  .content-boxes__right {
    flex-grow: 1;
    text-align: center;
    .app-c-card {
      display: block;
      position: relative;
      padding: 16px 32px 24px 32px;
      text-align: left;
      background: none;
      position: relative;
      margin: 10px 0px 0px;
      &:hover {
        box-shadow: 0px 8px 16px rgba(26, 39, 126, 0.16);
      }
      @include screen-lg() {
        padding: 16px 16px;
      }
      & + .app-c-card {
        margin-top: 30px !important;
      }
      @include screen-sm() {
        padding: 16px 11px 24px 10px;
      }
      @include screen-xs() {
        // @include noShadow();
        // padding: 0px 0px 10px 0px;
        margin-top: 10px;
      }
    }
    .add-service {
      margin-top: 30px;
    }
  }
  .content-boxes__tag {
    border-top: 1px dashed $generic-border-color;
    padding: 9px 0px;
    margin-top: 16px;
    @include screen-sm() {
      border-top: none;
      text-align: left;
      padding-left: 22px;
    }
    .card-title {
      margin-bottom: 0;
    }
  }
}

.dashed-t-border {
  border-top: 1px dashed $generic-border-color;
}

.dashed-b-border {
  border-bottom: 1px dashed $generic-border-color;
}

.app-c-dim-text,
.app-c-dark-text {
  color: $text-color-light;
  display: block;
  font-size: $text-md;
  line-height: 24px;
  @include cm-transition;
  a {
    color: $neutrals-color3;
    text-decoration: underline;
  }
}

.app-c-dark-text {
  color: $text-color;
  font-weight: $font-weight-medium;
}

.details-flex-box {
  @extend %flex-align-center-justify-space-between-row;
  &.bottom {
    margin: 22px 0px 0px;
    border-top: 1px dashed $generic-border-color;
    padding-top: 16px;
    .app-c-dim-text {
      @include screen-xs() {
        max-width: 246px;
      }
      a {
        white-space: nowrap;
      }
    }
  }
  @include screen-lg() {
    @include align-items(flex-start);
    margin-top: 20px;
  }
  .full-details {
    .timing {
      font-size: $text-lg;
      line-height: 28px;
    }
    h6 {
      font-size: $text-lg;
      line-height: 24px;
    }
    .app-c-dim-text {
      // @extend %truncate;
      // max-width: 250px;
    }
  }
}

.details-flex-box__next {
  @extend %flex-align-start-justify-start-row;
}

.details-flex-box__actions {
  @extend %flex-and-col;
  &.flex-width {
    min-width: 100px;
    @include screen-md {
      min-width: 30px;
    }
  }
}

.app-c-calendar-stamp {
  background: $neutrals-color3-light;
  @include border-radius(16px);
  min-width: 64px;
  width: 64px;
  height: 64px;
  position: relative;
  @extend %flex-all-center-col;
  margin-right: 24px;
  font-family: $font-primary;
  &.inactive {
    background: $form-disabled-custom-color;
  }
  @include screen-xs() {
    margin-right: 22px;
  }
  &::before {
    background: url(/assets/images/dotted-overlay.png) repeat;
    position: absolute;
    content: '';
    top: 8px;
    left: 8px;
    @include border-radius(16px);
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1;
  }
  &__month {
    color: $text-color;
    font-weight: $font-weight-bold;
    line-height: 14.63px;
    font-size: $text-md;
  }
  &__date {
    color: $neutrals-color3;
    font-weight: $font-weight-bold;
    line-height: 28px;
    font-size: 20px;
    &.inactive {
      color: grey;
    }
  }
  &__day {
    color: $text-color;
    font-weight: $font-weight-medium;
    line-height: 9.75px;
    font-size: 8px;
    text-transform: uppercase;
  }
}

.app-m-gallery-scroll {
  //margin-left: 15px;
  @media (max-width: 767px) {
    // width: calc(100vw - 160px);
    .simplebar-content {
      width: auto;
    }
  }
}

@media (max-width: 767px) {
  .photos-video-block {
    .full-window {
      margin-right: -5px !important;
    }
  }
}

.upload-container {
  @media (max-width: 767px) {
    flex-wrap: nowrap !important;
    width: 100%;
  }
}

.app-m-gallery {
  @extend %flex-align-center-justify-start-row;
  position: relative;
  // margin: 0px -12px;
  flex-wrap: nowrap;
  @include screen-sm() {
    min-width: auto;
    //padding-left: 20px;
    margin-top: 11px;
  }
  .app-m-gallery__item,
  .upload-btn-card {
    height: 100px;
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    min-height: 100px;
    background: $card-bg2;
    text-align: center;
    position: relative;
    margin: 0px 12px 0 0;
    @include flex-basis(100%);
    @extend %flex-all-center-col;
    overflow: hidden;
    @include border-radius(10px);
    .upload-new-photos {
      top: 0px !important;
    }
    &.upload-btn-card {
      border: none;
      background: $card-bg1;
      position: sticky;
      left: 0;
      z-index: 99;
      border-radius: 0;
      padding: 0 4px;
      min-width: 120px;
      width: 120px;
      max-width: 120px;
      margin-left: 0px;
    }
    &.private {
      // pointer-events: none;
      img,
      video {
        opacity: 0.19;
        filter: blur(3px);
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      &:after {
        content: '\e916';
        font-family: $app-icons;
        background: $app-brand-primary;
        z-index: 1;
        position: absolute;
        width: 40px;
        height: 40px;
        color: $white;
        border-radius: 8px;
        cursor: pointer;
        font-size: $text-xl;
        @extend %flex-all-center;
      }
      &.partial {
        img,
        video {
          opacity: 1;
          filter: blur(0);
        }
        &:after {
          width: 15px;
          height: 15px;
          font-size: 10px;
          right: 3px;
          bottom: 5px;
        }
      }
      &:hover {
        &:after {
          background: $app-brand-primary-dark;
        }
      }
    }
    &.private-edit {
      // pointer-events: none;
      img,
      video {
        opacity: 0.19;
        filter: blur(3px);
      }
      &:after {
        content: '\e916';
        font-family: $app-icons;
        background: $app-brand-primary;
        z-index: 1;
        position: absolute;
        width: 40px;
        height: 40px;
        color: $white;
        border-radius: 8px;
        cursor: pointer;
        font-size: $text-xl;
        @extend %flex-all-center;
      }
      &.partial {
        img,
        video {
          opacity: 1;
          filter: blur(0);
        }
        &:after {
          width: 15px;
          height: 15px;
          font-size: 10px;
          right: 3px;
          bottom: 5px;
        }
      }
      &:hover {
        &:after {
          background: $app-brand-primary-dark;
        }
      }
    }
    img,
    video {
      object-fit: contain;
      height: 100%;
      width: 100%;
      padding: 5px 0;
    }
    .app-c-btn {
      display: block;
      width: 100%;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
    .custom-checkbox {
      position: absolute;
      top: 5px;
      right: 0;
    }
  }
}

.tab-flex-head {
  flex-direction: column !important;
  @extend %flex-align-center-justify-space-between-row;
  @include screen-sm() {
    flex-wrap: wrap;
    text-align: center;
    @include justify-content(center);
  }
}

.tab-flex__head {
  .nav {
    @include justify-content(start);
    width: 100%;
    @include flex-wrap(noWrap);
    @include screen-sm() {
      @include flex-wrap(wrap);
    }
    .nav-item {
      flex: 1;
    }
  }
  .app-c-btn {
    padding: 10px !important;
  }
}

.app-c-tag-card {
  @extend %flex-align-center-justify-start-row;
  .app-c-tag-card__avatar {
    min-height: 56px;
    min-width: 56px;
    max-height: 56px;
    max-width: 56px;
    @include border-radius(8px);
    @extend %flex-all-center-col;
    &.bg1 {
      background: $neutrals-color4;
    }
    .app-c-tag-card__name {
      color: $white;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      font-size: 20px;
    }
    img {
      min-height: 56px;
      min-width: 56px;
      max-height: 56px;
      max-width: 56px;
      object-fit: cover;
      @include border-radius(8px);
    }
  }
  .app-c-tag-card__texting {
    padding-left: 24px;
    @include screen-sm() {
      padding-left: 15.5px;
    }
    .name-elipse {
      max-width: 392px !important;
      @media (max-width: 870px) {
        max-width: 300px !important;
      }
      @media (max-width: 780px) {
        max-width: 220px !important;
      }
      @media (max-width: 480px) {
        max-width: 100px !important;
      }
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 5px;
      margin-left: 0px;
    }
    h6 {
      font-size: $text-lg;
      line-height: 24px;
      font-family: $font-primary;
      display: flex;
      flex-wrap: wrap;
      > * {
        font-size: $text-lg;
        line-height: 24px;
        font-weight: $font-weight-bold;
        font-family: $font-primary;
        margin-left: 2px;
        &.category1 {
          color: $neutrals-color3;
        }
        &.category2 {
          color: $neutrals-color1;
        }
        &.category3 {
          color: $neutrals-color2;
        }
      }
    }
  }
}

.app-c-memorial-post {
  padding: 16px 0px 0px;
  // @include screen-xs() {
  //   &:not(:empty) {
  //     margin-left: -20px;
  //     margin-right: -20px;
  //   }
  // }
  text-align: center;
  &__head {
    padding: 24px;
    @extend %flex-align-start-justify-space-between-row;
    @include screen-sm() {
      padding: 24px 20px;
    }
  }
  &__banner {
    line-height: normal;
    margin-bottom: 18px;
    position: relative;
    min-height: 320px;
    @extend %flex-justify-center-col;
    overflow: hidden;
    &.-tree {
      background-color: $neutrals-color2-light;
    }
    &.-flower {
      background-color: $neutrals-color3-light;
    }
    &.-spec-list {
      img {
        position: absolute;
      }
    }
    img {
      width: 100%;
      position: relative;
      bottom: 0;
      z-index: 0;
      @include screen-xs() {
        bottom: 0;
        width: 100%;
      }
    }
    &__text {
      padding: 10px;
      position: relative;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: center;
      @extend %flex-all-center-col;
      z-index: 1;
      h6 {
        line-height: 28px;
        font-size: $text-lg;
        font-family: $font-secondary;
        margin-bottom: 40px;
      }
    }
    .app-c-memorial-post__message {
      max-width: 390px;
      margin-bottom: 130px;
      // max-height: 115px;
      // overflow-y: auto;
      @include screen-xs() {
        position: relative;
        max-width: 340px;
      }
      p {
        line-height: 28px;
        margin-top: 40px;
      }
    }
  }
  &__content {
    padding: 0px 24px 16px 24px;
    @extend %flex-align-start-justify-space-between-row;
    @include screen-sm() {
      flex-wrap: wrap;
      @include justify-content(center);
      padding: 0px 20px 16px 20px;
    }
    p {
      line-height: 28px;
      margin-bottom: 12px;
      word-break: break-word;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__stamp-pic {
    min-width: 131px;
    margin-right: 32px;
    @include screen-sm() {
      min-width: 218px;
      margin-right: 0;
      margin-bottom: 16px;
      img {
        width: 100%;
      }
    }
  }
  &__footer {
    @extend %flex-align-center-justify-space-between-row;
    padding: 0px 24px 22px;
    @include screen-sm() {
      // padding: 0px 21px 22px 21px;
      padding: 0px 21px 22px 12px;
    }
    .app-c-link-btn {
      &.liked {
        i {
          color: $app-brand-primary;
        }
      }
      &:hover {
        i {
          color: $app-brand-primary;
        }
      }
      i {
        font-size: 12px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        color: $neutrals-color3-light;
      }
      &:active {
        i {
          transform: scale(0.7);
        }
      }
    }
  }
  .load-more {
    margin-top: 41px;
    @include screen-xs() {
      margin-top: 32px;
    }
  }
}

.app-c-memorial-post-card {
  text-align: left;
  background: $white;
  @include border-radius(16px);
  @include boxShadow(0px 3px 8px 0px rgba(93, 39, 48, 0.1));
  margin: 16px 0px 0px;
  overflow: hidden;
}

.memorial-page-sidebar {
  flex-basis: 320px;
  min-width: 320px;
  margin-left: 50px;
  @include screen-xl() {
    margin-left: 40px;
    flex-basis: 300px;
    min-width: 300px;
  }
  @include screen-lg() {
    margin-left: 0px;
    margin-top: 20px;
  }
  @include screen-md() {
    flex-basis: 100%;
  }
  @include screen-sm() {
    display: none;
  }
  &.memorial-page-sidebar-edit {
    @include screen-sm() {
      display: none !important;
    }
  }
}

.app-mem-card {
  margin: 0px 0px 16px;
  text-align: left;
  display: block;
  padding: 14px 16px 16px;
  @include boxShadow(0px 4px 8px 0px rgba(93, 39, 48, 0.1));
  @include border-radius(16px);
  background: $white;
  border: none;
  @include screen-sm() {
    max-width: 100%;
    margin: auto;
    margin-bottom: 16px;
  }
  h4,
  .h5 {
    font-weight: $font-weight-bold;
    font-size: $text-lg;
    line-height: 24px;
    color: $text-color;
    font-family: $font-primary;
    margin-bottom: 5px;
    .handy-font {
      font-size: 32px;
      line-height: 41.41px;
    }
  }
  .contact-wrapper {
    padding: 16px 0px 16px 0;
    @extend %flexbox;
    a {
      color: $text-color;
    }
    &--prof {
      min-width: 50px;
      width: 50px;
      height: 50px;
      min-height: 50px;
      border-radius: 8px;
      text-align: center;
      font-size: $text-lg;
      font-weight: $font-weight-bold;
      @extend %flex-all-center;
      color: $white;
      img {
        object-fit: cover;
        width: 100%;
      }
      @extend %color-generator;
    }
    &--details {
      margin-left: 16px;
      position: relative;
      width: 100%;
      &-name {
        color: $text-color;
        font-size: $text-md-plus;
        font-weight: $font-weight-semibold;
        margin-bottom: 4px;
        .family-title {
          opacity: 0.7;
        }
      }
      &-contact {
        color: $text-color;
        opacity: 0.7;
        font-size: $text-md;
        padding-right: 55px;
        span {
          word-break: break-word;
          line-height: normal;
        }
        @extend %flex-and-align-center;
        em {
          margin-right: 6px;
        }
      }
      &-request {
        span,
        a {
          letter-spacing: -0.03em;
          cursor: pointer;
          text-decoration-line: underline;
          &:hover {
            text-decoration-line: underline;
            color: $app-brand-primary;
          }
        }
      }
      &-edit {
        position: absolute;
        right: 0;
        bottom: 10px;
        i {
          font-size: 13px;
          max-width: max-content;
          min-width: auto;
          height: auto;
          line-height: normal;
        }
        button + button {
          margin-left: 10px;
        }
      }
    }
  }
}

.app-c-split {
  margin-bottom: 20px;
  width: 100%;
}

.app-c-social-share {
  padding: 4px 0px 5px 0px;
  width: 100%;
  @include screen-md {
    max-width: 400px;
    margin: auto;
  }
  ul,
  .list-group {
    margin: 0;
    padding: 0;
    @extend %flex-align-center-justify-start-row;
  }
  li,
  .list-group-item {
    border: none;
    border-left: 1px dashed $generic-border-color;
    flex: 1;
    padding: 0;
    text-align: center;
    background: none;
    border-radius: 0px;
    &:first-child {
      border-left: none;
    }
  }
  .app-c-social-share__icon {
    all: unset;
    background: none;
    font-size: 18px;
    height: 32px;
    width: 32px;
    margin: auto;
    line-height: 36px;
    text-align: center;
    display: inline-block;
    @include border-radius(50%);
    cursor: pointer;
    &:hover {
      background: $neutrals-color3-light;
    }
    .fb {
      color: #4968ad;
    }
    .insta {
      color: #d35359;
    }
    .whats-app {
      color: #63a53a;
    }
    .mail {
      color: #503e9d;
    }
  }
}

.app-c-split {
  border-top: 1px dashed $generic-border-color;
  position: relative;
  height: 2px;
  text-align: center;
  margin-top: 13px;
  display: block;
  &.-sm {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  span {
    position: absolute;
    top: -13px;
    left: 0;
    right: 0;
    margin: auto;
    color: $text-color-extra-light;
    display: block;
    width: 58px;
    padding: 0px 0px;
    background: $white;
    text-align: center;
  }
}

.slider-special-tributes {
  width: 100%;
  position: relative;
  margin-top: 15px;
  [aria-hidden='true'] {
    max-height: 0;
    overflow: hidden;
  }
  .slider-content-block {
    p {
      word-wrap: break-word;
    }
  }
  .slick-slider {
    position: relative;
    .slick-prev,
    .slick-next {
      position: absolute;
      bottom: inherit;
      top: -34px;
      right: 0;
      left: inherit;
      width: 20px;
      height: 20px;
      color: $app-brand-primary;
      background: none;
      @include border-radius(50%);
      &::before {
        font-family: 'memlo';
        color: $app-brand-primary;
        font-size: 10px;
        opacity: 1;
      }
      &.slick-disabled {
        color: $app-brand-primary-light;
        &::before {
          opacity: 0.5;
        }
      }
    }
    .slick-prev {
      right: 40px;
      &::before {
        content: '\e901';
      }
    }
    .slick-next {
      right: 10px;
      &::before {
        content: '\e902';
      }
    }
  }
  .--tree {
    .slider-tag__texting h5 span {
      color: $radio-toggle;
    }
    p,
    h6 {
      position: relative;
      z-index: 1;
    }
    .slider-content-block {
      background-color: $neutrals-color2-light;
      &:before {
        background: url(/assets/images/tree_bg.png) no-repeat bottom left;
      }
    }
  }
}

.slider-tag-head {
  margin: 0px 16px;
  padding: 16px 0px;
  border-top: 1px dashed $generic-border-color;
  @extend %flex-align-center-justify-start-row;
  .slider-tag__avatar {
    @extend %flex-all-center-col;
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    @include border-radius(8px);
    margin-right: 16px;
    &.bg1 {
      background: $neutrals-color4;
    }
  }
  .slider-tag__name {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    color: $white;
    font-size: $text-lg;
  }
  .slider-tag__texting {
    h5 {
      font-size: 13px;
      //font-family: $font-secondary;
      font-weight: $font-weight-bold;
      line-height: 15.25px;
      margin-bottom: 1px;
      display: flex;
      flex-wrap: wrap;
      .name-elipse {
        max-width: 215px !important;
        margin-right: 5px;
        @media (max-width: 870px) {
          max-width: 300px !important;
        }
        @media (max-width: 780px) {
          max-width: 250px !important;
        }
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      span {
        color: $neutrals-color1;
        margin-block: auto;
      }
    }
    .app-c-time {
      font-size: $text-md;
    }
  }
}

.order-library-btn {
  position: relative;
  float: right;
  padding-right: 0 !important;
}

.order-message {
  @include border-radius(16px);
  border: 1px solid $generic-border-color;
  height: 125px;
  margin-top: 10px;
  margin-bottom: 19px;
  padding: 15px 24px 24px;
  .form-control {
    padding: 0;
    border: none;
    font-size: $text-lg;
    line-height: 22px;
    resize: none;
    min-height: 62px;
    &::-webkit-scrollbar {
      background: $bg-scroll;
      @include border-radius(100px);
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: $app-brand-primary;
      @include border-radius(100px);
      width: 8px;
    }
    @include placeholder {
      color: $text-placeholder;
      font-size: $text-lg;
    }
    &:focus {
      outline: none;
      @include noShadow();
    }
  }
}

.app-c-create-post {
  @include border-radius(16px);
  border: 1px solid $generic-border-color;
  min-height: 212px;
  margin-bottom: 19px;
  padding: 15px 24px 24px;
  &.flex-order {
    @extend %flex-and-col;
    .form-control {
      min-height: 54px;
      height: 100%;
      @include screen-xs() {
        order: 2;
        margin-top: 19px;
        min-height: 112px;
      }
    }
  }
  @include screen-xs() {
    &.share-photo {
      margin-bottom: 5px;
      min-height: 112px;
      padding: 0px 0px 24px;
      border: none;
    }
  }
  .form-control {
    padding: 0;
    border: none;
    font-size: $text-lg;
    line-height: 22px;
    resize: none;
    min-height: 170px;
    &::-webkit-scrollbar {
      background: $bg-scroll;
      @include border-radius(100px);
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: $app-brand-primary;
      @include border-radius(100px);
      width: 8px;
    }
    @include screen-xs() {
      &.share-photo--form {
        min-height: 112px;
        border: 1px solid $generic-border-color;
        padding: 15px 24px;
        @include border-radius(16px);
      }
    }
    @include placeholder {
      color: $text-placeholder;
      font-size: $text-lg;
    }
    &:focus {
      outline: none;
      @include noShadow();
    }
  }
}

.slider-content-block {
  text-align: center;
  padding: 24px 24px 63px;
  position: relative;
  margin-bottom: 8px;
  display: block;
  width: 100%;
  background: url(/assets/images/dots1.svg) no-repeat bottom left $neutrals-color3-light;
  &::before {
    background: url(/assets/images/purple-bouquet1.png) no-repeat bottom left;
    content: '';
    left: 0;
    bottom: 0;
    width: 123px;
    height: 76px;
    display: block;
    position: absolute;
  }
  p {
    font-size: $text-md;
    line-height: 24px;
    margin-bottom: 2px;
  }
  h6 {
    line-height: 28px;
    font-size: $text-md;
  }
}

.select-card-holder {
  @extend %flex-align-start-justify-start-row;
  flex-wrap: wrap;
  position: relative;
  margin: 0px -8px;
  padding-top: 27px;
  @include screen-xs() {
    flex-wrap: nowrap;
    padding-top: 0px;
  }
  .select-card__box {
    width: 131px;
    height: 146px;
    @include border-radius(16px);
    background: #ccc;
    flex-basis: calc((100% / 4) - 16px);
    margin: 0px 8px 27px 8px;
    position: relative;
    cursor: pointer;
    @include screen-md() {
      flex-basis: calc((100% / 3) - 16px);
    }
    @include screen-xs() {
      flex-basis: 131px;
      min-width: 131px;
      margin-bottom: 0;
      margin-top: 27px;
    }
    &.bg1 {
      background: $card-bg1;
    }
    &.bg2 {
      background: $card-bg2;
    }
    &.bg3 {
      background: $card-bg3;
    }
    &.bg4 {
      background: $card-bg4;
    }
    img {
      position: absolute;
      margin: auto;
      bottom: 47px;
      left: 0;
      right: 0;
      max-height: 112px;
      min-height: 110px;
      max-width: 128px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    .app-c-radion-check {
      position: absolute;
      bottom: 14px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.media-attached {
  position: relative;
  img {
    @include border-radius(16px);
  }
  .app-close-btn {
    position: absolute;
    right: -15px;
    top: -15px;
    @extend %flex-all-center;
  }
}

.delete-text {
  text-align: center;
}

.memorial-intro {
  white-space: pre-line;
  &.edit-box {
    position: relative;
    text-align: center;
    background: none;
    @include border-radius(16px);
    line-height: 28px;
    color: $text-placeholder;
    padding: 15px 24px 58px 24px;
    border: 1px solid $generic-border-color;
    margin-bottom: 16px;
    .app-l-form-input {
      padding-bottom: 8px;
    }
    @include screen-sm() {
      padding: 15px 24px 15px 24px;
      height: 170px;
    }
    @include screen-xs() {
      padding: 15px 6px 15px;
      height: auto;
    }
    @include border-radius(16px);
    .form-control {
      border: none;
      text-align: left;
      resize: none;
      height: 313px;
      padding: 0;
      font-size: $text-lg;
      padding-right: 25px;
      margin-right: -30px;
      display: inline-block;
      @include screen-sm() {
        height: 145px;
        margin-bottom: 0;
        margin-right: 0;
        padding: 0 10px;
      }
      &::-webkit-scrollbar {
        background: $bg-scroll;
        @include border-radius(100px);
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: $app-brand-primary;
        @include border-radius(100px);
        width: 8px;
      }
      &:focus {
        outline: none;
        @include noShadow();
      }
    }
  }
}

.memorial-intro-edit-action {
  position: absolute;
  right: 16px;
  bottom: 30px;
  @include screen-sm() {
    position: relative;
    right: inherit;
    bottom: inherit;
    display: flex;
    justify-content: center;
  }
  @include screen-xs() {
    button.app-c-btn.sm {
      width: 100%;
      padding: 13px;
    }
  }
  @include screen-xs() {
    flex-direction: column;
    button {
      margin: 0 0 5px 0 !important;
      justify-content: center;
    }
  }
}

.add-services-block {
  background: $card-bg1;
  height: 226px;
  @include border-radius(16px);
  @extend %flex-all-center-col;
  @include screen-sm() {
    height: 186px;
  }
}

.app-c-body-text-bold {
  font-weight: $font-weight-bold;
  color: $text-color-light;
  font-family: $font-primary;
  line-height: 24px;
  margin-bottom: 15px;
}

.requestAccessModal {
  .modal-body {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .app-c-body-text-bold {
    font-weight: $font-weight-bold;
    color: $text-color-light;
    font-family: $font-primary;
    line-height: 24px;
    .d-flex {
      justify-content: center;
      div {
        + div {
          margin-left: 40px;
          @media (max-width: 470px) {
            margin-left: 0;
          }
        }
      }
    }
    @include screen-sm() {
      margin-top: 30px;
    }
    button {
      margin: 0 auto;
      display: flex;
      margin-top: 40px;
    }
    @media (max-width: 470px) {
      margin-bottom: 0px;
      .d-flex {
        flex-direction: column;
        div {
          width: 100%;
          text-align: left !important;
          margin-bottom: 10px;
        }
      }
      button {
        margin-top: 20px;
      }
    }
  }
  @include screen-xs() {
    h4 {
      margin-bottom: 10px;
    }
  }
}

.bottom-5 {
  padding-bottom: 104px;
  @include screen-xl {
    padding-bottom: 80px;
  }
  @include screen-lg {
    padding-bottom: 66px;
  }
}

.customer-review-section {
  padding: 30px 0px 138px 0px;
  @include screen-sm() {
    padding: 0px 0px 0px 0px;
  }
  h3 {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.all-memories-title {
  @include screen-lg {
    font-size: $text-lg-plus;
  }
}

.customer-review-slider {
  @include transform(rotate(0deg));
  width: 100%;
  position: relative;
  padding: 10px 0px 10px 0px;
  @include screen-sm() {
    width: 100%;
  }
  .slick-slider {
    padding: 0px;
    position: relative;
    margin: 0px -8px;
    @include screen-sm() {
      padding-bottom: 84px;
    }
    .slick-track {
      margin: 0;
    }
    .slick-prev,
    .slick-next {
      bottom: -98px;
      left: inherit;
      right: 0;
      @include screen-sm() {
        left: 0;
        right: 0;
        top: inherit;
        bottom: 0px;
        margin: auto;
      }
    }
    .slick-prev {
      left: inherit;
      right: 48px;
      @include screen-sm() {
        left: 0px;
      }
    }
    .slick-next {
      @include screen-sm() {
        right: -47px;
        left: 0;
      }
    }
  }
  .slick-slide {
    @include screen-sm() {
      // width: 304px !important;
    }
  }
}

.app-c-card-grey {
  background: $card-bg2;
  padding: 24px;
  padding-right: 12px;
  @include border-radius(8px);
  margin: 0px 8px;
  .cs__scroll {
    min-height: 385px;
    max-height: 385px;
    padding-right: 15px;
    .simplebar-track.simplebar-vertical {
      width: 4px;
      right: 0;
      background: rgba(9, 30, 62, 0.12);
    }
    .simplebar-track {
      overflow: visible;
    }
    .simplebar-visible:before {
      opacity: 1 !important;
    }
    .simplebar-scrollbar:before {
      background: $app-brand-primary;
      left: -1px;
      right: -1px;
    }
  }
}

.customer-tag {
  @extend %flex-align-center-justify-start-row;
  position: relative;
  .rating-platform {
    position: absolute;
    top: 0;
    right: -2px;
  }
  .customer-tag__avatar {
    width: 49px;
    height: 49px;
    flex-shrink: 0;
    background: $neutrals-color4;
    @include border-radius(8px);
    overflow: hidden;
    &.text {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  .customer-tag__texting {
    padding-left: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    &__name,
    &__date {
      @extend %truncate;
      max-width: 150px;
    }
  }
  .customer-tag__name {
    display: block;
    font-weight: $font-weight-semibold;
    font-family: $font-secondary;
    color: $text-color;
    line-height: 24px;
    font-size: $text-lg;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
  .customer-tag__date {
    font-size: $text-md;
    line-height: 24px;
    color: $text-color-light;
  }
}

.reply-box {
  padding: 5px 6px 2px 6px;
  .reply-comment {
    padding-left: 13px;
  }
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1px 3px;
}

.on {
  color: $neutrals-color1;
}

.off {
  color: $neutrals-color7;
}

.app-c-rating {
  display: flex;
  width: 160px;
  position: relative;
  height: 32px;
  margin-top: 12.27px;
  margin-left: auto;
  margin-right: auto;
  .star-wrapper {
    min-width: 140px;
  }
  .starFull {
    position: relative;
    height: 100%;
    &::after {
      height: 100%;
      background: url(/assets/images/StarL.svg) repeat-x;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: translateX(0%);
      content: '';
      z-index: 1;
    }
  }
  .starEmpty {
    &::before {
      height: 100%;
      background: url(/assets/images/noStarL.svg) repeat-x;
      position: absolute;
      top: 0;
      left: 0;
      width: 140px;
      transform: translateX(0%);
      content: '';
      z-index: 0;
    }
  }
  .app-c-rating-nos {
    font-size: $lgs;
    font-weight: $font-weight-extra-bold;
    margin-left: auto;
    top: 1px;
    left: 10px;
    position: relative;
  }
  &.view {
    width: 69px;
    height: 22px;
    margin-top: 6.27px;
    margin-right: 18px;
    .star-wrapper {
      min-width: 108px;
    }
    .starFull {
      &::after {
        background-size: 17px;
        width: 79%;
      }
    }
    .starEmpty {
      &::before {
        width: 86px;
        background-size: 17px;
      }
    }
  }
  &.star1 {
    &::after {
      width: 14px;
    }
  }
  &.star2 {
    &::after {
      width: 28px;
    }
  }
  &.star3 {
    &::after {
      width: 42px;
    }
  }
  &.star4 {
    &::after {
      width: 56px;
    }
  }
  &.star5 {
    &::after {
      width: 70px;
    }
  }
}

.review {
  height: 140px !important;
}

.review-reply {
  margin-bottom: 7px;
  padding: 7px;
  height: 80px !important;
}

.review-edit {
  float: right;
}

.customer-review-quote {
  padding: 12.27px 0px 0px;
  p {
    height: auto;
    padding-bottom: 10px;
    padding-right: 15px;
    word-break: break-word;
    &::-webkit-scrollbar {
      background: $bg-scroll;
      @include border-radius(100px);
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: $app-brand-primary;
      @include border-radius(100px);
      width: 8px;
    }
  }
}

.scroll-clip {
  overflow-x: hidden;
}

.app-c-tab-wrapper {
  .nav.cs-margin {
    margin-left: 12px;
  }
}

.show-all-empty {
  text-align: center;
  margin: auto;
}

.select-bg-holder {
  @extend %flex-justify-start-row;
  position: relative;
  margin: 8px 0px 0px 0px;
  flex-wrap: wrap;
  overflow: visible;
  @include screen-sm() {
    margin: 0px 0px 0px 0px;
    @include justify-content(center);
  }
  &.center-align {
    min-height: 335px;
    align-items: center;
  }
  &.manageMedia {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    @include screen-sm() {
      grid-template-columns: repeat(3, 1fr);
      gap: 6px;
    }
    @media (max-width: 480px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 340px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .select-bg__card {
      margin: 0;
    }
    img,
    video {
      min-width: 100%;
      width: 100%;
      object-fit: contain;
      height: 100%;
      padding: 5% 0;
    }
  }
}

.select-bg__card {
  position: relative;
  margin-right: 12px;
  flex-basis: calc((100% / 4) - 24px);
  margin-top: 24px;
  padding: 0;
  line-height: normal;
  background: #ccc;
  display: block;
  font-size: 0;
  cursor: pointer;
  height: 100px;
  @include border-radius(8px);
  @include screen-sm() {
    flex-basis: calc((100% / 3) - 12px);
    margin-left: 6px;
    margin-right: 6px;
  }
  @media (max-width: 480px) {
    flex-basis: calc((100% / 2) - 12px);
  }
  @media (max-width: 340px) {
    flex-basis: calc((100% / 1) - 12px);
  }
  &.no-cursor {
    cursor: auto;
    pointer-events: none;
  }
  img {
    min-width: 100px;
    width: 100px;
    height: 100px;
    @include border-radius(8px);
    object-fit: cover;
    @include screen-sm() {
      width: 100%;
    }
  }
  .app-c-radion-check {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0px;
    margin: auto;
  }
}

.popup-content-box {
  @extend %flex-align-center-justify-start-row;
  @include screen-sm() {
    flex-wrap: wrap;
  }
  .popup-content-box__pic,
  .popup-content-box__texting {
    flex: 1;
    @include screen-sm() {
      flex-basis: 100%;
    }
  }
  .popup-content-box__pic {
    overflow: hidden;
    pointer-events: none;
    @include screen-sm() {
      order: 2;
    }
    .img-tree {
      min-width: 255px;
      position: relative;
      left: -65px;
    }
  }
  .popup-content-box__texting {
    h6 {
      margin-bottom: 8px;
      line-height: 24px;
    }
    p {
      line-height: 28px;
      color: $text-color-light;
      overflow-y: auto;
      height: auto;
      max-height: 400px;
      &::-webkit-scrollbar {
        background: $bg-scroll;
        @include border-radius(100px);
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: $app-brand-primary;
        @include border-radius(100px);
        width: 8px;
      }
    }
  }
}

.send-flower-box {
  position: relative;
  margin-top: -25px;
  text-align: center;
  img {
    display: inline-block;
    pointer-events: none;
  }
}

.app-c-border-box-wrapper {
  @extend %flex-align-center-justify-space-between-row;
  @include screen-sm() {
    flex-wrap: wrap;
  }
}

.app-c-border-box {
  border: 1px dashed $generic-border-color;
  @include border-radius(16px);
  margin-top: 16px;
  flex-basis: calc((100% / 2) - 8px);
  .app-c-checkbox__item {
    padding: 20px 24px;
    cursor: pointer;
  }
  @include screen-sm() {
    flex-basis: 100%;
  }
}

.app-c-upload-btn {
  position: absolute;
  margin-top: 0px;
  cursor: pointer;
  z-index: 1;
  width: 100%;
  height: 100%;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  @include screen-sm() {
    margin-top: 6px;
    width: 103px;
  }
  .app-c-upload-browse {
    opacity: 0;
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ccc;
  }
  .app-c-link-btn__text {
    color: $white;
    font-size: $text-md;
    font-weight: $font-weight-bold;
    cursor: pointer;
    text-shadow: 0 0 3px black;
    display: block;
    text-align: center;
    @include screen-sm() {
      font-weight: $font-weight-extra-bold;
      padding: 0px 5px;
      line-height: 14.63px;
    }
  }
}

.initials {
  text-align: center;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 40px;
  font-family: $font-primary;
  letter-spacing: -0.03em;
  color: $white;
}

.app-c-tag-card__initials {
  color: $white;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-size: 20px;
}

.tab-content {
  .fade {
    opacity: inherit !important;
  }
  .active {
    pointer-events: all;
  }
}

.button-grp-mem-card {
  @extend %flexbox;
  button {
    @include justify-content(center);
  }
}

.datepicker-publish-date {
  @extend %flex-and-col;
  &--row {
    & + .datepicker-publish-date--row {
      margin-top: 16px;
    }
  }
}

.upload-container {
  text-align: center;
  @extend %flexbox;
  .upload-btn-card {
    @include justify-content(center);
    padding: 5px;
    border-radius: 10px;
    background-color: hsla(253, 31%, 94%, 0.35);
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 100px;
    max-width: 100px;
    margin-right: 10px;
    .upload-new-photos {
      top: 0 !important;
      padding: 8px;
      font-weight: 600;
    }
    button {
      @include justify-content(center);
      font-weight: 600;
    }
  }
  .app-m-gallery-scroll {
    flex: 1;
  }
  input {
    display: none;
  }
  .upload-new-photos {
    position: absolute;
    right: 50%;
    @include transform(translate(50%, -50%));
    top: 50%;
  }
  &.uploaded {
    .upload-new-photos {
      position: relative;
      right: 0;
      top: 15px;
      @include transform(none);
    }
  }
}

.info--tooltip {
  display: inline-block;
}

.info--tooltip-grant-request {
  display: inline-block !important;
}

.spinner-tribute {
  text-align: center;
  position: absolute;
  left: 45%;
  top: 45%;

  .spinner-size {
    width: 3rem;
    height: 3rem;
  }
}

.play-btn {
  position: relative;

  .play-btn-icon {
    position: absolute;
    top: 46%;
    left: 46%;
    cursor: pointer;
  }
}

.uploaded-images--tribute {
  position: relative;
  &--item {
    position: relative;
    margin: 10px 0;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      @include border-radius(16px);
    }
  }
}

.text-decor-none {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

place-flex {
  display: flex;
}

.place-add {
  width: 20px !important;
}

.add-profile-owner {
  color: $text-color;
  font-size: $text-md;
  font-weight: $font-weight-bold;
  border: 1px solid $light-grayish-blue;
  @include border-radius(16px);
  padding: 23px 16px;
  button {
    width: 16px;
    height: 16px;
    padding: 0;
    min-width: 16px;
    @include border-radius(2px);
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    line-height: normal;
    font-weight: $font-weight-semibold;
  }
}

.add-contact-holder {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5rem;
  @include screen-sm() {
    grid-template-columns: repeat(1, 1fr);
  }
  .app-c-input-box {
    width: 100%;
    max-width: 100%;
    position: relative;
    select {
      height: auto;
    }
  }
}

// 06/07/2021
.order-summary-amount {
  font-weight: $font-weight-bold;
  color: $text-color;
  font-size: 16px;
  margin: 0;
  .col {
    padding: 0;
  }
}

.simplebar-track.simplebar-vertical {
  width: 6px;
  right: -6px;
}

.simplebar-visible:before {
  opacity: 1 !important;
}

.simplebar-scrollbar:before {
  background: $app-brand-primary;
}

.app-c-select-memorial {
  margin-left: 20px !important;
  .select-memorial {
    background-color: $white;
    border: 1px solid rgba(9, 30, 62, 0.2);
    @include border-radius(8px);
    width: 173px;
    position: relative;
    height: 40px;
    margin-right: 0 !important;
    &:focus {
      outline: none;
    }
    @include screen-sm {
      width: auto;
      padding-right: 14.5px;
      padding-left: 14px;
      min-width: 61px;
    }
    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      border-color: $app-brand-primary;
      background-color: $white;
      @include boxShadow(none);
    }
    &::after {
      content: '\e903';
      font-family: 'memlo';
      position: absolute;
      right: 18px;
      top: 12px;
      font-size: 10px;
      color: $app-brand-primary;
      border: 0;
      @include screen-sm {
        right: 14px;
        color: $text-color;
      }
    }
  }
  .app-c-input__label {
    margin: -4px 0 0 0;
  }
  .show {
    .dropdown-menu {
      max-height: 138px;
      overflow-y: auto;
      min-width: 225px;
      .app-c-language-text {
        margin-left: 0 !important;
      }
    }
  }
}

.app-c-memorial-post__footer {
  button {
    i {
      color: $neutrals-color3-light;
      background: transparent !important;
    }
    &:hover {
      i {
        color: $neutrals-color3-light !important;
      }
    }
    &.liked {
      i {
        color: $app-brand-primary;
      }
      &:hover {
        i {
          color: $app-brand-primary !important;
        }
      }
    }
  }
}

// input:-webkit-autofill, confirm with design team
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   -webkit-box-shadow: 0 0 0 30px white inset !important;
// }
@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

::selection {
  background: #ffb7b7;
  /* WebKit/Blink Browsers */
  color: white;
}

::-moz-selection {
  background: #ffb7b7;
  /* Gecko Browsers */
  color: white;
}

.app-l-visitation-details {
  @include screen-xs {
    flex-wrap: wrap;
  }
  .equal-split {
    flex: 1 !important;
    @include screen-xs {
      flex: 0 0 100% !important;
      margin: 0 !important;
    }
  }
}

.app-l-service-details {
  .select-box {
    height: 100%;
    padding: 0px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .content-boxes__left {
    padding-right: 50px;
    @include screen-md() {
      margin-bottom: 20px;
    }
  }
  .app-c-card {
    overflow: visible;
  }
}

.custom-orders-style {
  display: flex;
  @include screen-md() {
    display: inline;
    width: 100%;
    padding-top: 0;
    position: relative;
  }
  @media (min-width: 768px) {
    display: flex;
  }
}

.app-l-memorial-search {
  display: flex;
  width: 100%;
  @include screen-md() {
    width: 100%;
  }
  .app-l-search-item {
    .app-l-form-input {
      margin-right: 0;
    }
  }
  .btn-primary {
    margin-left: 10px !important;
  }
  @include screen-sm() {
    .app-l-search-item {
      width: 100%;
      flex: 0 0 100%;
      .app-l-form-input {
        margin-right: 0;
      }
    }
    .btn-primary {
      width: 100% !important;
      flex: 0 0 100% !important;
      margin-top: 10px !important;
      margin-left: 0 !important;
    }
  }
  .app-c-form {
    &::placeholder {
      font-size: $text-md;
      letter-spacing: -0.04em;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #091e3e;
      opacity: 0.75;
      font-size: 14px;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #091e3e;
      opacity: 0.75;
      font-size: 14px;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #091e3e;
      opacity: 0.75;
      font-size: 14px;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #091e3e;
      opacity: 0.75;
      font-size: 14px;
    }
  }
  @include screen-sm() {
    flex-wrap: wrap;
  }
  .basic-update {
    width: 300px;
    &.dashborad-input {
      .equal-split {
        flex: auto;
      }
    }
    @include screen-sm() {
      width: 100% !important;
      margin-bottom: 0;
      &.-mr-flex-col {
        flex-direction: column;
      }
      .equal-split + .equal-split {
        margin: 0 0 0 8px !important;
      }
    }
    @include screen-xs() {
      flex-direction: column;
      .equal-split + .equal-split {
        margin: 10px 0 0 0px !important;
      }
    }
    .app-c-input__label {
      line-height: 18px;
    }
    .app-c-input-box {
      margin-top: 0;
      position: relative;
      .rs-picker-toggle {
        position: absolute !important;
        height: 100% !important;
        top: 0 !important;
        left: 0 !important;
        padding: 24px 0 0 17px !important;
      }
    }
  }
  .app-l-form-input {
    flex: 1;
  }
  .btn-primary,
  .form-control,
  .app-c-input-box {
    height: 56px;
    min-height: 56px;
  }
  .app-l-form-input {
    margin: 0 8px;
    @include screen-sm() {
      margin-left: 0;
      flex: 0 0 calc(100% - 138px);
    }
  }
  .btn-primary {
    width: 130px;
    flex: 0 0 130px;
  }
  .form-control {
    border: 1px solid $generic-border-color !important;
  }
}

.app-l-event-select {
  .app-c-checkbox__item {
    margin-right: 10px !important;
  }
  .app-c-check-label {
    margin-left: 6px;
  }
}

.app-l-form-input {
  &.disabled {
    .app-c-input-box {
      background: $disabled;
      cursor: not-allowed;
      input {
        opacity: 0.8;
      }
    }
  }
}

.app-l-edit-block {
  position: absolute;
  right: 0;
  bottom: 0;
}

.star-rating {
  margin-top: 5px;
  button {
    outline: none !important;
    padding: 0;
    &.disabled,
    &[disabled] {
      cursor: auto !important;
    }
  }
  .star {
    font-size: $lgs;
  }
}

.partner-intro {
  padding-top: 7px;
}

.memorial-profile__texting.invert {
  .memorial__tagging {
    ul {
      li {
        color: $text-color;
      }
    }
  }
}

.app-l-reply-comments-holder {
  text-align: right;
  textarea {
    @include border-radius(6px);
    border-color: $input-border-color;
    margin-bottom: 16px;
    box-shadow: none !important;
    outline: none !important;
    font-size: 14px;
    height: 70px !important;
    resize: none;
    &:focus {
      border-color: $app-brand-primary;
    }
  }
}

.app-l-add-reviews-modal {
  h6 {
    line-height: 22px;
  }
  &--rating {
    padding-top: 10px;
    padding-bottom: 5px;
    .review {
      height: 90px !important;
      @include border-radius(16px);
      border-color: $input-border-color;
      resize: none;
      box-shadow: none;
      outline: none;
    }
    .star {
      font-size: 30px;
    }
  }
}

.app-c-memorial-post__banner {
  .row {
    .col {
      margin-left: 24px;
    }
  }
}

.app-c-edit-btn,
.app-mem-card-mobile-view {
  display: none;
  @include screen-sm() {
    display: block;
  }
}

.app-l-amount-of-tree {
  .app-l-form-input {
    width: 100%;
  }
}

.app-l-location-search-input {
  div {
    flex: 1;
  }
  button {
    height: 60px;
    width: 60px;
    min-width: 60px;
    @include border-radius(16px);
    .icon-check {
      margin-left: 0;
    }
  }
}

.app-l-edit-review-box {
  @include flexbox;
  width: 100%;
  margin-bottom: 10px;
  .app-l-form-input {
    width: 100%;
    padding-left: 0;
    div {
      width: 100%;
      padding-left: 0;
    }
    textarea {
      height: 70px !important;
      resize: none;
      box-shadow: none;
      font-size: 14px;
      &:focus {
        border-color: #ed8294;
      }
    }
  }
  .float-right {
    width: 30px;
    padding-left: 0;
    margin-left: 10px;
  }
  .review-reply {
    height: 67px !important;
    resize: none;
    @include border-radius(6px);
    font-size: 14px;
  }
  div {
    padding-left: 5px;
    width: 40px;
    button {
      width: 30px;
      min-width: 30px;
      margin: 0 0 5px;
      @include flexbox;
      height: 30px;
      @include align-items(center);
    }
  }
}

.app-l-review-reply-list {
  img {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    @include border-radius(8px);
    overflow: hidden;
    object-fit: cover;
    margin-right: 10px;
  }
  .app-c-tag-card__initials {
    width: 30px;
    height: 30px;
    background: $neutrals-color4;
    @include border-radius(8px);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  .media-body {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    p {
      font-size: 10px;
      color: $text-color;
      line-height: 20px;
      white-space: normal;
      word-break: break-word;
    }
    .rating-platform {
      position: absolute;
      top: 0;
      right: -2px;
    }
    .customer-tag__texting {
      position: relative;
      @include flex-direction(column);
      @include justify-content(start);
      @include align-items(flex-start);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      &.__name,
      &.__date {
        @extend %truncate;
        max-width: 150px;
      }
      &__date {
        line-height: normal;
      }
      .app-l-edit-block {
        bottom: 2px;
        i {
          font-size: 10px;
          min-width: 16px;
          height: 16px;
          line-height: 16px;
        }
      }
    }
    .customer-tag__name {
      display: block;
      font-weight: $font-weight-semibold;
      font-family: $font-secondary;
      color: $text-color;
      line-height: normal;
      font-size: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      max-width: 90%;
    }
    .customer-tag__date {
      font-size: 10px;
      line-height: normal;
      color: $text-color-light;
      margin-top: 4px;
      float: left;
    }
  }
}

.app-l-print-invoice {
  padding-top: 30px;
  h1 {
    font-size: 24px;
    padding-top: 30px;
  }
}

.app-l-flowers-card {
  position: relative;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-top: 0;
  margin-bottom: 30px;
  .app-c-radion-check {
    bottom: 12px;
  }
  .app-l-flower-name {
    width: 100%;
    position: absolute;
    background: $dawnPink;
    color: $carbonGrey;
    font-size: 13px;
    bottom: -20px;
    padding: 5px;
    text-align: center;
    border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -ms-border-radius: 0 0 5px 5px;
    -o-border-radius: 0 0 5px 5px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .app-l-price-badge {
    height: 20px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    left: -6px;
    top: 14px;
    background: $app-brand-primary;
    min-width: 40px;
    font-size: 14px;
    text-align: center;
    color: $white;
    font-weight: $font-weight-semibold;
    font-size: 14px;
    line-height: 20px;
    &:before,
    &:after {
      content: '';
      position: absolute;
    }
    &:before {
      height: 0;
      width: 0;
      top: -6px;
      left: 0px;
      border-bottom: 6px solid #bc4f61;
      border-left: 6px solid transparent;
    }
    &:after {
      height: 0;
      width: 0;
      right: -9px;
      border-top: 10px solid $app-brand-primary;
      border-bottom: 10px solid $app-brand-primary;
      border-right: 10px solid transparent;
    }
  }
}

.app-business-slider-holder {
  width: 265px;
  padding-top: 20px;
  @include screen-md {
    width: 100%;
  }
  .slick-track {
    @extend %flexbox;
  }
  .app-c-card {
    margin: 12px;
    width: 200px;
  }
  .slick-slide {
    width: 100%;
  }
  .slick-slider {
    padding-bottom: 96px;
    position: relative;
    @include screen-lg() {
      padding-bottom: 0px;
    }
    .slick-prev,
    .slick-next {
      position: absolute;
      width: 30px;
      height: 30px;
      background: $app-brand-primary;
      @include border-radius(50%);
      z-index: 9;
      margin-top: -30px;
      &::before {
        font-family: 'memlo';
        color: $white;
        font-size: 12px;
        opacity: 1;
      }
      &.slick-disabled {
        background: $app-brand-primary-light;
        &::before {
          opacity: 1;
        }
      }
    }
    .slick-prev {
      left: 12px;
      &::before {
        content: '\e901';
      }
    }
    .slick-next {
      right: 12px;
      &::before {
        content: '\e902';
      }
    }
  }
  .app-l-dashboard__account-preview {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column);
    &--title,
    &--profile,
    &--fullName,
    &--phone,
    &--contact {
      @include flexbox;
      @include justify-content(center);
      width: 100%;
    }
    &--fullName {
      display: block;
      text-align: center;
    }
    &--profile {
      margin-bottom: 60px;
      @include screen-md {
        margin-bottom: 30px !important;
      }
      img {
        border-radius: 16px !important;
        -webkit-border-radius: 16px !important;
        -moz-border-radius: 16px !important;
        -ms-border-radius: 16px !important;
        -o-border-radius: 16px !important;
      }
      &:after {
        right: 20px;
        left: inherit !important;
      }
    }
  }
}

body.German {
  .navbar-nav {
    .nav-link {
      @include screen-xl() {
        margin-left: 14px;
        font-size: 12px;
      }
      @include screen-lg() {
        margin-left: 10px;
      }
    }
  }
  .content-box-service-list {
    .nav {
      .nav-link {
        margin: 0 3px 5px;
      }
    }
  }
  .app-l-page-sub-heading {
    font-size: 24px;
  }
  .app-c-tab-wrapper.icon-tab .nav-link {
    font-size: 10px;
  }
  .app-c-tab-wrapper.icon-tab .nav-link i {
    margin-right: 10px;
  }
  .app-c-mw-form--title__heading {
    font-size: 24px !important;
    .lovely-font {
      font-size: 80px !important;
    }
  }
  .app-mem-card {
    .app-c-card-btn {
      font-size: 10px;
    }
  }
}

.app-l-page-sub-heading {
  font-size: 30px;
  width: 100%;
}

.app-c-enter-location {
  width: 100%;
  .autocomplete-dropdown-container {
    position: absolute;
    bottom: auto;
    top: 43px;
    left: 17px;
    z-index: 9999;
  }
}

.app-c-location-search {
  position: relative;
  z-index: 10;
}

.app-l-search-item {
  position: relative;
  flex: 1;
  margin-bottom: 10px;
  .memorial__edit-button {
    position: absolute;
    top: 16px;
    right: 12px;
  }
  .form-group {
    &::before {
      content: '\e956';
      font-family: $app-icons;
      position: absolute;
      top: 30px;
      @include transform(translate(0, -50%));
      z-index: 1;
      left: 30px;
    }
  }
  .app-c-form {
    padding-left: 50px;
    color: $text-color;
    min-height: 48px;
    filter: none;
    border-color: $lightGray !important;
    @include screen-md() {
      max-width: 100%;
      width: 100%;
      &::placeholder {
        font-size: $text-md;
        letter-spacing: -0.04em;
      }
    }
  }
  @include screen-sm() {
    margin-top: 20px;
    + button {
      margin-top: 20px;
    }
  }
}

.no-scroll {
  overflow: visible;
}

.time-slot-wrapper {
  flex-wrap: wrap;
  .time-range.app-c-input-box {
    min-width: 260px;
    max-width: 274px;
    margin-right: 8px;
    @include screen-md() {
      min-width: 100%;
      max-width: 100%;
    }
    + .time-range.app-c-input-box {
      margin-left: 0;
    }
  }
}

.memorial__rating-based {
  margin-top: 5px;
  display: inline-block;
  font-size: $text-lg;
}

.react-share__ShareButton {
  outline: none !important;
  box-shadow: none !important;
}

.private-card {
  // pointer-events: none;
  position: relative;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  img {
    opacity: 0.8;
    filter: blur(5px);
    pointer-events: none;
  }
  &:after {
    content: '';
    font-family: $app-icons;
    background: $red;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 135px;
    border-radius: 16px 16px 0 0;
    cursor: pointer;
    font-size: $text-xl;
    @extend%flex-all-center;
    opacity: 0.05;
    left: 0;
    top: 0;
    @media (max-width: 767px) {
      width: 90px;
      height: 100%;
      border-radius: 16px 0 0 16px;
    }
  }
  &:before {
    content: '';
    background: url(/assets/images/block.svg) no-repeat bottom left;
    background-position: center;
    color: $red;
    font-size: 36px;
    font-family: $app-icons;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 135px;
    z-index: 1;
    @media (max-width: 767px) {
      width: 90px;
      height: 100%;
      background-size: 35px;
      left: 0;
    }
  }
}

.app-change-bg-button {
  z-index: 6;
  &.remove-btn {
    top: 60px;
    @media (max-width: 640px) {
      top: 80px !important;
    }
    @media (max-width: 480px) {
      top: 100px !important;
    }
  }
  @media (max-width: 640px) {
    top: 60px !important;
  }
  @media (max-width: 480px) {
    top: 80px !important;
  }
}

.app-c-modal.-library {
  .modal-content {
    .modal-footer {
      padding: 0 24px 24px;
      @media (max-width: 640px) {
        flex-wrap: nowrap;
        padding: 0 20px 24px;
      }
    }
  }
}

.modal-footer {
  @media (max-width: 640px) {
    flex-wrap: nowrap;
  }
}

.app-c-modal {
  .modal-content {
    .modal-footer {
      padding: 0;
    }
  }
}

.memorial-profile-card.set-mid {
  .memorial-profile__texting {
    overflow: visible !important;
  }
}

.photoVideo {
  @media (max-width: 767px) {
    flex-wrap: wrap;
    h5,
    .row {
      width: 100%;
    }
    .row {
      margin: 0 !important;
      .col {
        margin-right: auto;
        display: flex;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-left: 0;
      }
    }
  }
}

.obituary-post-image {
  max-height: 400px;
  object-fit: contain;
}

.app-c-memorial-post__texting {
  padding: 0 24px !important;
}

button {
  outline: none !important;
}

.tippy-touch {
  cursor: pointer !important;
}

.activity__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  .app-l-page-sub-heading {
    width: auto;
  }
  .app-l-button-components {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;

    @media (max-width: 640px) {
      margin-bottom: 20px;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      width: 100%;
    }
    .dr-action {
      margin: 0 !important;
    }
  }
  @media (max-width: 991px) {
    flex-direction: row !important;
    .app-l-button-components {
      .app-c-select-memorial {
        min-width: 230px;
        .dropdown {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column !important;
    .app-l-button-components {
      .app-c-select-memorial {
        min-width: 230px;
        margin-left: 0 !important;
        .dropdown {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .app-l-button-components {
      .app-c-select-memorial {
        width: 100%;
        margin-left: 0 !important;
        .dropdown {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      button {
        width: 100%;
      }
    }
  }
}

button {
  outline: none !important;
}

.app-l-memorial-signup {
  @media (max-width: 480px) {
    .app-l-memorial-signup .modal-close-btn {
      right: 10px;
      top: 10px;
    }
  }
  .modal-dialog {
    max-width: 704px !important;
    @media (max-width: 600px) {
      max-width: 96% !important;
    }
  }
  .modal-content {
    padding-bottom: 40px;
    .modal-close-btn {
      right: 10px;
      top: 10px;
      height: 24px;
    }
    @media (max-width: 600px) {
      padding-bottom: 20px !important;
    }
  }
  .app-c-input-box.lg-height {
    min-height: 50px;
  }
  .app-c-number-select .app-l-phone-input {
    min-height: 50px;
  }
  .app-c-mw-form {
    padding: 50px 20px 0px;
    .modal-title {
      font-size: 28px;
      line-height: normal;
    }
    p {
      line-height: normal;
      padding: 10px 0;
    }
  }
  .app-c-mw-social {
    @extend %flex-and-row;
    padding-bottom: 0 !important;
    .btn-social {
      @include border-radius(8px);
      width: 100%;
      border: 0;
      padding: 14px;
      @extend %flex-all-center;
      .pre-img {
        @extend %flexbox;
        margin-right: 8px;
        @media (max-width: 992px) {
          margin-right: 15px;
        }
        img {
          width: 16px;
        }
      }
      .title-info {
        font-size: $text-md;
        color: $text-color;
        font-family: $font-primary;
        font-weight: $font-weight-bold;
        white-space: nowrap;
      }
      &.fb {
        background: #4765a8;
        .title-info {
          color: $white;
        }
        &:hover {
          background: #3a548b;
          @include boxShadow(0px 8px 16px rgba(26, 39, 126, 0.16));
        }
      }
      &.google {
        background: $neutrals-color3-light;
        .title-info {
          color: $text-color;
        }
        &:hover {
          @include boxShadow(0px 8px 16px rgba(26, 39, 126, 0.16));
        }
      }
      & + .btn-social {
        margin-left: 8px;
        @include screen-md() {
          margin-left: 0;
          margin-top: 16px;
        }
      }
      @include screen-md() {
        @include justify-content(center);
      }
    }
    @include screen-md() {
      @include flex-direction(column);
      padding-bottom: 22px;
    }
  }
  .app-c-mw-or-links {
    text-align: center;
    @extend %flex-align-center-row;
    color: $text-color;
    font-size: $text-md;
    font-family: $font-secondary;
    margin-top: 18px;
    margin-bottom: 22px;
    opacity: 0.5;
    .dot {
      border-bottom: 1px dashed $black;
      display: block;
      width: 100%;
      opacity: 0.1;
      &:first-child {
        margin: 0 7px 0 0;
      }
      &:last-child {
        margin: 0 0 0 7px;
      }
    }
  }
  .app-c-mw-ex-links {
    text-align: center;
    font-family: $font-secondary;
    color: #535e75;
    padding-top: 15px;
    a {
      @include transition(0.2s ease-in);
      text-decoration: underline;
      font-weight: $font-weight-semibold;
      color: #535e75;
      &:focus,
      &:hover {
        color: $black;
      }
    }
    & + .app-c-mw-ex-links {
      margin: 8px;
    }
  }
  .modal-close-btn {
    right: 40px;
    top: 20px;
    position: absolute;
    z-index: 9;
    background: $app-brand-primary;
    width: 22px;
    height: 22px;
    font-size: 14px;
    opacity: 1;
    color: $white;
    border: none !important;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      &:before {
        font-size: 16px;
      }
    }
    &:hover {
      opacity: 0.5;
    }
    @media (max-width: 480px) {
      right: 30px;
      top: 10px;
    }
  }
  .createMemorialBodyScroll {
    max-height: calc(100vh - 100px);
    @media (max-width: 960px) {
      max-height: calc(100vh - 250px);
    }
    @media (max-width: 480px) {
      height: calc(100vh - 208px);
      max-height: max-content;
    }
    .simplebar-track {
      display: block;
    }
    .simplebar-content-wrapper {
      overflow: auto !important;
    }
    .simplebar-track.simplebar-vertical {
      pointer-events: auto;
      width: 8px;
      right: -15px;
    }
  }
  .modal-dialog {
    max-width: 868px;
  }
  .app-c-mw--header__form {
    min-height: max-content !important;
  }
  @media (max-width: 991px) {
    .app-c-memlo-wrapper .app-c-memlo-wrapper__left .app-c-mw--header__form .app-c-mw-form {
      padding: 60px 20px !important;
    }
  }
  @media (max-width: 480px) {
    .modal-dialog .modal-content {
      padding: 0px 0px 39px 0px;
    }
  }
}

.select-unlocked-item {
  // border: 1px solid $light-grayish-blue;
  text-align: center;
  // padding: 10px;
  border-radius: 16px;
  justify-content: space-between;
  display: FLEX;
  align-items: center;
  @media (max-width: 768px) {
    width: 240px;
  }
  button {
    margin: 5px 0 0 0;
    width: 100%;
  }
  .custom-control {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
}

.app-l-privacy-settings {
  .custom-control-input {
    z-index: 1;
    cursor: pointer;
  }
  @media (max-width: 767px) {
    margin-top: 25px;
  }
}

.contact-wrapper-mob {
  @media (max-width: 1270px) {
    margin-top: 16px;
  }
}

.send-flower-choose-library {
  height: auto;
  float: left;
  width: 100%;
  padding: 15px;
  button {
    padding: 8px 0 0 0 !important;
  }
  textarea {
    margin-bottom: 15px;
  }
  .app-c-validation-message {
    padding-left: 0;
    float: left;
  }
}

.app-l-photo-video-button-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 308px;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    padding-left: 25px;
  }
  button {
    font-weight: 600;
    margin-top: 0 !important;
    @include screen-xs {
      font-size: 11px;
    }
  }
  .float-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    button {
      font-weight: 600;
      margin-right: 0 !important;
      text-decoration: underline !important;
      padding-right: 0 !important;
      color: $radio-toggle;
    }
    .app-c-cancel-btn {
      color: $red;
    }
  }
}

.content-box-service-list {
  .nav {
    padding-left: 0;
    margin-top: -5px;
    .nav-link {
      border: 1px solid $app-brand-primary;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      -webkit-text-fill-color: rgba(237, 130, 148, 1) !important;
      color: rgba(237, 130, 148, 1) !important;
      margin: 0 10px 5px;
    }
  }
}

.add-services-block-no-data {
  background: $white;
  height: auto;
  padding: 20px;
  box-shadow: 0px 4px 8px 0px rgba(93, 39, 48, 0.1);
  font-size: 16px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.view-page-lang-dropdown {
  .app-c-btn {
    background-color: hsla(0, 0%, 100%, 0.8) !important;
    border: 1px solid rgba(9, 30, 62, 0.1) !important;
  }
}

.service-details {
  padding-top: 24px;
  .memorial-profile__avatar.partner {
    margin: 0 0 0 auto;
  }
  .app-l-mob-view {
    display: none;
  }
  .app-l-web-view {
    .content-boxes__tag {
      margin-top: 30px;
      padding-top: 12px;
    }
  }
  @media (max-width: 767px) {
    padding-top: 0;
    margin-top: -5px;
    .content-boxes__left {
      align-items: flex-start;
      h5 {
        margin-bottom: 15px;
      }
    }
    .nav {
      margin-top: 0;
    }
    .app-l-web-view {
      display: none;
    }
    .app-l-mob-view {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      h5 {
        width: 100%;
        margin-bottom: 10px;
      }
      .add-service {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
    .left-card {
      margin-top: 0;
      img {
        float: left;
        margin-top: 0;
      }
    }
    .content-boxes__tag {
      padding: 0 0 0 10px;
      margin-top: 0;
      .app-c-rating {
        &.view {
          margin-left: 1px;
        }
      }
    }
  }
}

.set-min-width1 {
  min-width: 245px;
  // margin-right: 15px;
}

.app-l-activity-on-memorial {
  .app-l-service-select {
    margin-right: 10px;
    @media (max-width: 500px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}

.read-or-hide {
  &.category1 {
    color: $neutrals-color3;
    cursor: pointer;
  }
  &.category2 {
    color: $neutrals-color1;
    cursor: pointer;
  }
  &.category3 {
    color: $neutrals-color2;
    cursor: pointer;
  }
}

@include screen-md {
  .tippy-popper {
    display: none;
  }
  .app-l-dashboard__account-preview--group {
    .info--tooltip {
      float: none;
      display: flex;
      .app-l-dashboard__account-preview--email {
        justify-content: center;
      }
    }
  }
}

@media (pointer: coarse) {
  .tippy-popper {
    display: none;
  }
}
.learn-more--wrapper {
  @media (max-width: 767px) {
    background: #fff8ef;
  }
  .learn-more--container {
    margin-top: 0 !important;
    position: relative !important;
    z-index: 2;
    padding-bottom: 50px;
  }
  .learn-more-bg {
    padding: 25px 0 !important;
    z-index: 0;
  }
}
.learn-more-bg {
  height: 100%;
  background-size: auto;
}

// @media (max-width:1024px){
//   .sidebar-edit-btn{
//     max-width: 400px;
//     margin-left: auto;
//     display: block;
//   }
// }
.post-intro-tooltip {
  // max-height: 200px;
  // overflow: auto;
  p {
    color: white;
  }
}

.tippy-popper {
  pointer-events: auto !important;
  z-index: 1000000 !important;
}
.app-c-votes__hits {
  pointer-events: none;
  cursor: auto !important;
}
.modal {
  z-index: 999999;
}

.app-l-tank-you-select {
  display: flex;
  padding: 10px 0 20px;
  flex-wrap: wrap;
  .custom-checkbox{
    width: auto;
  }
  .custom-checkbox + .custom-checkbox {
    margin-left: 15px;
  }
  label {
    cursor: pointer;
    span {
      margin: 2px 0 0 0;
      float: left;
    }
  }
}
