$app-brand-primary        : #ed8294;
$app-brand-primary-dark   : saturate(darken($app-brand-primary, 3.73%), 0.31);
$app-brand-primary-light  : lighten(($app-brand-primary), 22.75%);
$app-brand-primary-disable: lighten(desaturate($app-brand-primary, 54.33), 68.24%);
$app-brand-secondary      : rgba(9, 30, 62, 1);

// Text color
$text-color            : #091e3e;
$text-color-light      : rgba(9, 30, 62, 0.7);
$text-color-extra-light: rgba(9, 30, 62, 0.5);

$default-link-color: rgba(9, 30, 62, 0.7);
$text-dark-extra   : $text-color;
$text-dark-medium  : lighten(desaturate($app-brand-primary, 77.81), 17.25);
$text-dark-normal  : lighten(desaturate($app-brand-primary, 78.65), 36.47);

$text-disabled   : #c1c1c1;
$text-placeholder: rgba(9, 30, 62, 0.4);
$text-label      : rgba(9, 30, 62, 0.6);
$text-input      : rgba(9, 30, 62, 0.5);

$radio-toggle: #3e9d8c;

$text-muted     : rgba(255, 255, 255, 0.6);
$text-muted-dark: rgba(9, 30, 62, 0.6);

$card-bg1: #fcfbfd;
$card-bg2: #eeecf5;
$card-bg3: #ecf5f4;
$card-bg4: #fff8ef;
$card-bg5: #e4f7fd;

$white: #fff;
$black: #000;

$generic-border-color    : rgba(9, 30, 62, 0.1);
$primary-btn-active-focus: lighten($app-brand-primary, 12%);

$disabled:#e9ecef;

$input-border-color:#D7D5DF;
//Neutrals color palette

$neutrals-color1      : #ffb85c;
$neutrals-color1-light: #fff8ef;
$neutrals-color2      : #3e9d8c;
$neutrals-color2-light: #ecf5f4;
$neutrals-color3      : #ed8294;
$neutrals-color3-light: #fdf3f4;
$neutrals-color3-dark : #da7788;
$neutrals-color4      : #503e9d;
$neutrals-color5      : #091e3e;
$neutrals-color6      : #56ccf2;
$neutrals-color6-light: #e4f7fd;
$neutrals-color7      : #ccc;
// App Colors
$footer-bg            : rgba(9, 30, 62, 1);
$footer-border-color  : rgba(255, 255, 255, 0.1);
$text-color-op-6      : #525e75;
$light-grayish-blue   :#E6E8EC;
//scrollbar
$bg-scroll            : #eedfe2;
$lightPink            : #fdf3f4;
$lightGray            :#ced2d8;

// Background colors
.lightPurple {
  background: #eeecf5 !important;
}

.lightYellow {
  background: #fff8ef !important;
}

.lightGreen {
  background: #ecf5f4 !important;
}

.lightPink {
  background: #fdf3f4 !important;
}
$red :#f71900;
$carbonGrey :#5d5d5d;
$dawnPink :#ececec;
$gray-dark:#000000b8;