.German {
  .app-c-footer {
    &.overlaps {
      @include screen-xs {
        padding-top: 220px;
        .btn-link {
          word-break: break-all;
        }
      }
    }
  }
}

.app-c-footer {
  position: relative;
  //background-color: $footer-bg;
  background: url(/assets/images/footer-overlay.png) repeat $footer-bg;
  margin-top: auto;

  &.overlaps {
    padding-top: 229px;
    @include screen-lg {
      padding-top: 140px;
    }
    @include screen-sm {
      padding-top: 180px;
    }
    @include screen-xs {
      padding-top: 160px;
    }
  }

  a {
    background: none !important;
    border: none;
    color: $text-muted;
    font-size: $text-lg;
    display: inline-block;
    padding: 6px 0px;
    @include transition(all ease 0.2s);
    font-family: $font-secondary;
    font-weight: $font-weight-medium;
    line-height: 16.42px;
    @include transition(all ease 0.2s);
    text-decoration: none;
    &:hover {
      color: $white;
    }

    &:focus {
      outline: none;
    }
  }
}
.app-c-footer__top {
  padding: 70px 0px;
  @include screen-md() {
    padding: 50px 0px 15px 0px;
  }
  @include screen-sm() {
    padding: 30px 0px 25px 0px;
  }
}

.app-c-footer__flex-wrapper {
  .accordion {
    @extend %flex-justify-start-row;
    @include screen-md() {
      flex-wrap: wrap;
    }

    @include screen-xs() {
      flex-direction: column;
    }
  }
  .app-c-footer__flex-box {
    flex: 1;
    @include screen-md() {
      flex-basis: 50%;
      margin-bottom: 27px;
    }
    @include screen-sm() {
      margin-bottom: 0px;
    }
  }

  .btn-link {
    background: none !important;
    padding: 0px 0px;
    display: block;
    color: $white;
    font-size: $text-xl;
    font-weight: $font-weight-bold;
    line-height: 24px;
    margin-bottom: 16px;
    text-decoration: none;
    position: relative;
    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }
    @include screen-sm() {
      font-size: $text-xl;
      margin-bottom: 10px;
      padding-right: 24px;
      font-weight: $font-weight-bold;
      font-family: $font-primary;
      display: inline-block;
      &::after {
        content: '\e903';
        position: absolute;
        right: 0;
        top: 1px;
        font-family: 'memlo';
        font-size: 9px;
        font-family: $font-weight-light;
      }
    }
  }
  .collapse {
    ul {
      @include screen-sm() {
        margin-bottom: 15px;
      }
      li {
        a {
          background: none !important;
          display: block;
        }
      }
    }
  }
}

@media only screen and (min-width: 767px) {
  .app-c-footer__flex-wrapper {
    .btn-link {
      pointer-events: none;
    }
    .collapse {
      display: block !important;
    }
  }
}

.app-c-social-links {
  display: block;
  margin-top: 96px;
  span {
    color: $white;
    font-size: 18px;
    margin-right: 21.42px;
    @include transition(all ease 0.2s);
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
      span {
        color: $app-brand-primary;
      }
    }
  }
  @include screen-sm() {
    display: none;
  }
  &.sm {
    display: none;
    @include screen-sm() {
      display: block;
      margin-top: 10px;
    }
  }
}

.app-c-footer__bottom {
  border-top: 1px solid $footer-border-color;
  padding: 42px 0px;
  @extend %flex-align-center-justify-space-between-row;
  @include screen-sm {
    padding: 21px 0px;
  }
}
.footer-copyright {
  @extend %flex-align-center-justify-start-row;
  @include screen-sm {
    justify-content: space-between;
    flex: 1;
  }
}
.footer-copyright-label {
  line-height: 28px;
  border: none;
  color: $text-muted;
  font-size: 14px;
  position: relative;
  margin: 0px 18px;
}
.made-with {
  color: $white;
  margin: 0;
  display: block;
  line-height: 24px;
  @include screen-md() {
    display: none;
  }
  span {
    color: $app-brand-primary;
    display: inline-block;
    margin: 0px 4px;
  }
}
.footer-logo {
  all: unset;
  @extend %flex-align-center-justify-start-row;
  .logo-shape {
    height: 24.07px;
  }
  .logo-text {
    height: 19.13px;
    margin: 0px 7.84px;
  }
}
.footer-links {
  @extend %flex-align-center-justify-start-row;
  @include screen-sm() {
    display: none;
  }
  a {
    margin-left: 30px;
  }
}
