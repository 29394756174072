$text-placeholder: #6b788b;

.app-c-memlo-wrapper {
  @extend %flex-and-row;
  height: 100%;

  .app-c-memlo-wrapper__left {
    @extend %flex-row;
    width: 55%;

    @media (max-width: 1862px) {
      width: 50%;
    }

    @include screen-md() {
      margin: 0 auto;
      width: 100%;
    }

    .app-c-mw--header {
      @extend %flex-align-center-col;
      width: 100%;
      max-width: 650px;
      padding: 32px 32px 0;
      margin-left: auto;

      &__top {
        @extend %flex-align-center-justify-space-between;
        width: 100%;
        padding: 0;

        .app-h-brand {
          //margin: 0 0 0 100px;
          line-height: normal;

          .logo-shape {
            height: 24px;
          }
        }

        .app-c-language-switch {
          margin: 0;
        }


        @include screen-md() {
          padding: 32px 20px 0 24px;

          .app-h-brand {
            .logo-text {
              display: flex;
            }
          }
        }

        @include screen-sm() {
          .app-h-brand {
            margin: 0 0 0 10px;
          }
        }
      }

      @include screen-md() {
        width: 100%;
        margin: 0 auto;
        padding: 0;
      }
    }

    .app-c-password-show{
      position: relative;
    }
    .app-c-password-show  .form-control{
      width:380px;
      @media (max-width:1000px) {
        width: 85%;
      }
    }
    .register-input .form-control {
      width:100%;
    }
    
    .icon-label-eye{
      position: absolute;
      right: 26px;
      top:20px;
      font-size: 20px;
     cursor: pointer;
    }

    .app-c-mw--header__form {
      width: 100%;
      max-width: 580px;
      display: block;
      margin-top: 51px;
      min-height: 730px;
      position: relative;
      margin-bottom: 35px;

      // margin-bottom: 95px;
      &.forgotpassword {
        @extend %flex-all-center;

        .app-c-mw-form {
          @extend %flex-justify-center-col;
        }
      }

      @include screen-md() {
        margin-bottom: -23px;
        min-height: auto;
        margin-top: 38px;
      }

      .inset-graphics-bg {
        background: $neutrals-color3-light;
        @include border-radius(40px);
        @include transform(matrix(1, -0.06, 0.04, 1, 0, 0));
        height: 100%;
        position: absolute;
        width: inherit;
        z-index: 1;

        &--underlay {
          @include border-radius(40px);
          height: 100%;
          position: absolute;
          width: inherit;
          z-index: 0;
          overflow: hidden;

          .underlay-row {
            height: 50%;
            @extend %flexbox;
            @include flex-direction(row);
            width: 100%;

            &--purp {
              background: $neutrals-color4;
              height: 100%;
              width: 100%;
            }

            &--yellow {
              background: $neutrals-color1;
              height: 100%;
              width: 100%;
            }
          }
        }

        &--mobile-underlay {
          @include border-radius(40px);
          position: absolute;
          width: 120%;
          top: 0px;
          left: 9px;
          height: 100%;
          z-index: 1;
          overflow: hidden;
          @include transform(translate(-56px, 4px) rotate(353deg));

          .underlay-row {
            height: 50%;
            @extend %flexbox;
            @include flex-direction(row);
            width: 100%;

            &--yellow {
              background: $neutrals-color1;
              height: 100%;
              width: 100%;
            }
          }

          @media (max-width: 400px) {
            width: 126%;
            // top: 0px;
            // left: 25px;
          }

          @media (max-width: 330px) {
            width: 120%;
            left: 24px;
          }
        }

        @include screen-md() {
          @include transform(matrix(1, -0.04, 0.04, 1, 0, 0));
        }
      }

      .app-c-mw-form {
        z-index: 2;
        position: relative;
        height: 100%;

        &.login,
        &.create {
          padding: 91px 85px;

          @media (max-width: 1200px) {
            padding: 40px 40px;
          }

          @include screen-md() {
            padding: 40px 20px;
          }
        }

        // padding: 91px 85px;
        padding: 40px 85px 56px;

        &.form-forgotpass {
          @extend %flex-justify-center-col;
        }
        @include screen-xl() {
          padding: 91px 55px !important;
        }
        @include screen-lg() {
          padding: 91px 35px !important;
        }

        .form-overflow {
          max-height: 409px;
          margin: -10px -15px -15px;
          padding: 10px 15px 15px;
          .simplebar-track.simplebar-vertical {
            width: 10px;
            pointer-events: auto;
            right: -4px;
          }
          .app-c-phone-number{
            z-index: 9;
            .app-l-phone-input {
              @include border-radius(16px);
              min-height: 64px;
              margin-top: 16px;
              background: $white;
              font-size: $text-lg;
              display: flex;
              align-items: center;
              filter: drop-shadow(0px 4px 8px rgba(93, 39, 48, 0.1));
              -webkit-filter: drop-shadow(0px 4px 8px rgba(93, 39, 48, 0.1));
              padding-left: 24px;
              padding-right: 30px;
              z-index: 999;
  
              .form-control,
              .flag-dropdown {
                border: none;
                padding: 0;
                background: transparent;
                outline: none;
                box-shadow: none;
                height: auto;
                .selected-flag {
                  padding-left: 0 !important;
                }
              }
              .form-control {
                padding-left: 36px;
              }
              .PhoneInputInput{
                border: none;
              }
              .country-list {
                top: 35px;
                max-height: 158px;
              }
            }
          }
         
        }

        @include screen-md() {
          padding: 40px 20px !important;
        }

        .app-c-mw-form--title {
          &__heading {
            color: $text-color;
            letter-spacing: -0.03em;
            font-family: $font-primary;
            font-weight: $font-weight-bold;
            font-size: 32px;
            text-align: center;
            margin-bottom: 24px;

            .lovely-font {
              font-family: $handwritten-font;
              color: $app-brand-primary;
              font-size: 85px;
              font-weight: $font-weight-medium;
              line-height: 40px;
              font-style: italic;

              @include screen-md() {
                line-height: 99px;
              }
            }

            @include screen-md() {
              font-size: 24px;
              margin-bottom: 8px;
            }
          }

          &__subheading {
            color: $text-color;
            letter-spacing: -0.03em;
            font-family: $font-secondary;
            font-size: $text-xl;
            margin-bottom: 36px;
            opacity: 0.7;
            text-align: center;

            @media (max-width: 992px) {
              margin-bottom: 12px;
              font-size: $text-lg;
            }
          }
        }

        .app-c-mw-form--submit {
          .login-btn {
            letter-spacing: 0.03em;
            width: 100%;
            margin-top: 15px;
            min-height: 56px;
            margin-bottom: 24px;

            @media (max-width: 992px) {
              min-height: 50px;
            }
          }
        }

        .app-c-mw-ex-links {
          text-align: center;
          font-family: $font-secondary;
          color: #535e75;

          a {
            @include transition(0.2s ease-in);
            text-decoration: underline;
            font-weight: $font-weight-semibold;
            color: #535e75;

            &:focus,
            &:hover {
              color: $black;
            }
          }

          & + .app-c-mw-ex-links {
            margin: 8px;
          }
        }

        .app-c-mw-or-links {
          text-align: center;
          @extend %flex-align-center-row;
          color: $text-color;
          font-size: $text-md;
          font-family: $font-secondary;
          margin-top: 18px;
          margin-bottom: 22px;
          opacity: 0.5;

          .dot {
            border-bottom: 1px dashed $black;
            display: block;
            width: 100%;
            opacity: 0.1;

            &:first-child {
              margin: 0 7px 0 0;
            }

            &:last-child {
              margin: 0 0 0 7px;
            }
          }
        }

        .app-c-mw-social {
          @extend %flex-and-row;

          .btn-social {
            @include border-radius(8px);
            width: 100%;
            border: 0;
            padding: 14px;
            @extend %flex-all-center;

            .pre-img {
              @extend %flexbox;
              margin-right: 8px;

              @media (max-width: 992px) {
                margin-right: 15px;
              }

              img {
                width: 16px;
              }
            }

            .title-info {
              font-size: $text-md;
              color: $text-color;
              font-family: $font-primary;
              font-weight: $font-weight-bold;
              white-space: nowrap;
            }

            &.fb {
              background: #4765a8;

              .title-info {
                color: $white;
              }

              &:hover {
                background: #3a548b;
                @include boxShadow(0px 8px 16px rgba(26, 39, 126, 0.16));
              }
            }

            &.google {
              background: $white;

              .title-info {
                color: $text-color;
              }

              &:hover {
                @include boxShadow(0px 8px 16px rgba(26, 39, 126, 0.16));
              }
            }

            & + .btn-social {
              margin-left: 8px;

              @include screen-md() {
                margin-left: 0;
                margin-top: 16px;
              }
            }

            @include screen-md() {
              @include justify-content(center);
            }
          }

          @include screen-md() {
            @include flex-direction(column);
            padding-bottom: 22px;
          }
        }
      }
    }
  }

  .app-l-memlo-wrapper__set-password {
    .app-c-mw--header__form {
      @include flexbox;
      @include align-items(center);
      width: 100%;

      .app-c-mw-form {
        width: 100%;
      }
    }
  }

  .app-c-memlo-wrapper__right {
    @extend %flex-and-row;
    background: linear-gradient(to bottom, #fff8ee, #fff8ee);
    width: 45%;

    &.memorial {
      background: $card-bg2;
      position: relative;
      width: 850px;

      img {
        position: absolute;
        top: 0;
      }
    }

    @media (max-width: 1862px) {
      width: 50%;
      @include align-items(flex-end);
    }

    @include screen-md() {
      display: none;
    }

    .landingGrap {
      display: block;
      margin-left: auto;
      width: 100%;
      height: 972px;
      object-fit: cover;
      pointer-events: none;

      @media (max-width: 1862px) {
        height: auto;
        object-fit: contain;
      }
    }
  }
}

.app-c-memlo--footer {
  .app-c-footer__bottom {
    padding: 39.5px 0px;

    .footer-copyright {
      @include align-items(end);
      margin-left: 85px;
    }

    .footer-links {
      @include justify-content(center);

      a {
        margin: 0;

        &:focus {
          color: $white;
        }

        & + a {
          margin-left: 30px;
        }
      }
    }

    @include screen-md() {
      padding: 45px 0px 0;
      @include flex-direction(column);

      .footer-copyright {
        @include order(3);
        position: relative;
        padding: 21px 0 28px;
        margin-left: 0;

        &:before {
          border: 1px solid $white;
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          opacity: 0.1;
        }
      }

      .made-with {
        @include order(2);
        @include align-items(center);
        display: flex;
        margin-bottom: 12px;
      }

      .footer-links {
        display: flex;
        margin-bottom: 15px;
      }
    }
  }

  @include screen-sm() {
    .footer-links {
      width: 100%;
    }
  }

  @media (max-width: 350px) {
    .footer-copyright-label {
      margin: 0;
    }

    .footer-links {
      a {
        margin-left: 20px;
      }
    }
  }
}

@media (min-width: 992px) {
  .mr-only {
    display: none;
  }
}

@include screen-md() {
  .ml-only {
    display: none;
  }
}

.melmo-hidden {
  overflow: hidden;
  &.no-scroll {
    overflow: hidden;
  }
  &.-height-firefox{
    display: flex;
    flex-direction: column;
  }
  @include screen-sm() {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .app-c-memlo-wrapper {
      min-height: calc(100vh - 200px);
    }

    .app-c-memlo-wrapper__left {
      width: 100%;

      .app-c-mw--header {
        margin: 0 auto;
      }
    }

    .app-c-memlo-wrapper__right {
      display: none;
    }
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    .app-c-memlo-wrapper__left {
      width: 100%;

      .app-c-mw--header {
        margin: 0 auto;
      }
    }

    .app-c-memlo-wrapper__right {
      display: none;
    }
  }
}

.app-c-mw-form--inputs {
  &__form-control {
    position: relative;

    &.datepicker {
      .floating-label {
        position: absolute;
        z-index: 1;
        font-size: $text-md;
        left: 24px;
        opacity: 0.6;
        color: $text-color;
        letter-spacing: -0.03em;
        font-family: $font-secondary;
        top: 10px;
        white-space: nowrap;

        @include screen-md() {
          left: 16px;
          top: 8px;
        }
      }

      .form-control {
        @include screen-md() {
          padding-left: 16px !important;
        }

        &::placeholder {
          font-weight: $font-weight-normal;
          color: $text-placeholder;
        }
      }

      .dp-control {
        color: $text-color;
        font-weight: $font-weight-semibold;
        position: absolute;
        top: 32px;
        font-size: $text-lg;
        font-family: $font-secondary;
        //worksans has issue with DD/MM/YY text

        @include screen-md() {
          top: 25px;
          font-size: $text-md;
        }
      }
    }
  }
}

.profile-admin {
  margin-top: 30px;
  @extend %flex-align-center-row;

  &--title {
    color: $text-color-op-6;
    letter-spacing: -0.03em;
    font-size: $text-lg;
  }

  &--toggle {
    margin-left: auto;
  }
}

.profile-admin-form {
  h5 {
    margin: 12px 0;
    letter-spacing: -0.03em;
    font-size: $text-xl;
  }
}

.rs-picker-date.rs-picker-toggle-wrapper {
  a {
    &.rs-btn {
      background: transparent;
      font-size: $text-lg;
      padding: 0;
      font-weight: $font-weight-semibold;
      color: $text-color;

      &.rs-picker-toggle .rs-picker-toggle-placeholder {
        text-transform: capitalize;
      }

      .rs-picker-toggle-value {
        color: $text-color;
      }

      .rs-picker-toggle-clean {
        right: 17px;
        top: 24px;
      }

      &:hover {
        background-color: transparent;
      }

      .rs-picker-toggle-caret {
        &:before {
          display: none;
        }
      }
    }
  }

  &.rs-picker-subtle .rs-btn:focus,
  &.rs-picker-subtle .rs-picker-toggle:focus {
    background-color: transparent;
    color: $text-color;
  }

  &.rs-picker-subtle .rs-btn.active,
  &.rs-picker-subtle .rs-picker-toggle.active {
    background-color: transparent;
    color: $text-color;
  }
}

.app-l-create-memorial {
  .app-c-memlo-wrapper__left {
    width: 100%;
  }

  .memorial {
    width: 850px;
    max-width: 850px;
    flex: 0 0 850px;

    @media (max-width: 1366px) {
      width: 620px;
      max-width: 620px;
      flex: 0 0 620px;

      img {
        width: 128% !important;
      }
    }
    @media (max-width: 1152px) {
      width: 500px;
      max-width: 500px;
      flex: 0 0 500px;

      img {
        width: 128% !important;
      }
    }

    img {
      height: auto !important;
    }

    .memorial-profile__texting {
      transform: rotate(-4deg);
      top: 156px;
      left: 300px;
      position: absolute;
      z-index: 1;
      padding-left: 0;
      padding-top: 0;

      h2 {
        line-height: 12px;

        .memorial-profile-name {
          font-size: 11px;
          font-weight: $font-weight-semibold;
        }

        .memorial-preview-profile-name {
          max-width: 160px;
          color: #fff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 11px;
          font-weight: $font-weight-semibold;
        }
      }

      .memorial__date {
        font-size: 8px;
        line-height: 16px;
        margin-bottom: 0;
      }

      .memorial__location-wrapper {
        margin-top: 0px;

        .memorial__location {
          font-size: 8px;
          line-height: 8px;

          .icon-location {
            font-size: 8px;
          }

          .-address {
            font-size: 8px;
          }
        }
      }

      @media (max-width: 1366px) {
        top: 148px;

        left: 276px;
        .memorial__date {
          line-height: 12px;
        }
      }
      @media (max-width: 1152px) {
        top: 115px;
        left: 216px;
        h2 {
          line-height: 11px;
          .memorial-profile-name {
            font-size: 10px;
            font-weight: $font-weight-semibold;
          }
        }
        .memorial__date {
          line-height: 10px;
          font-size: 7px;
        }
        .memorial__location-wrapper {
          margin-top: 0px;

          .memorial__location {
            font-size: 7px;
            line-height: 7px;

            .icon-location {
              font-size: 7px;
            }

            .-address {
              font-size: 7px;
            }
          }
        }
      }
    }
  }
}
