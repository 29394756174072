$text-dark-10: #1e1919;
$gray-shade-100: #f4f5f9;

.modal {
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;

  @include cm-transition();

  &.show {
    background: rgba(#ffffff, 0.5);
    @extend %flex-all-center;
    @include transform(scale(1));
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;

    .modal-dialog {
      opacity: 1;
      filter: alpha(opacity=100);
      visibility: visible;

      @include transform(scale(1));
    }
  }

  .modal-dialog {
    max-width: 568px;
    width: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;

    @include transform(scale(0));
    @include transition(transform 0.1s ease, width 0.1s linear, opacity 0.1s linear);

    @include screen-sm() {
      max-width: 568px;
    }

    //   @media screen and (min-width: 576px) {
    //     max-width: 80%;
    //     min-width: 296px;
    //   }
  }
}

.modal-dialog {
  height: 100%;
  margin: 0 auto;
  padding: 15px;

  @extend %flex-all-center;

  .modal-content {
    background: $white;
    border: none;
    max-height: 100%;
    padding: 0px 32px 24px 32px;


    @include border-radius(16px);
    @include boxShadow(0px 0px 10px rgba(35, 59, 88, 0.3));
    @extend %flexbox;
    @include flex-direction(column);

    @include screen-sm() {
      padding: 0px 20px 25px 20px;
    }

    .modal-header {
      border-bottom: none;
      padding: 32px 0px 32px 0px;
      border-bottom: 1px dashed $generic-border-color;

      @extend %flex-align-center;
      @include flex-shrink(0);

      @include screen-sm() {
        padding: 20px 0px 18px 0px;
      }

      .modal-title {
        line-height: 24px;
        font-size: 16px;
        font-weight: $font-weight-bold;
        color: $text-color;
        font-family: $font-primary;
      }

      .app-c-modal--close {
        background: transparent;
        width: 20px;
        height: 20px;
        color: $text-dark-normal;
        font-size: 9px;
        outline: none;
        border: none;
        padding: 0;
        position: relative;
        top: -10px;
        right: -6px;
        bottom: auto;
        left: auto;

        @include border-radius(3px);
        @extend %flex-all-center;
        @include transition(background ease 0.2s);

        &:hover {
          background: $neutrals-color3;
        }
      }
    }

    .modal-body {
      padding: 25px 5px 0 0px;
      margin-bottom: 20px;
      height: 100%;
      // overflow  : auto;
      min-height: 0;
      @extend %flex-align-center-justify-space-between-col;

      > div,
      form {
        width: 100%;
      }
      .modalBodyScroll {
        max-height: calc(100vh - 240px);
        .simplebar-track.simplebar-vertical {
          width: 9px;
          pointer-events: auto;
          right: -12px;
          .-library &{
            right: 0;
          }
        }
        .simplebar-content-wrapper {
          height: 100% !important;
          overflow: auto !important;
        }
        .app-c-mw--header__form {
          margin-bottom: 0 !important;
          margin-top: 0 !important;
        }
        .create-table-dropdown {
          filter: none !important;
          -webkit-filter: none !important;
          border: 1px solid $generic-border-color;
        }
      }

      .col-1-ml {
        width: 50%;
        margin-right: auto;

        @include screen-md() {
          width: 100%;
        }
      }

      @include screen-sm() {
        padding: 19px 5px 10px 0px;
      }

      &.mx {
        position: relative;
        margin-left: -12px;
        margin-right: -12px;
      }

      h5 {
        font-size: $text-lg;
      }

      // &::-webkit-scrollbar {
      //   width : 3px;
      //   height: 3px;
      // }

      // &::-webkit-scrollbar-thumb {
      //   background   : $neutrals-color3;
      //   border-radius: 10px;
      // }

      // &::-webkit-scrollbar-track {
      //   background   : $gray-shade-100;
      //   border-radius: 0px;
      // }
    }

    .modal-footer {
      margin: 0px 0px 0px 0px;
      border-top: none;
      padding-top: 0 !important;

      &.center {
        @extend %justify-center;
      }

      > * {
        margin: 0;
      }

      button {
        min-width: 120px;
        text-align: center;
        @extend %flex-all-center;
        min-height: 40px;

        & + button {
          margin-left: 12px;
        }
      }
    }
  }
}

.app-c-modal-close {
  // all: unset;
  font-size: 18px;
  color: $text-color;
  width: 30px;
  height: 30px;
  @extend %flex-all-center-col;
  @include border-radius(50%);
  @include cm-transition();
  i {
    &::before {
      font-weight: 900;
      font-size: 17px;
    }
  }
  &:hover {
    background: $neutrals-color3;
    color: $white;
  }

  &:focus,
  &:active {
    background: $neutrals-color3;
    color: $white;
  }

  &.btn-primary:not(:disabled):not(.disabled):active {
    background-color: $neutrals-color3;
    color: $white;
  }
}

.modal-order {
  .modal-dialog {
    .modal-content {
      .modal-body { 
        &.order-sum-no-h {
          height: 100%;
          margin-right: -10px;
          padding-right: 20px;
        }

        .modal-order-body {
          height: inherit;

          .order-row {
            @extend %flexbox;
            padding-left: 36px;
            @include screen-sm() {
              padding-left: 5px;
            }
            .memorial-profile__texting {
              padding-left: 31px;

              h2 {
                color: $text-color;
                font-size: 32px;
                word-break: break-word;
                white-space: normal;
                line-height: 40px;
                font-family: $font-primary;

                @include screen-sm() {
                  font-size: 16px;
                }
              }

              .memorial__date {
                color: $text-color;

                @include screen-sm() {
                  font-size: 14px;
                }
              }

              .memorial__location {
                color: $text-color;
                word-break: break-word;
                white-space: break-spaces;

                @include screen-sm() {
                  font-size: 14px;
                }
              }
            }

            & + .order-row {
              margin-top: 30px;
            }
          }

          .memorial-profile__avatar {
            @extend %flexbox;
          }

          .order-left-title {
            font-size: $text-lg;
            @extend %common-text-prop-normal;
            margin-top: 9px;
            margin-bottom: 31px;
            font-weight: $font-weight-bold;
          }

          .order-payment {
            padding: 0 60px;

            .app-c-border-box-wrapper {
              @extend %flex-justify-space-between;

              .app-c-border-box {
                padding: 0;
                max-width: 150px;

                .app-c-checkbox__item {
                  cursor: pointer;
                  padding: 20px 24px;
                }
              }

              &.card-select {
                margin-top: 40px;
                @extend %flex-column;

                .app-c-checkbox__item {
                  padding: 20px 7px;

                  .app-c-check-label {
                    margin: 0;
                  }

                  .app-c-radio-check__node {
                    border-radius: 4px;
                  }
                }

                .app-c-border-box {
                  max-width: 100%;
                  min-width: 100%;

                  .app-c-checkbox__item {
                    position: relative;

                    .app-c-radion-check {
                      position: absolute;
                      right: 24px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.-payment {
    .modal-dialog {
      .modal-content {
        .modal-body {
          height: 518px;
        }
      }
    }
  }

  &.-conf {
    .modal-dialog {
      .modal-content {
        overflow: hidden;
        padding: 0;
        .modal-header {
          padding: 32px;
        }
        .modal-body {
          height: auto;
          height: 508px;
          z-index: 1;
          padding: 0;
          margin-bottom: 0;
          .app-l-popup-content {
            padding: 32px;
          }
          .app-h-brand .logo-text {
            display: block !important;
          }
          .modalBodyScroll {
            height: 100%;
            max-height: 100%;
          }

          .modal-order-body {
            @extend %flex-and-col;
            margin-top: 60px;
          }

          h5.order-c-title {
            font-size: $lgs !important;
            font-weight: $font-weight-bold;
            text-align: center;
            color: $text-color;
            font-family: $font-primary;
          }

          .app-h-brand {
            margin: 25px auto 65px;
          }

          .memorial-home {
            text-align: center;
          }
        }

        .order-payment {
          position: absolute;
          bottom: -1px;
          right: 0;
          pointer-events: none;
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .modal-content {
    width: 568px;
  }

  &.summary {
    .modal-footer {
      &.no-border {
        border: 0;
      }
      padding-top: 30px!important;
      border-top: 1px dashed rgba(9, 30, 62, 0.1);
    }
  }
  &.order-summary-modal {
    .modal-footer { 
      padding-top: 30px!important; 
    }
  }
}

.-visitation {
  .modal-dialog {
    max-width: 650px;
  }

  .visitation-wrapper {
    transform: translateY(0);
    margin-bottom: 24px;
  }

  .nav-link {
    .tab-count {
      min-width: 18px;
      height: 18px;
      margin-left: 5px;
      background: $app-brand-primary;
      border-radius: 50%;
      display: inline-block;
      color: $white;
      font-size: $text-md;
      font-weight: $font-weight-normal;
      padding: 1px;
    }

    &.active {
      .tab-count {
        background: $white;
        color: $app-brand-primary;
      }
    }
  }

  .attendees {
    justify-content: flex-end;
    margin-top: 23px;
  }

  .content-boxes__full {
    @extend %flex-column;
  }

  .attendees-list {
    margin-top: 15px;

    .attendees-item {
      border: 1px solid #dcdfe4;
      border-radius: 16px;
      @extend %flex-align-center;
      padding: 10px;
      position: relative;

      @include screen-md() {
        flex-direction: column;
      }

      &--prof {
        border-radius: 8px;
        min-width: 40px;
        height: 40px;
        text-align: center;
        color: $white;
        @extend %flex-all-center;
        @extend %color-generator;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &--name {
        margin-left: 14px;
        font-weight: $font-weight-bold;
        font-size: $text-md-plus;
        line-height: 15px;
        @extend %common-text-prop-normal;
        word-break: break-all;
        width: 210px;
        margin-right: 20px;

        @include screen-md() {
          text-align: center;
          margin: 10px 0 0;
          width: 100%;
        }
      }

      &--email {
        margin-left: auto;
        word-break: break-word;
        width: 210px;

        @include screen-md() {
          margin: 10px auto 0;
          text-align: center;
          width: 100%;
        }
      }

      &--action {
        @include screen-md() {
          position: absolute;
          top: 0;
          right: 0;

          .delete-btn {
            margin-left: 0;
            font-size: $text-lg-plus;
          }
        }

        .delete-btn {
          margin-left: 20px;
        }
      }

      & + .attendees-item {
        margin-top: 12px;
      }
    }
  }
}

@include screen-md() {
  .modal-footer.mobile-center {
    @include justify-content(center);
  }
}

.modal-video {
  background: rgba(0, 0, 0, 0.712) !important;
  padding-right: 0;

  .app-l-video-holder {
    max-width: 500px;
  }

  .modal-dialog {
    width: 100%;
    max-width: 100%;

    .modal-content {
      background: none;
      box-shadow: none;

      .ril__navButtonPrev {
        position: fixed;
        left: 0;
      }

      .ril__navButtonNext {
        position: fixed;
        right: 0;
      }

      .app-c-modal-close {
        background: none;
        color: $white !important;
      }
    }
  }

  .app-c-modal-close {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .app-l-video-holder {
    max-width: 500px;
    margin: auto;
  }
}

.modal-list {
  .modal-dialog {
    max-width: 1000px;
  }
}

.app-c-modal {
  h5 {
    color: $text-color;
  }

  &.-library {
    .modal-dialog {
      .modal-content {
        padding: 0;

        .modal-header {
          padding: 26px 30px;
        }

        .modal-body {
          padding: 0;
          overflow: hidden;

          .choose-lib {
            overflow: auto;

            li {
              position: relative;
              border-bottom: 1px solid rgba(237, 130, 148, 0.12);
              label {
                cursor: pointer;
                width: 100%;
                &.no-template {
                  cursor: $cursor-disabled;
                  pointer-events: none;
                }
              }

              .lib-template {
                // padding: 13px 32px 16px 32px;
                padding: 13px 69px 13px 32px;
                font-size: $text-md-plus;
                line-height: 24px;
                border: 1px dashed transparent;
                color: $text-color;

                &:hover {
                  background: rgba(237, 130, 148, 0.12);
                }
                &.no-template {
                  text-align: center;
                  background: none;
                }
              }

              input {
                display: none;

                &:checked ~ .custom-lib-radio-button {
                  &:after {
                    content: '\e910';
                    font-family: 'memlo';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    @extend %flex-all-center;
                    background: $app-brand-primary;
                    border-radius: 8px;
                    color: $white;
                  }
                }

                &:checked ~ .lib-template {
                  background: rgba(237, 130, 148, 0.12);
                  border: 1px dashed $app-brand-primary;
                }
              }
            }
          }
        }
      }
    }
  }

  .visitation-space {
    @extend %flex-justify-space-between;
  }

  &.-requests {
    .form-input-flex {
      @extend %flex-align-center-col;
    }

    .request {
      font-family: $font-primary;
      line-height: 28px;

      &-heading {
        font-weight: $font-weight-bold;
        @extend %common-text-prop-normal;
        font-size: $lgs;
        text-align: center;
        margin-bottom: 20px;
      }

      &-detail {
        font-weight: $font-weight-normal;
        @extend %common-text-prop-normal;
        text-align: center;
        margin-bottom: 20px;
      }

      &-type {
        margin-bottom: 20px;
        @extend %flex-justify-space-around;
        width: 100%;
      }

      &-access {
        margin-top: 30px;
      }
    }
  }

  .visitation-selected {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    width: 100%;
    padding: 16px 23px;
    margin: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.16);
    @extend %flex-and-col;

    &--row {
      @extend %flexbox;
      width: 100%;
    }

    &--col {
      margin-top: 3px;

      &:last-child {
        width: 160px;
        margin-left: auto;
      }
    }

    &--label {
      letter-spacing: -0.03em;
      color: $text-color;
      font-size: $text-md;
      opacity: 0.6;
    }

    &--val {
      letter-spacing: -0.03em;
      color: $text-color;
      font-size: $text-lg;
      font-weight: $font-weight-semibold;
    }
  }
}

.app-l-choose-from-content-popup {
  .modal-footer {
    display: none !important;
  }

  .modal-header {
    padding: 32px !important;
    border: none !important;
  }

  .modal-content {
    padding: 0;
  }

  .modal-body {
    padding: 0 !important;
    border-bottom-left-radius: calc(16px);
    border-bottom-right-radius: calc(16px);
  }

  &--content {
    .list-group {
      @include border-radius(0);

      .list-group-item {
        border: 1px dashed $generic-border-color;
        border-left: 0 !important;
        border-right: 0 !important;
        font-size: 13px;
        line-height: 24px;
        letter-spacing: -0.03em;
        cursor: pointer;

        &:last-child {
          border-bottom: none !important;
        }

        &.active {
          background: rgba(237, 130, 148, 0.12);
          color: $neutrals-color5;
          @include flexbox;
          @include align-items(center);
          border: 1px dashed $app-brand-primary;

          &:after {
            content: '\e910';
            font-family: 'memlo' !important;
            width: 40px;
            height: 40px;
            flex: 0 0 40px;
            max-height: 40px;
            @include border-radius(8px);
            background: $app-brand-primary;
            color: $white;
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
            margin-left: 30px;
          }
        }
      }
    }
  }
}

.visitation-selected-list-view {
  padding: 15px !important;

  .visitation-selected--col {
    width: 100%;
    margin-top: 0;
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .rs-picker-menu {
    position: fixed !important;
  }
}

.add-user-modal {
  .modal-header {
    border: none !important;
    padding: 0 0 0 0 !important;
    position: relative;
    height: 60px;
    button {
      position: absolute;
      right: 0;
      top: 18px;
    }

    .modal-title {
      display: none;
    }
    + .modal-body {
      padding-top: 0 !important;
    }
  }

  .modal-title {
    font-size: 32px;
    line-height: 48px;
    font-weight: $font-weight-bold;
    font-family: $font-primary;

    color: $text-color;
    width: 100%;
    text-align: center;
  }

  p {
    font-size: 16px;
    line-height: 32px;
    padding: 24px 0 32px;
    color: $text-color;
    width: 100%;
    text-align: center;
  }

  .app-c-mw-form--submit {
    button {
      width: 100%;
      margin-top: 40px;
      min-height: 56px;
    }
  }
}

.-attendees {
  .modal-dialog {
    max-width: 650px;
  }

  .visitation-wrapper {
    transform: translateY(0);
  }

  .attendees-list {
    .attendees-item {
      border: 1px solid #dcdfe4;
      border-radius: 16px;
      @extend %flex-align-center;
      padding: 10px;
      position: relative;

      @include screen-md() {
        flex-direction: column;
      }

      &--prof {
        @include border-radius(8px);
        min-width: 40px;
        height: 40px;
        text-align: center;
        color: $white;
        @extend %flex-all-center;
        @extend %color-generator;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &--name {
        margin-left: 14px;
        font-weight: $font-weight-bold;
        font-size: $text-md-plus;
        line-height: 15px;
        @extend %common-text-prop-normal;
        word-break: break-all;
        width: 210px;
        margin-right: 20px;

        @include screen-md() {
          text-align: center;
          margin: 10px 0 0;
          width: 100%;
        }
      }

      &--dateTime {
        margin-left: auto;
        word-break: break-word;
        white-space: nowrap;
        color: $text-color;
        font-size: 12px;
        opacity: 0.7;
      }

      & + .attendees-item {
        margin-top: 12px;
      }
    }
  }

  .cs-scroll {
    max-height: 390px;
    overflow-x: hidden;

    .simplebar-track.simplebar-vertical {
      width: 4px;
      right: 0px;
      background: rgba(9, 30, 62, 0);
    }

    .simplebar-track {
      overflow: visible;
    }

    .simplebar-visible:before {
      opacity: 1 !important;
    }

    .simplebar-scrollbar:before {
      background: $app-brand-primary;
      left: -1px;
      right: -1px;
    }
  }
}

.-report-user {
  .modal-dialog {
    max-width: 650px;
  }

  .visitation-wrapper {
    transform: translateY(0);
  }

  .attendees-list {
    .attendees-item {
      border: 1px solid #dcdfe4;
      border-radius: 16px;
      @extend %flex-align-center;
      padding: 10px;
      position: relative;
      flex-direction: column;

      @include screen-md() {
        flex-direction: column;
      }

      &--prof {
        @include border-radius(8px);
        min-width: 36px;
        height: 36px;
        text-align: center;
        color: $white;
        @extend %flex-all-center;
        @extend %color-generator;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      &--name {
        font-weight: $font-weight-bold;
        font-size: $text-md-plus;
        line-height: 15px;
        @extend %common-text-prop-normal;
        word-break: break-all;
        width: 100%;

        @include screen-md() {
          text-align: left;
          margin: 10px 0 0;
          width: 100%;
        }
      }

      &--dateTime {
        margin-left: auto;
        word-break: break-word;
        white-space: normal;
        color: $text-color;
        font-size: 12px;
        opacity: 0.7;
      }

      & + .attendees-item {
        margin-top: 12px;
      }
    }
  }

  .cs-scroll {
    max-height: 390px;
    overflow-x: hidden;

    .simplebar-track.simplebar-vertical {
      width: 4px;
      right: 0px;
      background: rgba(9, 30, 62, 0);
    }

    .simplebar-track {
      overflow: visible;
    }

    .simplebar-visible:before {
      opacity: 1 !important;
    }

    .simplebar-scrollbar:before {
      background: $app-brand-primary;
      left: -1px;
      right: -1px;
    }
  }

  .report-user-content {
    @include flexbox;
    width: 100%;
    &--details {
      padding-left: 10px;
      .attendees-item--name {
        margin: 0 0 4px 0 !important;
      }
    }
  }

  .attendees-reason {
    padding-top: 12px;
    align-self: start;
    label {
      font-size: 12px;
      line-height: 14px;
      color: $text-color;
      opacity: 0.7;
    }
    p {
      color: $text-color;
      margin-top: 5px;
      word-break: break-word;
    }
  }
}
.create-user-custom-modal {
  .modal-header {
    padding: 0 !important;
    button {
      right: 58px;
      top: 63px;
      z-index: 9;
      background: $app-brand-primary;
      width: 22px;
      height: 22px;
      font-size: 14px;
      opacity: 1;
      color: $white;
      i {
        &:before {
          font-size: 16px;
        }
      }
      &:hover {
        opacity: 0.5;
      }
      @media (max-width: 480px) {
        right: 30px;
        top: 10px;
      }
    }
  }
  .modal-body {
    padding: 0 0px 20px 0px !important;
  }
  .modal-dialog {
    max-width: 640px;
  }
  .modal-content {
    background: transparent;
    box-shadow: none;
    .app-c-mw--header {
      padding: 0 !important;
    }
  }
}
.app-l-create-memorial {
  .modalBodyScroll {
    max-height: max-content !important;
    .simplebar-track {
      display: none;
    }
    .simplebar-mask,
    .simplebar-wrapper,
    .simplebar-content-wrapper {
      overflow: visible !important;
    }
  }
  .modal-body {
    padding: 0 !important;
  }
  .app-c-mw--header__form {
    min-height: auto !important;
  }
  .createMemorialBodyScroll {
    height: calc(100vh - 335px);
    @media (max-width: 960px) {
      height: calc(100vh - 180px);
    }
    @media (max-width: 480px) {
      height: calc(100vh - 108px);
      max-height: max-content;
    }
    .simplebar-track {
      display: block;
    }
    .simplebar-content-wrapper {
      overflow: auto !important;
    }
    .simplebar-track.simplebar-vertical {
      width: 8px;
      right: -4px;
    }
  }
  .mr-only {
    display: none !important;
  }
  .ml-only {
    display: block !important;
  }
}
.app-l-mobile-filed-block {
  .app-l-phone-input {
    border: 1px solid $generic-border-color;
    @include border-radius(16px);
    padding: 8px 10px 7px 17px;
    margin-top: 16px;
    background: $white;
    font-size: $text-lg;
    input {
      border: none;
    }
    .app-c-input__label {
      line-height: 22px;
      margin: 0;
      font-size: $text-md;
      font-family: $font-secondary;
      color: $text-label;
      display: block;
    }
    .form-control,
    .flag-dropdown {
      border: none;
      padding: 0;
      background: transparent;
      outline: none;
      box-shadow: none;
      height: auto;
      .selected-flag {
        padding-left: 0 !important;
      }
    }
    .form-control {
      padding-left: 36px;
    }
    .PhoneInputInput {
      border: none;
    }
    .country-list {
      bottom: 15px;
    }
  }
}
.app-c-number-select {
  .app-l-phone-input {
    border: 1px solid $generic-border-color;
    @include border-radius(16px);
    padding: 8px 10px 7px 17px;
    min-height: 64px;
    margin-top: 16px;
    background: $white;
    font-size: $text-lg;
    display: flex;
    align-items: center;
    .app-c-input__label {
      line-height: 22px;
      margin: 0;
      font-size: $text-md;
      font-family: $font-secondary;
      color: $text-label;
      display: block;
    }
    .form-control,
    .flag-dropdown {
      border: none;
      padding: 0;
      background: transparent;
      outline: none;
      box-shadow: none;
      height: auto;
      .selected-flag {
        padding-left: 0 !important;
      }
    }
    .form-control {
      padding-left: 36px;
    }
    .PhoneInputInput {
      border: none;
    }
    .country-list {
      top: 35px;
    }
  }
}
.order-payment-footer-graphics {
  text-align: right;
  margin-bottom: -20px;
}
.order-confirmation-details {
  text-align: center;
  padding-bottom: 10px;
  .app-h-brand {
    justify-content: center;
    padding-top: 10px;
  }
}

.small-image {
  width: 37px !important;
  border-radius: 8px;
}
.app-l-popup-content-error {
  .app-c-validation-message {
    padding-left: 0;
  }
  .order-library-btn {
    padding: 0;
    position: relative;
    left: auto;
    right: 0;
    float: right;
  }
  .order-message {
    height: auto;
    min-height: auto;
    display: inline-block;
    width: 100%;
    padding: 15px 17px;
    textarea {
      margin-bottom: 15px;
    }
    .app-c-validation-message {
      float: left;
      margin: -3px 0 0 0;
    }
  }
}

.app-l-modal-mobile {
  display: none;
}
@media (max-width: 768px) {
  .app-l-modal-mobile {
    display: block;
    overflow: auto;
  }
  .app-l-modal-web {
    display: none;
  }
}
.app-l-slot-details {
  .equal-split {
    margin: 0 !important;
  }
}
.app-l-crop-popup {
  .modal-dialog {
    max-width: 900px;
    .simplebar-content {
      div {
        display: flex;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .uploadedImage,
        .croppedPreview {
          flex: 1;
          flex-direction: column;
          border: 0.5px solid $generic-border-color;;
          border-radius: 4px;
          padding: 5px; 
          
          .ReactCrop,canvas{
            margin: auto;
          }
          .cropper-heading{
            font-size: $text-xl;
            margin-bottom: 20px;
          }
        }
        .croppedPreview{
          margin-left: 10px;
          @media (max-width: 768px) {
            margin-left: 0px;
            margin-top: 15px;

          }
        }
      }
    }
  }
}
.btn-less-height {
    min-height: 51px !important;
}
.modal-preview { 
  .modal-dialog {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 98vw;
    height: 98vh;
    position: fixed;
    top: 1%;
    left: 1%;
      .modal-content {
        height: 100% !important;
        .modal-header {   
          padding: 1rem 1rem !important;
        }
        .modal-body {
          height: 100% !important;
        }
      }
  }

}
body.mob-overflow{
  overflow:hidden !important;
}
.app-l-thank-you-container{
  h5{
    float: left;
    width: 100%;
  }
  div{
    float: left;
    width: 100%;
  }
  .info--tooltip{
    width: auto;
  }
  form{
    width: 100%;
    float: left;
  }
  span,label,textarea{
    float: left;
    width: 100%;
  }
}