@media all and (-ms-high-contrast: none) {
 .mob-overflow .app-l-dashboard-base{
   z-index: -1;
 }
  .graphics-box3 .inner-skew1 {
    left: 0;
  }
  .content-boxes__full .content-boxes__right,
  .memorial-page-content-boxes .content-boxes__right {
    width: auto;
  }
  .memorial-intro p {
    max-width: 600px;
  }
  .select-bg__card {
    flex-basis: calc((100% / 4) - 16px);
  }
  .modal-dialog .modal-content .modal-footer button {
    height: 40px;
  }
  .app-c-input-box.lg-height {
    min-height: 64px;
  }
  .app-c-close.profile {
    width: 32px;
    background: #474747;
  }
  .app-c-close {
    .info--tooltip {
      display: flex !important;
      align-items: center;
      height: 32px;
    }
  }
  .private-card:after {
    content: '' !important;
    z-index: 1;
    position: absolute !important;
    width: 100%;
    height: 135px;
    left: 0;
    top: 0;
    display: block;
  }
  .private-card:before {
    background: url(/assets/images/block.svg) no-repeat center !important;
    width: 100%;
    height: 135px;
    left: 0;
    top: 0;
    display: block;
  }
  .private-card {
    img {
      opacity: 0.25;
    }
  }
  // .app-l-dashboard-base .app-l-dashboard-base-wrapper .app-l-dashboard--left .app-l-dashboard__navigation .nav {
  //   width: 280px;
  // }
  .pageLayout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    min-height: 100vh;
    height: 100%;
  }
  .app-header {
    height: auto;
    min-height: auto;
  }
  .add-services-block-no-data {
    max-width: 600px;
  }
  .app-c-tag-card .app-c-tag-card__texting .name-elipse {
    max-width: 255px !important;
  }
  .app-c-memorial-post__banner {
    line-height: 20px;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 20px;
  }
  label.app-c-card-btn {
    &.bg1,
    &.bg2,
    &.bg3,
    &.bg4 {
      &:after {
        display: none !important;
      }
      .custom-checkbox {
        position: absolute;
        right: 10px;
        top: 12px;
      }
    }
  }
  label.app-c-card-btn {
    &:after {
      display: none !important;
    }
    .custom-checkbox {
      position: absolute;
      right: 10px;
      top: 12px;
    }
    &.full__checkbox {
      background: #fff;
      border: 1px solid #e6e8ec;
      border-radius: 16px;
    }
    .custom-radio {
      top: 10px;
    }
  }
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
  .melmo-hidden {
    overflow: visible;
  }
  .app-c-memlo-wrapper {
    height: auto;
  }
  .app-c-mw--header__form {
    &.forgotpassword {
      display: block !important;
      .app-c-mw-form--title {
        padding-top: 154px;
      }
    }
  }
  .create-table-dropdown .creatable-dropdown .css-yk16xz-control .css-g1d714-ValueContainer {
    padding: 0 !important;
  }

  @media (min-width: 768px){
    .app-l-my-memorials .app-c-card {
      flex-basis: 19.4%;
    }
  }
  .memorial-profile__name {
    width: 100%;
  }
  .app-c-close {
    height: 32px;
  }
  .app-c-memlo-wrapper .app-c-memlo-wrapper__left .app-c-mw--header__form .app-c-mw-form .form-overflow .app-c-phone-number .app-l-phone-input {
    min-height: 64px;
  }
  .app-l-dashboard-base {
    min-height: calc(100vh - 229px);
  }
  .learn-more--wrapper {
    min-height: calc(100vh - 280px);
  }
  .learn-more--container {
    // width: 600px;
    .learn-more--subtitle {
      width: 100%;
    }
  }
  // .app-c-memorial-post__texting {
  //   width: 600px;
  // }
  .app-c-tag-card .app-c-tag-card__avatar {
    height: 56px;
  }
  .app-c-memorial-post__banner img {
    height: auto;
    -webkit-transform: translate(0px, 0);
    -ms-transform: translate(0px, 0);
    transform: translate(0px, 0);
    width: auto;

  }

  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .app-c-memorial-post__banner img {
      height: 600px;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  @supports (-ms-ime-align: auto) {
    .app-c-memorial-post__banner img {
      height: auto;
      -webkit-transform: translate(0px, 0);
      -ms-transform: translate(0px, 0);
      transform: translate(0px, 0);
      width: auto;
    }
  }
  .app-c-tab-wrapper{
    form{
      text-align: center;
    }
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .app-c-tab-wrapper{
      form{
        text-align: center;
      }
    }
    .graphics-box3 .inner-skew1 {
      left: 0;
    }
    .content-boxes__full .content-boxes__right,
    .memorial-page-content-boxes .content-boxes__right {
      width: auto;
    }
    .memorial-intro p {
      max-width: 600px;
    }
    .select-bg__card {
      flex-basis: calc((100% / 4) - 16px);
    }
    .modal-dialog .modal-content .modal-footer button {
      height: 40px;
    }
    .app-c-input-box.lg-height {
      min-height: 64px;
    }
    .app-c-close.profile {
      width: 32px;
      background: #474747;
    }
    .app-c-close {
      .info--tooltip {
        display: flex !important;
        align-items: center;
        height: 32px;
      }
    }
    .private-card {
      img {
        opacity: 0.25;
      }
    }
    .pageLayout {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 0 auto;
      min-height: 100vh;
      // add height that is less than the min-height
      // it can even be 1px, as long as it is less than the min-height
      height: 100%;
    }
    .app-header {
      height: auto;
      min-height: auto;
    }
    .add-services-block-no-data {
      max-width: 600px;
    }
    .app-c-tag-card .app-c-tag-card__texting .name-elipse {
      max-width: 255px !important;
    }
    .app-c-memorial-post__banner {
      line-height: 20px;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      padding-top: 20px;
    }
    label.app-c-card-btn {
      &.bg1,
      &.bg2,
      &.bg3,
      &.bg4 {
        &:after {
          display: none !important;
        }
        .custom-checkbox {
          position: absolute;
          right: 10px;
          top: 12px;
        }
      }
    }
    label.app-c-card-btn {
      &:after {
        display: none !important;
      }
      .custom-checkbox {
        position: absolute;
        right: 10px;
        top: 12px;
      }
      &.full__checkbox {
        background: #fff;
        border: 1px solid #e6e8ec;
        border-radius: 16px;
      }
      .custom-radio {
        top: 10px;
      }
    }
    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
    .melmo-hidden {
      overflow: visible;
    }
    .app-c-memlo-wrapper {
      height: auto;
    }
    .app-c-mw--header__form {
      &.forgotpassword {
        display: block !important;
        .app-c-mw-form--title {
          padding-top: 154px;
        }
      }
    }
    .create-table-dropdown .creatable-dropdown .css-yk16xz-control .css-g1d714-ValueContainer {
      padding: 0 !important;
    }
    .memorial-profile__name {
      width: 100%;
    }
    .app-c-close {
      height: 32px;
    }
    .app-c-memlo-wrapper .app-c-memlo-wrapper__left .app-c-mw--header__form .app-c-mw-form .form-overflow .app-c-phone-number .app-l-phone-input {
      min-height: 64px;
    }
    .app-l-dashboard-base {
      min-height: calc(100vh - 229px);
    }
    .learn-more--wrapper {
      min-height: calc(100vh - 280px);
    }
    .learn-more--container {
      .learn-more--subtitle {
        width: 100%;
      }
    }
    .app-c-tag-card .app-c-tag-card__avatar {
      height: 56px;
    }
  }
}



@media (max-width: 768px){
  @media all and (-ms-high-contrast: none) {
    .content-boxes__full .content-boxes__right,
    .memorial-page-content-boxes .content-boxes__right {
      width: 100%;
    }
    .app-l-my-memorials .app-c-card {
      flex-basis:100%;
    }
    .navbarImage{
      right: 0;
      opacity: 1;
      z-index: 1;
    }
    .app-header__wrapper .app-c-profile-dropdown {
        margin-right: 0;
        margin-left: 0;
    }
    .private-card:after {
      content: '' !important;
      z-index: 1;
      position: absolute !important;
      width: 90px!important;
      height: 100%;
      left: 0;
      top: 0;
      display: block;
    }
    .private-card:before {
      background: url(/assets/images/block.svg) no-repeat center !important;
      width: 90px;
      height: 135px;
      left: 0;
      top: 0;
      display: block;
    }
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {

      .content-boxes__full .content-boxes__right,
      .memorial-page-content-boxes .content-boxes__right {
        width: 100%;
      }
      .app-l-my-memorials .app-c-card {
        flex-basis:100%;
      }
    }
  }
}

.uploaded-images--tribute--item{
  img{
    object-fit: contain;
  }
  video{
max-height: 300px!important;  }
}

.melmo-hidden{
  min-height: 100vh;
    display: FLEX;
    flex-direction: column;
}
.app-c-memorial-post {
  max-width: 580px;
  @media (max-width: 1200px){
    
  }
}
.obituary-post-image {
  max-height: 400px!important;

}
.app-c-tab-wrapper{
  video{
    max-height: 400px;
    object-fit: contain;
    width: 100%;
  }
}
.app-c-memorial-post__footer{
  .app-c-dark-text{
    white-space: nowrap;
  }
}
.app-c-profile-dropdown{
  .dropdown-menu{
    right: 0!important;
    left: auto!important;
  }
}