.container {
  @include screen-sm() {
    padding: 0px 20px;
  }
}

.app-l-search-base {
  overflow: hidden;
}

.app-l-search-wrapper {
  max-width: 691px;
  margin: auto;
  padding: 62px 0px 66px 0px;
  text-align: center;
  position: relative;

  @include screen-sm() {
    text-align: left;
    padding: 5px 0px 56px 0px;
  }

  h2 {
    color: $text-color;

    @include screen-sm() {
      margin-bottom: 0;
    }
  }

  p {
    font-size: $text-lg;
    line-height: 28px;
    display: none;

    @include screen-sm {
      display: block;
    }
  }
}

.app-l-search-box {
  @extend %flex-align-center-justify-space-between-row;
  background: $white;
  @include border-radius(16px);
  @include boxShadow(0px 4px 8px 0px rgba(93, 39, 48, 0.1));
  text-align: left;
  margin-top: 56px;

  @media (min-width: 1268px) {
    &:focus-within {
      @include boxShadow(0px 7px 8px 3px rgba(93, 39, 48, 0.1));
    }
  }

  @include screen-lg() {
    margin-top: 40px;
  }

  .app-c-btn {
    min-width: 155px;
  }

  @include screen-sm() {
    flex-wrap: wrap;
    @include noShadow();
    background: none;
    justify-content: center;
    margin: auto;
    margin-top: 16px;
    max-width: 540px;
  }

  .app-c-btn {
    margin-right: 8px;

    @include screen-sm() {
      margin-right: 0;
    }
  }
}

.app-l-search-fields {
  flex-grow: 1;
  @extend %flexbox;

  @include screen-sm() {
    @include boxShadow(0px 4px 8px 0px rgba(93, 39, 48, 0.1));
    background: $white;
    @include border-radius(16px);
    margin-bottom: 24px;
    flex-shrink: 0;
    flex-basis: 100%;
  }

  .app-l-search-field__item {
    position: relative;
    padding: 8px;
    padding-left: 64px;
    height: 64px;
    flex: 1;
    @extend %align-start-justify-center-col;

    @include screen-sm() {
      padding-left: 50px;
    }

    &::before {
      position: absolute;
      left: 30px;
      top: 0;
      bottom: 0;
      font-family: 'memlo';
      font-size: 18px;
      color: $text-color;
      height: 24px;
      width: 16px;
      margin: auto;
      line-height: 24px;

      @include screen-sm() {
        left: 20px;
      }
    }

    &.name {
      // border-right: 1px dashed rgba(0, 0, 0, 0.1);
      // max-width: 42%;
      .app-l-form-input {
          width: 100%;
      }

      &::before {
        content: '\e956';
      }
    }

    &.location {
      .form-group {
        width: 100%;
      }

      @include screen-sm() {
        flex-basis: 141px;
      }

      &::before {
        content: '\e94b';
      }

      .autocomplete-dropdown-container {
        position: absolute;
        bottom: auto;
        top: 43px;
        left: 17px;
        z-index: 11;
      }

      .app-c-link-btn {
        bottom: -24px;
      }
    }

    &:not(.on-focus) {
      .form-group label {
        position: absolute;
        pointer-events: none;
        top: 21px;
        font-size: 16px;
        z-index: 1;

        @include screen-md() {
          font-size: $text-md;
        }
      }

      &:focus-within {
        .form-group {
          label {
            font-size: 12px;
            font-family: $font-secondary;
            font-weight: 400;
            position: absolute;
            pointer-events: none;
            transition: 0.3s all;
            z-index: 1;
            top: 8px;
          }

          input {
            position: relative;
            top: 8px;
            padding-right: 28px;
          }
        }
      }
    }

    &.on-focus {
      .form-group {
        label {
          font-size: $text-md;
          font-family: $font-secondary;
          font-weight: $font-weight-normal;
          position: absolute;
          pointer-events: none;
          transition: 0.3s all;
          z-index: 1;
          top: 8px;
        }

        .form-control {
          height: 20px;
          border: none;
          font-size: $text-lg;
          color: $text-color;
          font-family: $font-secondary;
          font-weight: $font-weight-medium;
          position: relative;
          top: 8px;
        }
      }
    }

    .form-group {
      margin: 0;

      label {
        font-size: 14px;
        line-height: 22px;
        color: $text-color-light;
        display: block;
        margin: 0;
        @extend %truncate;
        max-width: 60%;
        transition: 0.3s all;
      }
      .form-control {
        border: none;
        padding: 0;
        @extend %truncate;
        &.with-clear {
          padding-right: 30px;
        }
        &:focus {
          outline: none;
          @include noShadow();
        }
      }
    }
  }
}

.search-result-wrapper {
  .container {
    padding: 0;
  }
  @media (max-width: 767px) {
  .app-c-card__avatar{
   
      width: 60px!important;
      height: 60px!important;
      flex: 0 0 60px!important;
      max-width: 60px!important;
    }
    .app-c-card__texting{
      padding-top: 0!important;
      padding-left: 40px!important;
    }

  }
  @media (max-width: 480px) {
    .app-c-card__avatar{
     
        width: 40px!important;
        height: 40px!important;
        flex: 0 0 40px!important;
        max-width: 40px!important;
      }
    }
  

  .app-c-card-flex {
    padding: 0px 20px 20px 20px;

    @include screen-xs() {
      overflow: hidden;
      background: url(/assets/images/graphics-line4.svg) no-repeat bottom right;

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        top: 14%;
        left: -20px;
        background: url(/assets/images/graphics-line3.svg) no-repeat top left;
        z-index: -1;
      }
    }

    &.app-c-not-found {
      @include screen-xs() {
        background: none;
      }
    }

    .pattern1 {
      display: none;

      @include screen-xs() {
        display: block;
        bottom: inherit;
        top: 56%;
        left: inherit;
        right: -100px;
      }
    }
  }
}

.app-c-card-flex {
  @extend %flex-justify-start-row;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
  // overflow-y: scroll;
  // max-height: 592px;

  &.six-col {
    margin-left: -8px;
    margin-right: -8px;

    .app-c-card {
      flex-basis: calc((100% / 6) - 16px);
      margin: 16px 8px 0px 8px;
      padding: 24px 0px;

      @include screen-lg() {
        flex-basis: calc((100% / 5) - 16px);
      }

      @include screen-md() {
        flex-basis: calc((100% / 3) - 16px);
      }

      @include screen-sm() {
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(flex-start);
        margin: 0px 0px 16px 0px;
        padding: 24px;
        width: 100%;
        flex-basis: 100%;

        .app-c-card__texting {
          text-align: left;
          flex-grow: 1;
          padding-left: 24px;
        }
      }
    }

    .app-c-card__texting {
      > * {
        @extend %truncate;
        max-width: 164px;
      }
    }
  }

  // overflow-y: scroll;
  // max-height: 592px;

  .app-c-card {
    @include screen-sm() {
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(flex-start);
      margin: 0px 0px 16px 0px;
      padding: 24px;
      width: 100%;
      flex-basis: 100%;

      .app-c-card__texting {
        text-align: left;
        flex-grow: 1;
        padding-left: 24px;
      }
    }
  }
}
.app-l-my-memorials{
  .app-c-card{
    flex-basis: 22%;
    @include screen-lg() {
      flex-basis: 30.3%;
    }
    @media (max-width: 991px){
      flex-basis: 32%;
        }
    @media (max-width: 767px){
      flex-basis: 100%;
    }
  }
}
.app-c-card {
  background: $white;
  @include border-radius(16px);
  @include boxShadow(0px 4px 8px 0px rgba(93, 39, 48, 0.1));
  padding: 24px 10px;
  flex-basis: 17%;
  margin: 1.5%;
  @extend %flex-align-center-justify-space-between-col;
  @include cm-transition;

  &:hover {
    @include transform(translateY(-5px));
    @include boxShadow(0px 8px 16px rgba(26, 39, 126, 0.16));
  }

  @include screen-lg() {
    flex-basis: 30.33%;
  }

  @include screen-md() {
    margin: 5px !important;
  }

  .app-c-card__avatar {
    width: 104px;
    height: 104px;
    @include border-radius(16px);
    // overflow: hidden;
    margin-right: 0px !important;
    @extend %flex-all-center-col;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      @include border-radius(16px);
    }

    .app-c-card__name {
      color: $white;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: $font-weight-bold;
    }

    &.bg1 {
      background: $neutrals-color2;
    }

    &.bg2 {
      background: $neutrals-color1;
    }

    &.bg3 {
      background: $neutrals-color4;
    }
  }
}
.app-list-Of-Obituaries {
  .app-c-card {
    flex-basis: 22%;

    @include screen-md() {
      flex-basis: 32%;
    }

    @include screen-md() {
      margin: 5px !important;
    }
  }
}

.app-c-card__texting {
  text-align: center;
  padding: 16px 0px 0px;

  h5 {
    margin: 0;
  }

  > * {
    @extend %truncate;
    max-width: 168px;
  }
}

.app-c-card__date {
  font-size: $text-md;
  display: block;
  line-height: 24px;
  font-family: $font-secondary;
  font-weight: $font-weight-normal;
  color: $text-color-light;
}

.app-c-card__location {
  font-size: $text-md;
  display: inline-block;
  line-height: 24px;
  font-weight: $font-weight-normal;
  color: $text-color;
  margin-top: 4px;
  position: relative;
  padding-left: 20px;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '\e94a';
    font-size: 16px;
    font-family: 'memlo';
    color: $neutrals-color1;
  }
}

.pull-to-footer {
  height: 482px;

  @include screen-lg {
    height: 426px;
  }
}

.app-similar-memorials {
  text-align: center;
  margin-top: 128px;

  &.mt-5 {
    @include screen-sm() {
      margin-top: 0 !important;
    }
  }

  @include screen-lg() {
    margin-top: 40px;
  }

  @include screen-sm() {
    //overflow: hidden;
    padding: 40px 0px;
    margin-top: 20px;
    .app-c-card { 
      margin: 5px auto!important;
    }
  }
}

.app-similar-memorials__head {
  position: relative;
  z-index: 3;
  padding-top: 105px;

  p {
    color: $text-color-op-6;
    margin-top: 10px;
  }

  @include screen-lg() {
    padding-top: 60px;
  }

  @include screen-sm() {
    padding-top: 30px;
    text-align: left;
    padding-left: 20px;
  }
}

.app-slider-base {
  // @include transform(rotate(-2deg));
  overflow: hidden;
  padding: 20px 0px 50px 80px;
  width: 100%;
  position: relative;
  z-index: 3;
  margin-right: -8px;

  @include screen-lg() {
    padding-bottom: 20px;
  }

  @include screen-md() {
    padding-left: 30px;
  }

  @include screen-sm() {
    padding-left: 6px;
    padding-bottom: 0;
    padding-top: 12px;
    margin-right: 0px;
    @include transform(rotate(0deg));
  }
}

.no-review-customer{
  min-height: 100px;
  @extend %flex-all-center;
  @include boxShadow(0px 4px 8px 0px rgba(93, 39, 48, 0.1));
  border-radius: 5px;
  margin: 20px 0;
}

.app-slider-holder {
  width: 1200px;
  padding-top: 20px;

  // @include transform(rotate(2deg));

  @include screen-sm() {
    padding-top: 0px;
    width: 100%;
    @include transform(rotate(0deg));
  }

  .app-c-card {
    margin: 12px;
    width: 200px;
    cursor: pointer;
  }

  .slick-slider {
    padding-bottom: 96px;
    position: relative;

    @include screen-lg() {
      padding-bottom: 80px;
    }

    .slick-prev,
    .slick-next {
      position: absolute;
      bottom: 0;
      top: inherit;
      left: 0;
      width: 40px;
      height: 40px;
      background: $app-brand-primary;
      @include border-radius(50%);

      @include screen-sm() {
        left: 0;
        right: 0;
        bottom: -10px;
        margin: auto;
      }

      &::before {
        font-family: 'memlo';
        color: $white;
        font-size: 12px;
        opacity: 1;
      }

      &.slick-disabled {
        background: $app-brand-primary-light;

        &::before {
          opacity: 1;
        }
      }
    }

    .slick-prev {
      left: 10px;

      @include screen-sm() {
        left: -47px;
      }

      &::before {
        content: '\e901';
      }
    }

    .slick-next {
      left: 58px;

      @include screen-sm() {
        right: -47px;
        left: 0;
      }

      &::before {
        content: '\e902';
      }
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.app-l-input-search {
  position: relative;

  .app-c-link-btn {
    position: absolute;
    right: 3px;
    top: 0;
  }

  input {
    padding-right: 30px !important;
  }
}

.app-c-card-add-review {
  flex-wrap: nowrap;
  overflow: auto;
  .app-c-card {
    flex-direction: column;
    align-items: center;
  }
  .app-c-card__texting {
    padding-left: 0 !important;
    text-align: center !important;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: $neutrals-color3;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $gray-shade-100;
    border-radius: 0px;
  }

  .app-c-card {
    min-width: 200px;
    margin-left: 5px;
    margin-right: 16px;
  }
}

.app-c-card {
  &.selected {
    position: relative;
    border: 1px solid $app-brand-primary;
    @include border-radius(20px);
    box-shadow: 0px 4px 8px rgba(237, 130, 148, 0.5);

    &::after {
      content: '';
      background: url('/assets/images/cardSelect.svg') no-repeat;
      width: 53px;
      height: 52px;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.app-c-card {
  overflow: hidden;
  &.published,
  &.toBePublished,
  &.draft,
  &.training,
  &.expired {
    position: relative;
    &::after {
      width: 200px;
      padding: 3px 16px;
      font-size: 11px;
      position: absolute;
      text-align: center;
      color: #ffffff;
      left: -63px;
      top: 25px;
      font-weight: 600;
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  &.published {
    border: 1px solid #198754;
    box-shadow: 0px 4px 8px hsla(152, 69%, 31%, 0.5);
    &::after {
      content: 'Published';
      background-color: #198754;
    }
  }
  &.toBePublished {
    border: 1px solid #198754;
    @include border-radius(20px);
    box-shadow: 0px 4px 8px hsla(152, 69%, 31%, 0.5);
    &::after {
      content: 'To be published';
      background-color: #198754;
    }
  }
  &.draft {
    border: 1px solid #ffc107;
    @include border-radius(20px);
    box-shadow: 0px 4px 8px hsla(45, 100%, 51%, 0.5);
    &::after {
      content: 'Draft';
      background-color: #ffc107;
      color: $black;
      letter-spacing: 0.5px;
    }
  }
  &.training {
    border: 1px solid #000000;
    @include border-radius(20px);
    box-shadow: 0px 4px 8px hsla(0, 0%, 0%, 0.5);
    &::after {
      content: 'Training';
      background-color: #000000;
    }
  }
  &.expired  {
    border: 1px solid #dc3545;
    @include border-radius(20px);
    box-shadow: 0px 4px 8px hsla(354, 70%, 54%, 0.5);
    &::after {
      content: 'Expired ';
      background-color: #dc3545;
      letter-spacing: 0.5px;
    }
  }
}


.app-l-my-order-list {
  border: 1px solid #e6e8ec;
  margin-bottom: 20px;
  @include border-radius(8px);
  overflow: hidden;
  padding: 10px 30px 20px;

  table {
    border: none;
    margin-bottom: 0;

    @include screen-md() {
      width: 150vw;
    }

    @include screen-sm() {
      width: 170vw;
    }
  }

  tr {
    th,
    td {
      padding: 20px 10px !important;
      border-top: none !important;
      border-left: 0 !important;
      &.invoice {
        width: 140px;
      }
      &.action {
        width: 102px;
        text-align: center;
      }
      &.status {
        width: 100px;
      }
      .btn {
        background: transparent;
        padding: 0;
        color: $text-color;
        text-decoration: underline;
        font-size: 13px;
        line-height: 24px;
        outline: none;
        box-shadow: none;
      }
      &.text-muted {
        color: $text-color;
        opacity: 0.6;
      }
    }

    th {
      padding-bottom: 10px !important;
    }
  }

  thead {
    tr {
      th {
        border: none;
        font-family: $font-primary;
        font-weight: $font-weight-medium;
        border-right: 1px solid transparent;
        color: $text-color;
        opacity: 0.6;
        font-size: 10px;
        text-transform: uppercase;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1rem 0.75rem;
        border-right: 1px solid transparent !important;
        border-bottom: 1px dashed $bg-scroll !important;
        vertical-align: middle;
        word-break: break-all;
        max-width: 159px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $text-color;
        font-size: 13px;
        font-weight: $font-weight-medium;
        border-left: 0 !important;

        button {
          background: $app-brand-primary;
          @include border-radius(6px);
          color: $white;
          padding: 6px;
          font-weight: $font-weight-medium;
          font-size: 11px;
          outline: none;
          box-shadow: none;
        }
        img {
          width: 26px;
          height: 26px;
          @include border-radius(8px);
          overflow: hidden;
          margin-right: 8px;
        }

        .custom-switch .custom-control-label {
          &:after,
          &:before {
            @include border-radius(50px);
            border: none;
            outline: none;
            box-shadow: none;
          }

          &:before {
            left: -2.25rem;
            width: 36px;
            height: 22px;
            background: $lightGray;
          }

          &:after {
            top: calc(0.25rem + 2px);
            left: calc(-36px + 2px);
            width: calc(22px - 4px);
            height: calc(22px - 4px);
            background: $white;
          }
        }

        .custom-control-input:checked ~ .custom-control-label {
          &:before {
            background-color: $app-brand-primary;
          }

          &:after {
            left: calc(-36px + 4px);
          }
        }
      }
    }
  }
}

.app-l-label {
  div {
    padding: 5px 0;
  }

  b {
    width: 170px;
    float: left;
  }
}

.app-l-service-select {
  .form-group {
    width: 100%;
  }

  .format-label__control {
    box-shadow: none !important;
    outline: none !important;
    border-color: $generic-border-color !important;
    border-radius: 16px;
    padding: 8px 5px;
  }
  .format-label__value-container {
    @extend %truncate;
    display: block;
  }
  .format-label__menu-list {
    &::-webkit-scrollbar {
      width: 6px;
      height: 3px;
    }

    img {
      @include border-radius(50px);
    }

    &::-webkit-scrollbar-thumb {
      background: $neutrals-color3;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $gray-shade-100;
      border-radius: 0px;
    }

    .custom-label {
      @include flexbox;
      @include flex-direction(row);
      @include align-items(flex-start);
      @include justify-content(flex-start);
      word-break: break-all;
    }

    .label-sub-block {
      @include flexbox;
      @include align-items(flex-start);
      @include flex-direction(column);
      @include justify-content(flex-start);
      text-align: left;
      padding-left: 10px;
      font-size: 12px;
      .main-text {
        font-weight: 600;
      }
      .sub-text {
        font-size: 11px;
      }
    }

    .format-label__option {
      cursor: pointer;
      border-bottom: 1px solid rgb(238, 238, 238);
      padding: 6px 10px;
      &--is-selected {
        background: $app-brand-primary;
        color: $white;
        cursor: pointer;
      }
      &:hover {
        background: #ffb7b7;
        color: $white;
        cursor: pointer;
      }

      &--is-focused {
        background: none;
        color: inherit;
      }

      .create-option {
        &--is-focused {
          color: $white !important;
        }
      }
    }
  }

  .format-label__indicator {
    cursor: pointer;
  }

  .format-label__value-container {
    .custom-label {
      @include flexbox;

      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }

      .sub-text {
        display: none;
      }
    }
  }
}
.app-l-grant-request {
  padding-bottom: 40px;
  a {
    color: $text-color;
    margin-left: 0;
    min-width: 104px;
    height: 32px;
    font-weight: $font-weight-semibold;
    text-align: center;
    margin-right: 15px;

    &.active {
      @include border-radius(50px);
      color: $white !important;
      background: linear-gradient(260.98deg, #ed8294 -63.28%, #ed8294 102.74%), #ed8294;
      box-shadow: 0px 4px 4px rgba(237, 130, 148, 0.2), inset 0px 1px 2px #ffabb9;
    }
  }
}

.format-label__indicators {
  .app-c-link-btn {
    @extend %flex-all-center;
  }
}
//added due to import issue in production
.css-g1d714-ValueContainer {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 2px 8px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.css-1hb7zxy-IndicatorsContainer {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}
.format-label__img {
  padding-left: 10px;
  padding-right: 1px;
  img {
    width: 18px;
    @include border-radius(50%);
    object-fit: cover;
  }
}