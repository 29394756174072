@font-face {
  font-family: 'memlo';
  src: url('../../../assets/fonts/font-icons/memlo.eot?77tvi6');
  src: url('../../../assets/fonts/font-icons/memlo.eot?77tvi6#iefix') format('embedded-opentype'), url('../../../assets/fonts/font-icons/memlo.woff2?77tvi6') format('woff2'),
    url('../../../assets/fonts/font-icons/memlo.ttf?77tvi6') format('truetype'), url('../../../assets/fonts/font-icons/memlo.woff?77tvi6') format('woff'),
    url('../../../assets/fonts/font-icons/memlo.svg?77tvi6#memlo') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'memlo' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa{
  color: #ffb7b7
}
.icon-cross:before {
  content: "\e91d";
  // font-size: 18px!important;

}
.icon-add:before {
  content: "\e91c";
}
.icon-whatsappOutline:before {
  content: "\e91b";
  color: #6b788b;
}
.icon-trash:before {
  content: "\e91a";
  color: #ed8294;
}
.icon-delete:before {
  content: '\e917';
}
.icon-lock:before {
  content: '\e916';
}

.icon-markunread:before {
  content: '\e914';
}
.icon-phone-android:before {
  content: '\e915';
}
.icon-info:before {
  content: '\e913';
}
.icon-calender:before {
  content: '\e911';
}
.icon-home:before {
  content: '\e912';
}
.icon-bell:before {
  content: '\e90f';
}
.icon-at-sign:before {
  content: '\e90d';
}
.icon-call:before {
  content: '\e90e';
}
.icon-image:before {
  content: '\e90c';
}
.icon-attach-media:before {
  content: '\e90b';
}
.icon-edit-pen:before {
  content: '\e90a';
}
.icon-like-colored:before {
  content: '\e907';
  color: #ed8294;
}
.icon-union-colored:before {
  content: '\e908';
  color: #ffb85c;
}
.icon-plus:before {
  content: '\e909';
}
.icon-tree-colored .path1:before {
  content: '\e900';
  color: rgb(166, 124, 82);
}
.icon-tree-colored .path2:before {
  content: '\e906';
  margin-left: -1em;
  color: rgb(62, 157, 140);
}
.icon-fb:before {
  content: '\e904';
}
.icon-twitter:before {
  content: '\e905';
}
.icon-down:before {
  content: '\e903';
}
.icon-left:before {
  content: '\e901';
}
.icon-right:before {
  content: '\e902';
}
.icon-check:before {
  content: '\e910';
}
.icon-clover:before {
  content: '\e918';
}
.icon-email-solid:before {
  content: '\e929';
}
.icon-facebook:before {
  content: '\e92a';
}
.icon-flag:before {
  content: '\e92b';
}
.icon-heart:before {
  content: '\e92c';
}
.icon-instagram:before {
  content: '\e932';
}
.icon-left-arrow:before {
  content: '\e948';
}
.icon-like:before {
  content: '\e949';
}
.icon-location:before {
  content: '\e94a';
}
.icon-map-pin:before {
  content: '\e94b';
}
.icon-mouse:before {
  content: '\e954';
}
.icon-right-arrow:before {
  content: '\e955';
}
.icon-search:before {
  content: '\e956';
}
.icon-tag:before {
  content: '\e957';
}
.icon-union:before {
  content: '\e959';
}
.icon-whatsapp:before {
  content: '\e95b';
}
