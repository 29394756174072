.landing-page {
  .app-l-search-base {
    @include screen-md() {
      .container {
        padding: 0;
      }

      // background: $neutrals-color3-light;
    }
  }

  .app-header {
    @include screen-md() {
      background: $neutrals-color3-light;
    }
  }

  .container {
    @include screen-xl() {
      max-width: 1140px;
    }
    @media (max-width:1188px) {
      max-width: 980px;
    }
  }
}

.app-l-landing__top {
  min-height: 600px;
  margin-top: 70px;
  position: relative;

  @include screen-xxl() {
    margin-top: 0;
    min-height: auto;
  }

  @include screen-md() {
    margin-top: 0;
  }

  &--left {
    max-width: 540px;
    @include screen-xxl() {
      max-width:562px;
      padding-left: 35px;

    }
    @include screen-lg() {
      padding-left: 75px;
      max-width: 500px;

    }
    .page-caption {
      font-family: $font-primary;
      font-size: 56px;
      font-weight: $font-weight-bold;
      letter-spacing: -0.03em;
      color: $text-color;
      line-height: 64px;

      @include screen-xxl() {
        font-size: 46px;
        line-height: 54px;
      }

      @include screen-xl() {
        font-size: 36px;
        line-height: 44px;
      }

      @include screen-md() {
        font-size: 24px;
        line-height: 45px;
      }

      @media (max-width: 360px) {
        text-align: center;
      }

      .pc-line-1 {
        color: $text-color;
        display: block;

        @include screen-md() {
          display: inline-block;
        }
      }

      .pc-line-2 {
        color: $text-color;
        display: inline-block;

        @include screen-md() {
          display: inline;
        }
      }

      .just-lovely {
        font-family: $handwritten-font;
        font-size: 120px;
        margin-left: 8px;
        font-style: italic;
        font-weight: $font-weight-normal;

        @include screen-xxl() {
          font-size: 110px;
        }

        @include screen-xl() {
          font-size: 90px;
        }

        @include screen-md() {
          font-size: 56px;
          margin: 0;
        }
      }
    }

    .page-subtitle {
      color: $text-color;
      font-weight: $font-weight-medium;
      font-size: 18px;
      letter-spacing: -0.03em;
      opacity: 0.8;
      line-height: 36px;
      margin-top: 33px;
      margin-bottom: 56px;

      @include screen-xl() {
        font-size: 16px;
        margin-top: 23px;
        margin-bottom: 36px;
        line-height: 26px;
      }
      @include screen-lg() {
        margin-top: 13px;
        margin-bottom:13px;
      }

      @include screen-md() {
        font-size: $text-lg;
        line-height: 28px;
        margin: 0;
      }
    }

    .search-option--form {
      width: 584px;
      margin-bottom: 30px;
      @include screen-xl() {
        width: 100%;
      }
      @include screen-md() {
        width: 100%;
        margin-top: 16px;
      }

      .app-c-form {
        max-width: 584px;
        padding-right: 170px;
        padding-left: 50px;
        color: $text-color;

        @include screen-md() {
          max-width: 100%;
          width: 100%;
          padding-left: 35px;
          padding-right: 10px;

          &::placeholder {
            font-size: $text-md;
            letter-spacing: -0.04em;
          }
        }


      }

      .form-group {
        &::before {
          content: '\e956';
          font-family: $app-icons;
          position: absolute;
          top: 50%;
          @include transform(translate(0, -50%));
          z-index: 1;
          left: 23px;

          @include screen-md() {
            left: 11px;
            top: 25px;
            color: $text-color;
            font-size: 18px;
          }
        }

        input,
        input::-webkit-input-placeholder {
          font-size: 13px;

          @include screen-xxs() {
            font-size: 9px;
            letter-spacing: -0.01em;

          }
        }
      }

      .app-c-mw-form--submit {
        position: absolute;
        top: 8px;
        right: 8px;

        @include screen-md() {
          position: relative;
          top: 24px;
          right: 8px;
          text-align: center;
        }
      }
    }

    .scroll-down-prompt {
      margin-top: 90px;
      @extend %flexbox;
      @include screen-xl() {
        margin-top: 45px;
      }
      .sd-title {
        color: $text-color;
        font-weight: $font-weight-extra-bold;
        font-size: $text-lg;
        margin-left: 14px;
      }

      .sd-icon {
        em {
          color: $app-brand-primary;
          font-size: 18px;
        }

        @include animation(1.5s mouseMovt infinite);
      }

      @include screen-md() {
        display: none;
      }
    }

    @include screen-md() {
      max-width: 100%;
      padding: 0 18px 0;
      background: $neutrals-color3-light;
    }
  }

  &--right {
    display: none;

    @include screen-md() {
      display: block;
      margin-top: -50px;
    }

    img {
      width: 100%;
    }
  }
}

.app-l-landing__features {
  margin-top: 280px;
  @extend %flexbox;
  position: relative;

  @include screen-md() {
    @include flex-direction(column);
  }

  .app-l-landing__features--left {
    width: 50%;

    .lf-features {
      max-width: 430px;

      @media (max-width: 1035px) {
        max-width: 100%;
      } 

      .lf-features__title {
        &--heading {
          color: $text-color;
          letter-spacing: -0.03em;
          font-family: $font-primary;
          font-weight: $font-weight-extra-bold;
          line-height: 48px;
          font-size: 32px;
          margin-bottom: 24px;

          @include screen-md() {
            font-size: 24px;
            line-height: 40px;
            margin-bottom: 10px;
          }

          .just-lovely {
            font-family: $handwritten-font;
            font-size: 72px;
            font-weight: $font-weight-normal;
            color: $app-brand-primary;
            margin-left: 8px;
            font-style: italic;

            @include screen-md() {
              font-size: 56px;
            }
          }
        }

        &--xs {
          font-size: $text-md;
          text-transform: uppercase;
          letter-spacing: 0.2em;
          opacity: 0.5;
          color: $text-color;
          margin-bottom: 24px;
          font-family: $font-primary;
          font-weight: $font-weight-medium;

          @include screen-md() {
            margin-bottom: 4px;
          }
        }

        &--sub {
          font-size: $text-xl;
          line-height: 32px;
          @extend %common-text-prop-normal;
          opacity: 0.7;
          margin-bottom: 32px;

          @include screen-md() {
            font-size: $text-lg;
            line-height: 28px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .memorial-img {
      position: absolute;
      left: -410px;
      bottom: 72px;

      @include screen-md() {
        display: none;
      }
    }

    @media (max-width: 1150px) {
      width: 40%;
    }

    @include screen-md() {
      width: 100%;
      padding: 0 18px;
      margin-bottom: 70px;
    }
  }

  .app-l-landing__features--right {
    width: 50%;

    @media (max-width: 1150px) {
      width: 60%;
    }

    .lf-features {
      &__list {
        max-width: 550px;
        margin-left: auto;
        @include screen-md() {
          margin-top: 90px;
          max-width: 100%;
          li{
            width: 50%;
          }
        }
        @include screen-sm() {
          max-width: 100%;
        }

        ul {
          @extend %flexbox;
          @include flex-flow(row wrap);

          @include screen-sm() {
            @include flex-flow(column wrap);
          }

          li {
            margin-bottom: 68px;
            position: relative;

            @include screen-sm() {
              margin-bottom: 44px;
              width: auto;
            }

            .lf-features__list--wrapper {
              max-width: 256px;
            }

            &:nth-child(even) {
              .lf-features__list--wrapper {
                margin-left: 35px;
                @include transform(translateY(-125px));

                @include screen-sm() {
                  margin-left: 0;
                  @include transform(translateY(0));
                }
              }
            }
          }
        }

        &--image {
          @include border-radius(16px);
          display: inline-block;
          padding: 17px 20px;

          .lf-features__list--img {
            width: 40px;
          }

          &.purple {
            background: $card-bg2;
          }

          &.yellow {
            background: $card-bg4;
          }

          &.green {
            background: $neutrals-color2-light;
          }

          &.pink {
            background: $neutrals-color3-light;
          }
        }

        &--title {
          color: $text-color;
          letter-spacing: -0.03em;
          font-family: $font-primary;
          line-height: 28px;
          font-size: $lgs;
          font-weight: $font-weight-bold;
          margin: 24px 0px;
        }

        &--subtitle {
          font-size: $text-lg;
          font-weight: $font-weight-normal;
          opacity: 0.7;
          @extend %common-text-prop-normal;
          margin-bottom: 28px;

          @include screen-md() {
            line-height: 28px;
          }
        }

        &--learnMore {
          .learnMore {
            color: $app-brand-primary;
            cursor: pointer;
            border: 0;
            background-color: transparent;
            font-weight: $font-weight-bold;
            font-family: $font-primary;
            margin-left: -6px;

            &:after {
              content: '\e955';
              margin-left: 25px;
              font-family: $app-icons;
              position: relative;
              top: 1px;
            }

            &:hover {
              color: $neutrals-color3-dark;

              &:after {
                @include animation(1.6s arrowMovt infinite);
              }
            }

            @include screen-md() {
              font-size: $text-md;
            }
          }
        }
      }

      &__mobile-graphics {
        display: none;
        position: absolute;
        z-index: -1;

        @include screen-sm() {
          display: block;
        }

        &--summer {
          top: -171px;
          height: 365px;
          right: -155px;
          width: 360px;
        }

        &--feature-1 {
          top: -170px;
          width: 400px;
          right: -170px;
        }

        &--feature-2 {
          top: -84px;
          right: -120px;
          height: 430px;
          width: 380px;
        }

        &--feature-3 {
          top: -95px;
          right: -60px;
        }
      }
    }

    @include screen-md() {
      width: 100%;
      padding: 0 18px;
    }
  }

  @include screen-md() {
    margin-top: 50px;
  }
}

.app-l-landing__htd {
  margin-top: 110px;
  @extend %flex-and-col;
  position: relative;

  &--heading {
    .lf-htd {
      .lf-htd__title {
        &--heading {
          color: $text-color;
          letter-spacing: -0.03em;
          font-family: $font-primary;
          font-weight: $font-weight-extra-bold;
          line-height: 48px;
          font-size: 32px;
          margin-bottom: 24px;

          @include screen-md() {
            font-size: 24px;
            line-height: 40px;
            margin-bottom: 10px;
          }

          .just-lovely {
            font-family: $handwritten-font;
            font-size: 72px;
            font-weight: $font-weight-normal;
            color: $app-brand-primary;
            margin-left: 15px;
            font-style: italic;

            @include screen-md() {
              font-size: 56px;
              margin: 0;
              display: block;
            }
          }
        }

        &--xs {
          font-size: $text-md;
          text-transform: uppercase;
          letter-spacing: 0.2em;
          opacity: 0.5;
          color: $text-color;
          margin-bottom: 24px;
          font-family: $font-primary;
          font-weight: $font-weight-medium;

          @include screen-md() {
            margin-bottom: 4px;
          }
        }

        &--sub {
          font-size: $text-xl;
          line-height: 32px;
          @extend %common-text-prop-normal;
          opacity: 0.7;
          margin-bottom: 32px;
        }
      }
    }
  }

  &--body {
    @extend %flexbox;
    margin-bottom: 32px;
    margin-top: 80px;

    @include screen-md() {
      margin-top: 10px;
      flex-wrap: wrap;
    }
    @include screen-sm() { 
      @include flex-direction(column);
      flex-wrap: wrap;
    }

    .htd-items {
      width: 300px;
      padding: 18px;
      height: 380px;
      @include border-radius(16px);
      @extend %flex-justify-space-between-col;
      margin: 0 auto;

      @media (max-width: 350px) {
        width: 100%;
      }

      &+.htd-items {
        margin-left: 27px;

        @include screen-md() {
          margin-left: auto;
          margin-top: 20px;
        }
      }

      &:nth-child(2) {
        @include transform(translateY(-32px));

        //margin-bottom: 32px;
        @include screen-md() {
          @include transform(translateY(0));
        }
      }

      &:nth-child(3) {
        @include transform(translateY(-64px));

        //margin-bottom: 64px;
        @include screen-md() {
          @include transform(translateY(0));
        }
      }

      &:nth-child(4) {
        @include transform(translateY(-96px));

        // margin-bottom: 96px;
        @include screen-md() {
          @include transform(translateY(0));
        }
      }

      &.purp {
        background: $card-bg2;
      }

      &.pink {
        background: $neutrals-color3-light;
      }

      &.yellow {
        background: $card-bg4;
      }

      &.green {
        background: $neutrals-color2-light;
      }

      &--img {
        width: 230px;
        margin: 0 auto;
        display: block;

        @media (max-width: 1179px) and (min-width: 993px) {
          width: 180px;
        }

        @media (max-width: 350px) {
          width: 100%;
        }
      }

      &--caption {
        text-align: center;
      }

      &--title {
        font-size: $lgs;
        color: $text-color;
        letter-spacing: -0.03em;
        font-family: $font-primary;
        line-height: 28px;
        font-weight: $font-weight-bold;
        margin-bottom: 8px;
      }

      &--subtitle {
        font-size: $text-lg;
        opacity: 0.7;
        @extend %common-text-prop-normal;
        line-height: 28px;
        margin-bottom: 32px;
      }
    }
  }

  @include screen-md() {
    margin-top: 30px;
    padding: 0 18px;
    margin-bottom: 20px;
  } 
}

.app-similar-memorials {
  &--location {
    margin-top: 128px;
    margin-bottom: 90px;
    height: 482px;

    @include screen-md() {
      margin-top: 50px;
    }

    .app-slider-base {
      padding: 20px 0px 50px 0;

      @include screen-md() {
        padding: 0 18px;
      }

      .slick-slider {
        margin: 0 0px 0 80px;

        @include screen-md() {
          margin: 0;
        }

        img {
          object-fit: cover;
          height: 100%;
        }
      }
    }

    .heading {
      margin-top: 50px;
      font-family: $font-primary;
      font-size: 32px;
      line-height: 48px;
      font-weight: $font-weight-bold;
      text-align: center;
      @extend %common-text-prop-normal;
      margin-bottom: 16px;

      @include screen-md() {
        margin: 10px 0;
        text-align: left;
        font-size: 24px;
        line-height: 40px;
      }
    }

    .subtitle {
      font-size: $text-xl;
      line-height: 32px;
      font-weight: $font-weight-normal;
      text-align: center;
      @extend %common-text-prop-normal;
      opacity: 0.7;
      margin-bottom: 42px;

      @include screen-md() {
        margin: 0 0 10px;
        text-align: left;
        font-size: $text-lg;
        line-height: 28px;
      }
    }

    .search-option--form {
      width: 444px;
      margin: 0 auto 50px auto;

      @include screen-md() {
        width: 100%;
        margin: 0 auto 40px auto;
      }

      .app-c-form {
        max-width: 444px;
        padding-right: 160px;
        padding-left: 50px;
        color: $text-color;

        @include screen-md() {
          max-width: 100%;
          width: 100%;
          padding-left: 35px;
          padding-right: 0;

          &::placeholder {
            font-size: $text-md;
            letter-spacing: -0.04em;
          }
        }
      }

      .form-group {
        &::before {
          content: '\e956';
          font-family: $app-icons;
          position: absolute;
          top: 50%;
          @include transform(translate(0, -50%));
          z-index: 1;
          left: 23px;

          @include screen-md() {
            left: 11px;
            top: 25px;
            color: $text-color;
            font-size: 18px;
          }
        }
      }

      .app-c-mw-form--submit {
        position: absolute;
        top: 8px;
        right: 8px;

        @include screen-md() {
          position: relative;
          top: 24px;
          right: 8px;
          text-align: center;
        }
      }
    }

    .app-c-card {
      padding: 16px;

      .app-c-card__avatar {
        width: 168px;
        @include border-radius(4px);
        height: 149px;

        img {
          width: 100%;
          overflow: hidden;
          object-fit: cover;
        }
      }
    }
  }

  .app-slider-base {
    .app-slider-holder {
      width: inherit;

      .slick-track {
        @extend %flexbox;
      }
    }
  }
}

@keyframes mouseMovt {
  0% {
    @include transform(translateY(0));
  }

  50% {
    @include transform(translateY(5px));
  }

  100% {
    @include transform(translateY(0));
  }
}

@keyframes arrowMovt {
  0% {
    left: 0px;
  }

  50% {
    left: 5px;
  }

  100% {
    left: 0px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.app-c-mw-form--submit {
  a:hover {
    text-decoration: none;
  }
}
.learn-more{
  &--wrapper {
    min-height: 325px;
    // min-height: calc(100vh - 280px);
    // height: 100vh !important; 
   }
   &--container {    
      margin: 200px auto 0;
      max-width:600px;
      display: flex;
      flex-direction: column; 
      align-items: center; 
      @include screen-md() {
        margin-top: 100px;
        padding: 0 20px;
      }
   }
  &--image {
    margin-right: 23px;
    margin-left: -30px;
  }
  &--title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
      @include screen-sm() {
        max-width: 178px
      }
  }
  // &--subtitle {
  //   color: red;
  // }
}
.heading-container {
  display: flex;
  margin-bottom: 40px;
}