.app-c-drag-drop {
  border: 1px dashed $generic-border-color;
  height: 84px;
  @include border-radius(16px);
  @extend %flex-all-center-col;
  p {
    margin: 0px 0px 4px 0px;
  }
}
.app-c-create-post {
  .app-c-drag-drop {
    @include screen-md() {
      border: 0;
    }
  }
}
