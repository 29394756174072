.app-c-loader {
  position: fixed;
  z-index: 99999;
  &--backdrop {
    position: fixed;
    background: hsla(0, 0%, 0%, 0.451);
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    z-index: 11;
    &.modal-loader {
      position: absolute;
      background: #96969612;
    }
  }
  &--wrapper {
    position: fixed;
    width: 90px;
    height: 90px;
    background: #ffffff;
    top: 50%;
    right: 50%;
    @include border-radius(10px);
    @include transform(translate(50%, -50%));
    z-index: 12;
    @extend %flex-all-center;
  }
  .loading-text {
    position: absolute;
    bottom: 11px;
    color: $white;
    width: 100%;
    text-align: center;
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }
  .loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 6px solid #091c3b;
    border-right: 6px solid #091c3b;
    border-bottom: 6px solid #091c3b;
    border-left: 6px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

//toast
.Toastify {
  .Toastify__toast {
    border-radius: 5px;
    padding: 8px 28px;
  }
  .Toastify__toast--success{ 
    color: $white;
    font-family: $font-primary;
    background: #44d788;
  }
  .Toastify__toast--error{ 
    color: $white;
  }
  .Toastify__toast--warning{
    border-left: 4px solid #f1c40f; 
    color: $white;
  }
  .Toastify__toast-body{
    color: $white;

  }
  
}
.loader-center {
  margin-left: 48%;
  margin-top: 1%;
}
.loader-video{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  .app-c-loader{
    height: 100%;
    position: relative;
    @extend %flex-all-center;
    .loader{
      border-top: 2px solid #091c3b;
      border-right: 2px solid #091c3b;
      border-bottom: 2px solid #091c3b;
      border-left: 2px solid #ffffff;
      margin: 30px auto;
      width: 3em;
      height: 3em;  
    }
  }
}