.app-c-tab-wrapper {
  &.pull-top {
    margin-top: -33px;
    @include screen-sm() {
      margin-top: 21px;
    }
  }

  .cs-scroll {
    @include screen-sm() {
      //width: calc(100vw - 56px);
      width: 100%;
      overflow: auto;
    }
    @include screen-xs() {
      //width: calc(100vw - 40px);
      //overflow: auto;
    }
    .nav {
      flex-wrap: wrap;
      .nav-link {
        white-space: nowrap;
        text-align: center;
      }
    }
  }
  .nav {
    @extend %flex-align-center-justify-start-row;
  }
  .nav-link {
    all: unset;
    cursor: pointer;
    margin: 0;
  }
  &.rounded {
    .nav-link {
      @include border-radius(30px);
      padding: 7px 18px !important;
      display: inline-block;
      font-weight: $font-weight-semibold;
      font-family: $font-primary;
      font-size: 13px;
      color: $text-color;
      @include screen-xs() {
        line-height: 15.85px;
        padding: 9px 12px 7px 12px !important;
        font-size: 12px;
        @include cm-transition();
      }
      &.active {
        background: $app-brand-primary;
        @include boxShadow(0px 1px 2px 0px rgba(255, 171, 185, 1));
        -webkit-text-fill-color: rgba(255, 255, 255, 1) !important;
        color:rgba(255, 255, 255, 1) !important;
        @include screen-xs() {
          padding: 9px 33px 7px 33px !important;
        }
      }
    }
  }
  &.select-bg-box {
    min-height: 374px;
    &.show-flower {
      margin-top: -30px;
    }
    .nav-link {
      padding: 7px 27px !important;
    }
  }
  &.icon-tab {
    .nav {
      position: relative;
      margin-left: -15px;

      @include screen-md() {
        @include flex-wrap(wrap);
        @include justify-content(center);
        .nav-item {
          margin: 0;
          .nav-link {
            max-width: 100%;
          }
        }
      }
      @include screen-sm() {
        flex-grow: 1;
        flex-basis: 100%;
        @include inline-flex();
        @include flex-direction(row);
        @include align-items(center);
        margin-bottom: 10px;
        margin-left: 0px;
      }
      @include screen-xs() {
        .app-l-post-msg-block {
          width: 100%;
          margin-top: 10px;
          display: flex;
    justify-content: center;
        }
      }

      > * + * {
        border-left: 1px dashed $generic-border-color;
        @include screen-xs() {
          border-left: none;
        }
      }
    }
    .nav-link {
      padding: 7px !important;
      font-weight: $font-weight-bold;
      font-family: $font-primary;
      font-size: $text-md;
      color: $text-color;
      @extend %flex-align-center-justify-start-row;
      text-align: left;
      @include screen-xs() {
        max-width: 91px;
        padding: 5px !important;
      }

      &.active {
        color: $app-brand-primary !important;
      }
      i {
        font-size: 16px;
        margin-right: 15px;
        @include screen-xs() {
          margin-right: 8px;
          font-size: 14px;
        }
      }
      .tab-label {
        line-height: 14.63px;
        font-weight: $font-weight-bold;
        font-family: $font-primary;
        @include screen-xs() {
          font-size: 10px;
          line-height: 12.19px;
        }
      }
    }
  }
}
.app-l-post-edit {
  padding: 13px 13px 16px 15px !important;
  text-align: right;
}
.app-c-memorial-post__content {
  .app-l-post-edit {
    padding: 0;
  }
}
