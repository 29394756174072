.app-c-confirm-popup-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  @include border-radius(8px);
  @extend %flex-align-center-justify-space-between-col;
  background: rgba($white, 0.8);
}
.app-c-confirm-popup {
  width: 400px;
  height: 140px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  @include boxShadow(0px 0px 20px rgba(35, 59, 88, 0.2));
  @include border-radius(8px);
  @extend %flex-justify-space-between-col;
  @media (max-width: 440px) {
    width: 250px;
    height: fit-content;

  }
  .app-c-confirm-popup__body {
    text-align: center;
    padding: 16px;
    flex: 1 1;
    @extend %flex-justify-center-col;
  }
  .app-c-confirm-popup__footer {
    padding: 16px;
    text-align: right;
    border-top: 1px solid $generic-border-color;
  }
}
