@media (min-width: 992px) {
  .navbarImage {
    display: none;
  }
}
@include screen-md {
  .navbar-toggler {
    margin-right: 0;
    .line {
      @include transition(0.4s all);
    }
    &:not(.collapsed) {
      .line {
        &:nth-child(1) {
          transform: rotate(45deg);
          position: relative;
          top: 7px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          position: relative;
          top: -7px;
        }
      }
    }
  }

  .navbar-collapse {
    opacity: 0;
    transition: opacity 0.35s ease;
    &.collapsing {
      position: fixed;
      height: 90%;
      top: 70px;
      width: 50%;
      background: $neutrals-color3-light;
      &.default-page {
        background: $white;
      }
      @include border-radius(0 0 100px 0);
      @include boxShadow(rgba(93, 39, 48, 0.1) 1px 13px 8px 0px);
      .nav-link {
        font-family: $font-primary;
        font-weight: $font-weight-bold;
        letter-spacing: -0.03em;
        font-size: $lgs;
        line-height: 28px;
        padding: 11.5px 33px !important;
      }
    }
    &.show {
      position: fixed;
      height: 90%;
      top: 70px;
      opacity: 1;
      overflow: hidden;
      background: $neutrals-color3-light;
      @include border-radius(0 0 100px 0);
      @include boxShadow(rgba(93, 39, 48, 0.1) 1px 13px 8px 0px);
      &.default-page {
        background: $white;
      }
      width: 50%;
      .nav-link {
        font-family: $font-primary;
        font-weight: $font-weight-bold;
        letter-spacing: -0.03em;
        font-size: $lgs;
        line-height: 28px;
        padding: 11.5px 33px !important;
      }
    }
  }
}
@include screen-sm() {
  .navbar-collapse {
    &.collapsing {
      height: 90%;
      width: 100%;
      z-index: 999!important;
    }
    &.show {
      height: 90%;
      width: 100%;
      top: 90px;

    }
  }
}
//common
.navbarImage {
  position: absolute;
  bottom: 0;
  right: -100%;
  z-index: -1;
  opacity: 0;
  @include animation(1s bgTransition forwards);
}
@keyframes bgTransition {
  0% {
    opacity: 0;
    right: -100%;
  }
  100% {
    opacity: 1;
    right: -80px;
  }
}
