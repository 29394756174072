/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 15, 2021 */

$primary-fontpath: '../../../assets/fonts/custom-fonts/montserrat';
$secondary-fontpath: '../../../assets/fonts/custom-fonts/just-lovely';
$alternate-fontpath: '../../../assets/fonts/custom-fonts/worksans';

// JUST LOVELY WIDE
@font-face {
  font-family: 'just_lovelyregular';
  src: url('#{$secondary-fontpath}/just_lovely_regular-webfont.woff2') format('woff2'), url('#{$secondary-fontpath}/just_lovely_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
// WORK SANS
@font-face {
  font-family: 'worksans';
  src: url('#{$alternate-fontpath}/worksans-variablefont_wght-webfont.woff2') format('woff2'), url('#{$alternate-fontpath}/worksans-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
// MONTSERRAT

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('#{$primary-fontpath}/montserrat-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''), url('#{$primary-fontpath}/montserrat-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('#{$primary-fontpath}/montserrat-v15-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('#{$primary-fontpath}/montserrat-v15-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('#{$primary-fontpath}/montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('#{$primary-fontpath}/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$primary-fontpath}/montserrat-v15-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('#{$primary-fontpath}/montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('#{$primary-fontpath}/montserrat-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''), url('#{$primary-fontpath}/montserrat-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('#{$primary-fontpath}/montserrat-v15-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('#{$primary-fontpath}/montserrat-v15-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('#{$primary-fontpath}/montserrat-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('#{$primary-fontpath}/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('#{$primary-fontpath}/montserrat-v15-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('#{$primary-fontpath}/montserrat-v15-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('#{$primary-fontpath}/montserrat-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('#{$primary-fontpath}/montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('#{$primary-fontpath}/montserrat-v15-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('#{$primary-fontpath}/montserrat-v15-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('#{$primary-fontpath}/montserrat-v15-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('#{$primary-fontpath}/montserrat-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('#{$primary-fontpath}/montserrat-v15-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('#{$primary-fontpath}/montserrat-v15-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('#{$primary-fontpath}/montserrat-v15-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}
