.app-c-radion-check {
  position: relative;
  width: $radio-width;
  height: $radio-height;
  cursor: pointer;
  input[type='radio'],input[type='checkbox'] {
    width: $radio-width;
    height: $radio-height;
    position: relative;
    opacity: 0;
    cursor: pointer;

    &:checked + .app-c-radio-check__node {
      background: $app-brand-primary;
      &::after {
        opacity: 1;
      }
    }
  }
  .app-c-radio-check__node {
    position: absolute;
    top: 0px;
    left: 0px;
    width: $radio-width;
    height: $radio-height;
    content: '';
    background: $white;
    border: 1px solid $app-brand-primary;
    display: block;
    @include cm-transition();
    @include border-radius(50%);
    pointer-events: none;
    &::after {
      content: '\e910';
      font-family: 'memlo';
      color: $white;
      font-size: 7px;
      text-align: center;
      width: 100%;
      height: 100%;
      display: block;
      line-height: 14px;
      opacity: 0;
      font-weight: bold;
      @include cm-transition();
    }
    &.-new-radio {
      &:after {
        content: '';
        width: 10px;
        height: 10px;
        background: $app-brand-primary;
        border-radius: 50%;
        position: absolute;
        right: 50%;
        left: 50%;
        bottom: 50%;
        border: 2px solid white;
        transform: translate(-50%, 50%);
      }
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 31px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    @include transition(0.4s);
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 3px;
    background-color: $white;
    @include transition(0.4s);
  }

  input:checked + .slider {
    background-color: $radio-toggle;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $radio-toggle;
  }

  input:checked + .slider:before {
    @include transform(translateX(22px));
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
