//custom-selection color
::selection {
  color: #fff;
  background: #000;
}

::-moz-selection {
  color: #fff;
  background: #000;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: $body-font;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
}
body {
  font-family: $body-font !important;
  font-size: $text-lg; // 14px by default
  margin: 0;
  color: $text-color;
}
.dx-widget {
  font-family: $body-font;
  color: $text-color;
  font-size: $text-md;
}
a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
button {
  cursor: pointer;
}

b,
strong {
  font-weight: $font-weight-bold;
}
img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

// Custom Scrollbar
//@include scrollbars(7px, $gray-500);

.rtl {
  direction: rtl;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary!important;
  font-weight: $font-weight-bold;
  color: $text-color!important;
}
.handy-font {
  font-family: $handwritten-font;
  font-weight: normal;
  &.primary {
    color: $app-brand-primary;
  }
}
h1 {
  font-weight: $font-weight-bold;
  font-size: 20px;
  color: $text-color;
  margin-bottom: 5px;
}
h2 {
  font-weight: $font-weight-bold;
  font-size: 32px;
  line-height: 48px;
  color: $text-color;
  margin-bottom: 5px;
  .handy-font {
    font-size: 72px;

    @include screen-sm() {
      font-size: 56px;
      line-height: 40px;
    }
  }
  @include screen-sm() {
    font-size: 24px;
    line-height: 40px;
  }
  span {
    color: $app-brand-primary;
    font-family: $font-primary;
    font-weight: $font-weight-bold;
  }
}
h3 {
  font-weight: $font-weight-bold;
  font-size: 20px;
  color: $text-color;
  margin-bottom: 16px;
  line-height: 28px;
}
h4 {
  font-weight: $font-weight-bold;
  font-size: 12px;
  color: $text-color;
  margin-bottom: 5px;
}
h5 {
  font-weight: $font-weight-bold;
  font-size: $text-xl;
  line-height: 24px;
  color: $text-color;
  margin-bottom: 5px;
}

p {
  font-size: $text-lg;
  line-height: 20px;
  color: $text-color;
  &.lg {
    font-size: $text-xl;
    @include screen-sm() {
      font-size: $text-lg;
    }
  }
}
.rs-picker-date-menu {
   z-index: 999999!important;
}

.autocomplete-dropdown-container {
  top: auto;
  bottom: 100%;
  width: 100%;
}
.input-sugggestion {
  top: auto;
  bottom: 100%;
}
