%inline-flex-all-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
%flex-row {
  flex-direction: row;
}
%flex-column {
  flex-direction: column;
}
%flex-wrap {
  @include flex-wrap(wrap);
}
%flex-grow-1 {
  @include flex-grow(1);
}
%flex-and-col {
  @extend %flexbox;
  @extend %flex-column;
}
%flex-and-row {
  @extend %flexbox;
  @extend %flex-row;
}
%justify-center {
  justify-content: center;
}
%justify-space-between {
  justify-content: space-between;
}
%flex-justify-space-around {
  display: flex;
  justify-content: space-around;
}
%flex-and-align-center {
  display: flex;
  align-items: center;
}
%inline-flex-and-align-center {
  display: inline-flex;
  align-items: center;
}
%flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}
%flex-align-center-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
%flex-justify-center-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
%flex-align-start-row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
%flex-align-start-col {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
%flex-align-center {
  display: flex;
  align-items: center;
}
%flex-justify-center-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
%flex-justify-start-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
%flex-align-center-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
%flex-align-start-justify-start-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
%flex-align-center-justify-start-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
%flex-align-center-justify-start-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
%inline-flex-align-center-justify-center-col {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
%flex-align-start-justify-center-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
%flex-align-start-justify-center-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
%flex-align-center-justify-space-between-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
%flex-align-start-justify-space-between-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

%flex-all-center-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

%flex-align-center-justify-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-align-center-justify-end-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
%flex-align-center-justify-space-between-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
%align-center-justify-start-row {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

%align-start-justify-center-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

%flex-justify-space-between-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

%flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
%flex-align-end-col {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
%inline-flex-all-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

%flex-horiz-center {
  display: flex;
  justify-content: center;
}

%flex-vert-center {
  display: flex;
  align-items: center;
}

%flex-vert-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

%flex-horiz-away-vert-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
%color-generator {
  &.bg-1 {
    background-color: $neutrals-color1;
  }
  &.bg-2 {
    background-color: $neutrals-color2;
  }
  &.bg-3 {
    background-color: $neutrals-color3;
  }
  &.bg-4 {
    background-color: $neutrals-color4;
  }
  &.bg-5 {
    background-color: $neutrals-color5;
  }
  &.bg-6 {
    background-color: $neutrals-color6;
  }
  &.bg-0 {
    background-color: $neutrals-color7;
  }
}
%common-text-prop-primary {
  color: $text-color;
  letter-spacing: -0.03em;
  font-family: $font-primary;
}
%common-text-prop-secondary {
  color: $text-color;
  letter-spacing: -0.03em;
  font-family: $font-secondary;
}
%common-text-prop-normal {
  color: $text-color;
  letter-spacing: -0.03em;
}
