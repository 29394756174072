$unpublished: #c2c6ce;
button {
  @include cm-transition();
}
.btn-link {
  text-decoration: none;
  &:focus,
  &:active {
    @include noShadow();
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.temp {
  align-self: center;
}
.app-c-btn {
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  font-weight: 500;
  color: $text-color;
  font-size: $text-lg;
  outline: none;
  font-family: $font-primary;
  max-width: 100%;
  min-width: 0;
  font-size: $text-lg;
  font-weight: $font-weight-extra-bold;
  line-height: 17.07px;
  padding: 15px 28px;
  -webkit-appearance: initial;

  > * {
    margin-left: 8px;
  }

  @include screen-sm() {
    margin: 0;
    padding: 13px 35px 12px 35px;
    font-size: 12px;
    line-height: 14.63px;
  }

  @include transition(background ease 0.3s, color ease 0.3s, border ease 0.3s, box-shadow ease 0.3s);
  @include inline-flex();
  @include align-items(center);
  @include border-radius(8px);
  @extend %truncate;
  &.sm {
    line-height: 14.63px;
    padding: 13px 24px 11px 24px;
    font-size: $text-md;
  }
  &.xs {
    line-height: 15.85px;
    padding: 7px 18px;
    font-size: 12px;
    font-weight: $font-weight-semibold;
  }
  &.rounded {
    font-weight: $font-weight-semibold;
    font-family: $font-primary;
    @include border-radius(30px !important);
  }
  &.lined {
    border: 1px solid $neutrals-color3;
    background: none;
    color: $neutrals-color3;
    &:hover {
      background: $neutrals-color3;
      color: $white;
    }
    &:focus {
      outline: none;
    }
  }

  + .app-c-btn {
    margin-left: 8px;
    .rtl &,
    [dir='rtl'] & {
      margin-left: 0px;
      margin-right: 8px;
    }
  }

  &:hover {
    text-decoration: none;
    outline: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none; // @include boxShadow(inset 0 3px 5px rgba(0,0,0,.125));

    @include noShadow();
  }

  &:focus {
    @include noShadow();
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    opacity: 0.6;
    pointer-events: none;

    @include boxShadow(none);

    &:hover,
    &.hover {
      @include boxShadow(none);
    }
  }
}

.app-c-btn--primary {
  background: $app-brand-primary;
  border-color: $app-brand-primary;
  color: $white;
  min-width: 80px;
  text-decoration: none;
  @include justify-content(center);
  @include boxShadow(0px 1px 2px 0px rgba(255, 171, 185, 1));

  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: $app-brand-primary-dark;
    border-color: $app-brand-primary-dark;
    outline: none;
    box-shadow: 0px 8px 16px rgba(26, 39, 126, 0.16);
    color: $white;
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0px 8px 16px rgba(26, 39, 126, 0.16);
  }
  &[disabled] {
    background: $app-brand-primary;
    border-color: $app-brand-primary;
    cursor: not-allowed;
  }
}

.app-c-btn--secondary {
  background: $white;
  border: 1px solid #bec1d2;
  color: $text-color;
  min-width: 80px;

  @extend %justify-space-between;
  i {
    color: $text-color-light;
  }
  &:hover,
  &:active,
  &:focus {
    outline: none;
    background: $app-brand-primary-disable;
  }

  &[disabled] {
    color: $text-disabled;
    background: $neutrals-color4;
    border-color: lighten(desaturate($app-brand-primary, 61.41), 65.49);
  }
  &.clear {
    color: $app-brand-primary;
    border: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

.app-c-btn--link {
  background: transparent;
  border-color: transparent;
  color: $app-brand-primary;
  text-decoration: underline;
  font-size: 12px;

  &:hover {
    color: $app-brand-primary;
    text-decoration: underline;

    @include noShadow();
  }
}

.app-c-card-btn {
  border: none;
  padding: 16px;
  margin-top: 8px;
  @extend %flex-align-center-justify-start-row;
  flex-basis: 100%;
  width: 100%;
  @include border-radius(16px);
  font-size: $text-md;
  font-weight: $font-weight-bold;
  font-family: $font-primary;
  position: relative;
  @include cm-transition();
  -webkit-appearance: initial;
  &.--checkbox {
    input{
      z-index: 1;
    }
    &.--full-checkbox {
      @extend %flex-align-start-justify-center-col;
      background: $white;
      border: 1px solid #e6e8ec;
      border-radius: 16px;
      &:hover {
        background-color: #f8f8f8;
      }
      .main-label {
        font-size: 12px;
        font-family: $font-primary;
        font-weight: $font-weight-bold;
        line-height: 15px;
        &--sub-title {
          font-size: 10px;
          font-family: $font-secondary;
          line-height: 24px;
          opacity: 0.6;
        }
      }
    }
    &.not-published {
      background: #f7f7f7;
      color: $unpublished;
      i,
      em,
      span {
        color: $unpublished;
        &:after,
        &:before {
          color: $unpublished;
        }
      }
      &:hover {
        @include boxShadow(none);
      }
    }

    &::after {
      display: none;
    }
    .custom-checkbox {
      position: absolute;
      right: 10px;
    }
  }
  &:focus {
    outline: none;
  }
  &.bg1 {
    background: $neutrals-color1-light;
    &::after {
      color: $neutrals-color1;
    }
    &:hover {
      @include boxShadow(0px 0px 0px 1px $neutrals-color1);
    }
  }
  &.bg2 {
    background: $neutrals-color2-light;
    &::after {
      color: $neutrals-color2;
    }
    &:hover {
      @include boxShadow(0px 0px 0px 1px $neutrals-color2);
    }
  }
  &.bg3 {
    text-decoration: none;
    color: inherit;
    background: $neutrals-color3-light;
    &::after {
      color: $neutrals-color3;
    }
    &:hover {
      @include boxShadow(0px 0px 0px 1px $neutrals-color3);
    }
  }
  &.bg4 {
    text-decoration: none;
    color: inherit;
    background: $neutrals-color6-light;
    em,
    span {
      color: $neutrals-color6;
    }
    &::after {
      color: $neutrals-color6;
    }
    &:hover {
      @include boxShadow(0px 0px 0px 1px $neutrals-color6);
    }
  }
  &:hover {
    &::after {
      right: 15.5px;
    }
  }
  span,
  em {
    width: 20px;
    margin-right: 12px;
    font-size: 15px;
  }
  i {
    font-family: 'memlo' !important;
    font-style: normal;
  }
  &::after {
    @include cm-transition();
    content: '\e955';
    position: absolute;
    font-family: 'memlo';
    right: 20.5px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 18px;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
  }
}
.background-image-action{
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;
  width: 161px;
  z-index: 6;
  flex-wrap: wrap;
  z-index: 999;
  flex-direction: column;
  @extend %flex-all-center;
  @media (max-width:992px){
    top:60px;
    width: 100%;
  }
}
.app-c-link-btn {
  // all: unset;
  border: 0px;
  background: transparent;
  color: $neutrals-color3;
  font-family: $font-primary;
  font-size: $text-md;
  font-weight: $font-weight-bold;
  line-height: 14.63px;
  white-space: nowrap;
  @extend %inline-flex;
  @extend %align-center-justify-start-row;
  outline: none;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
    pointer-events: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow:none;
  }
  &.inline {
    display: inline-block;
    vertical-align: middle;
  }
  &--change {
    i {
      font-size: 22px;
      margin-right: 5px;
    }
    @include screen-md() {
      top: 36px;
    } 
  }
  &.load-more {
    i {
      font-size: 16px;
      margin-right: 8px;
    }
  }
  &.delete-btn {
    color: $text-color;
  }
  &:hover {
    color: $neutrals-color3-dark;
    background: none;
    i {
      background: $neutrals-color3-light;
    }
  }
  i + * {
    margin-left: 4px;
  }
  .app-c-link-btn__text + * {
    margin-left: 8px;
  }
  i {
    line-height: 21px;
    font-size: 15px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;

    text-align: center;
    @include border-radius(50%);
    @include cm-transition();
  }
}

.app-c-report-btn {
  outline: none !important;
  all: unset;
  color: rgba(9, 30, 62, 1);
  white-space: nowrap;
  @extend %flex-align-center-justify-start-row;
  @include screen-xs() {
    margin-top: 7px;
    &:focus {
      outline: none !important;
    }
  }
  &:hover {
    color: $app-brand-primary;
  }
  &.active {
    color: $app-brand-primary;
    cursor: auto;
    outline: none !important;
  }
  i {
    font-size: 10px;
    margin-right: 8px;
  }
  span {
    text-decoration: underline;
    font-size: $text-md;
    line-height: 24px;
    @include screen-xs() {
      display: none;
    }
  }
}

.app-c-browse-btn {
  all: unset;
  color: $neutrals-color3 !important;
  font-family: $font-primary;
  font-size: $text-lg;
  font-weight: $font-weight-medium;
  line-height: 28px;
  text-decoration: underline !important;
  input {
    display: none;
  }
}

.app-close-btn {
  all: unset;
  width: 32px;
  height: 32px;
  @include border-radius(50%);
  border: none;
  background: $app-brand-primary;
  color: $white;
  font-size: 16px;
  line-height: 34px;
  &:hover {
    background: $app-brand-primary-dark;
  }
}
.app-c-btn--clear {
  outline: none !important;
  all: unset;
  @extend %flexbox;
  @include align-items(center);
  em {
    color: $app-brand-primary;
    margin-right: 6.5px;
  }
  .btn-title {
    color: $app-brand-primary;
    font-family: $font-primary;
    font-size: $text-lg;
    font-weight: $font-weight-semibold;
  }

  &:hover {
    .btn-title,
    em {
      color: $app-brand-primary-dark;
    }
  }
}
.app-c-edit {
  padding: 8px 0;
  margin-top: 10px;
  background: $app-brand-primary;
  @extend %flex-all-center;
  @include border-radius(10px);
  text-align: center;
  color: $white;
  &:hover,
  &:active,
  &:focus {
    background: $app-brand-primary-dark;
    border-color: $app-brand-primary-dark;
    outline: none;
    box-shadow: 0px 8px 16px rgba(26, 39, 126, 0.16);
    color: $white;
  }
  .icon-mob {
    display: none;
    @include screen-sm() {
      display: block;
      margin: 0 5px;
    }
  }
}
.app-c-close {
  position: absolute;
  right: 0px;
  top: -8px;
  @include border-radius(50%);
  min-height: 32px;
  max-width: 32px;
  width:32px;
  background: $app-brand-primary;
  color: $white;
  z-index: 1;
  @extend %flex-all-center;
  &:hover,
  &:active,
  &:focus {
    background: $app-brand-primary-dark;
    border-color: $app-brand-primary-dark;
    outline: none;
    box-shadow: 0px 8px 16px rgba(26, 39, 126, 0.16);
    color: $white;
  }
  &.profile {
    z-index: 2;
    background: $gray-dark;
    box-shadow: none;
    right: -10px;
    color: $white !important;
    .icon-cross {
      color: $white;
    }
    &.dashboard {
      left: 78px;
      right: none;
      top:-8px;
    }
  }
}
.app-c-btn--primary-outline {
  background: transparent;
  border-color: $app-brand-primary;
  color: $app-brand-primary;
  text-decoration: none;
  @include justify-content(center);
  @include boxShadow(0px 1px 2px 0px rgba(255, 171, 185, 1));
  box-shadow: none;
  width: 100%;
  height: 45px;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 600;
  margin-top: 7px;
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: $app-brand-primary-dark;
    border-color: $app-brand-primary-dark;
    outline: none;
    box-shadow: 0px 8px 16px rgba(26, 39, 126, 0.16);
    color: $white;
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0px 8px 16px rgba(26, 39, 126, 0.16);
  }
}
.clear-btn-type-1{
  position: absolute;
  bottom: 8px;
  right: 5px;
}
.form-control.with-clear{
  padding-right: 30px;
}
.tribute-library-btn {
  position: relative;
  left: -4%;
}

.show-attendees {
  color: $app-brand-primary;
  text-decoration: underline;
}

.tribute-disabled {
  cursor: $cursor-disabled;
  opacity: 0.6;
  pointer-events: none;
}