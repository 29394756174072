.app-header {
  position: relative;
  z-index: 5;
  padding: 32px 0px;
  min-height: 90px;

  @include screen-lg() {
    padding: 20px 0px;
    z-index: 9999;
  }

  .navbar-expand-lg {
    @extend %justify-space-between;
  }

  .navbar {
    padding: 0;
    @include screen-sm {
      flex-wrap: nowrap;
    }
  }

  &.invert {
    .app-h-brand {
      .logo-shape {
        @include screen-sm() {
          position: relative;
          top: -2px;
        }
      }

      .logo-text {
        @include screen-sm() {
          display: block;
        }
      }
    }

    .app-c-go-back:hover {
      color: $text-color;
    }

    .white-texting {
      color: $text-color;
    }
  }

  .container {
    position: relative;
  }

  &__user-tag {
    padding: 5px;

    &.-user-icon {
      margin-left: 30px;

      @media (max-width: 1188px) {
        margin-left: auto;
      }
    }

    @extend %flex-align-center-justify-start-row;

    .primary-texting:hover {
      color: $app-brand-primary-dark;
    }
  }
}

.app-c-go-back {
  display: inline-block;
  background-color: transparent;
  border: 0;
  @include cm-transition();

  @include screen-sm() {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &.primary {
    color: $app-brand-primary;

    // &:hover {
    //   color: $white;
    // }

    span {
      margin-right: 6.5px;
      position: relative;
      top: 1px;
    }

    &.preview:hover {
      color: #ed8294;
    }
  }
}

.app-header__wrapper {
  @extend %flex-align-center-justify-space-between-row;
  flex-grow: 1;

  @include screen-sm {
    flex-grow: 0;
    @include justify-content(flex-end);
  }
  @include screen-xs {
    flex-grow: 1;
    .app-c-profile-dropdown {
      button {
        padding: 0 !important;
        min-width: auto;
      }
    }

    .app-c-profile-dropdown {
      margin-right: 0;
      .app-header__user-tag {
        padding-right: 0;
      }
    }
  }

  button + a,
  a + a {
    margin-left: 8px;
  }

  a,
  a:hover {
    text-decoration: none;
  }
}

.app-header__right {
  @extend %flex-align-center-justify-end-row;
  order: 2;

  &.content-right {
    flex-grow: 1;
  }

  .dropdown-menu {
    padding: 0;
    font-size: $text-md;
    a + a {
      margin-left: 0;
    }

    @include screen-md() {
      right: 0 !important;
      left: auto !important;
      &:before {
        display: none;
      }
    }

    @include screen-xxs() {
      left: auto !important;

      &:before {
        display: none;
      }
    }

    a {
      font-size: 12px;
      font-family: $font-primary;
      font-weight: 500;
      padding: 12px 10px;
      font-size: $text-md;

      &.dropdown-item:active {
        color: $text-color;
        text-decoration: none;
        background-color: #fdf3f4;
        @include border-radius(8px);
      }
    }
  }

  .app-c-profile-dropdown {
    .show.dropdown-menu {
      @include screen-md() {
        left: auto !important;
        right: 0 !important;

        &:before {
          display: none;
        }
      }
    }
  }
  a {
    .app-c-btn {
      @include screen-md() {
        padding: 13px 10px 11px !important;
        min-width: auto !important;
      }
    }
  }
}
.German{
  .app-header__right {

    a {
      .app-c-btn {
        @include screen-md() {
          padding: 13px 2px 11px !important;
          min-width: auto !important;
          font-size: 11px;
        }
      }
    }
  }
}
.app-c-language-switch {
  display: inline-flex;
  margin-right: 8px;

  .dropdown-menu {
    transform: translateY(-12px);
  }

  .dropdown-item {
    padding: 5px 18px !important;
  }

  button {
    outline: none !important;
  }

  .show {
    .dropdown-menu {
      padding: 5px 0 !important;
      transform: translateY(2px);
      @include border-radius(10px 10px);
      @include screen-sm {
        right: 0 !important;
        left: auto;
      }
      .dropdown-item {
        padding: 10px 18px 10px 18px;
        min-width: 150px;
        @include border-radius(10px);
        display: flex;
        align-items: center;

        &:focus {
          background-color: #f8f9fa;
          color: $text-color;
        }

        .app-c-language-text {
          margin-left:18px;
          font-size: $text-lg;
        }

        .app-c-language-flag {
          width: 17px;
          height: 19px;
          object-fit: cover;
        }
      }

      &:before {
        content: '';
        position: absolute;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid white;
        border-top: 5px solid transparent;
        top: -9px;
        right: 25px;

        @include screen-md() {
          right: 58px;
        }
      }
    }

    .dropdown-toggle {
      color: $text-color;
      background-color: $white;
      border-color: $text-color;
      @include boxShadow(none);

      &:focus,
      &:active,
      &:hover {
        color: $text-color;
        background-color: $white;
        border-color: $app-brand-primary;
        @include boxShadow(none);
      }
    }
  }

  .app-c-btn {
    background-color: $white;
    border: 1px solid rgba(9, 30, 62, 0.2);
    @include border-radius(8px);
    width: 173px;
    position: relative;
    padding-left: 48px;
    padding-right: 40px;
    height: 40px;

    &:focus {
      outline: none;
    }

    @include screen-sm {
      width: auto;
      padding-right: 14.5px;
      padding-left: 14px;
      min-width: 61px;
    }

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      border-color: $app-brand-primary;
      background-color: $white;
      @include boxShadow(none);
    }

    .app-c-language-text {
      font-size: $text-md;
      color: $text-color;
      font-family: $font-primary;
      font-weight: $font-weight-medium;
      @extend %truncate;
      @include screen-sm {
        display: none;
      }
    }

    &::after {
      content: '\e903';
      font-family: 'memlo';
      position: absolute;
      right: 18px;
      top: 12px;
      font-size: 10px;
      color: $app-brand-primary;
      border: 0;

      @include screen-sm {
        right: 14px;
        color: $text-color;
      }
    }

    .app-c-language-flag {
      position: absolute;
      width: 18px;
      height: 15px;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 16px;
      line-height: normal;
      display: block;

      @include screen-sm {
        left: 14px;
      }

      img {
        width: 18px;
        height: 14px;
        margin: 0;
        padding: 0;
        display: block;
      }
    }
  }

  button + button {
    margin-left: 16px;
  }

  .app-c-link-btn {
    padding: 0px 20px;
  }

  // @media (max-width: 360px) {
  //   display: none;
  // }
}

.app-brand-wrapper {
  @extend %flex-align-center-justify-start-row;
  @include screen-sm() {
    margin-right: auto;
  }
}

.app-h-brand {
  // all: unset;
  background-color: transparent;
  border: 0;
  @extend %flex-align-center-justify-start-row;
  margin-right: 24.18px;

  @include screen-sm() {
    margin-right: 0;
  }

  .app-c-go-back {
    display: none;

    @include screen-sm() {
      display: block;
      margin-right: 44.27px;
    }
  }

  .logo-shape {
    height: 24.07px;
    @include screen-sm {
      margin-right: 10px;
    }
    img {
      height: 24.07px;
    }
  }

  .logo-text {
    margin: 0px 7.84px;
    display: block;

    @include screen-sm {
      display: none;
    }

    img {
      height: 19.13px;
    }
  }
}

.navbar-toggler {
  padding: 0px;
  margin: 0 0 0 0 !important;
  border: none;
  margin-right: 0 !important;
  position: relative;

  @include screen-sm {
    padding: 3px 7px;
  }
  &:focus {
    outline: none;
  }

  span {
    height: 2px;
    width: 26px;
    margin: 5px 0px;
    background: $text-color;
    display: block;
    @include border-radius(2px);
  }
}

.navbar-collapse {
  @include screen-md {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    background: #fff;
    padding: 20px 0px;
    @include border-radius(6px);
    @include boxShadow(0px 4px 8px 0px rgba(93, 39, 48, 0.1));
  }

  .nav-link {
    @include screen-md() {
      margin: 0;
      padding: 8px 20px;
      display: block;
    }
  }
}

.nav-link {
  font-size: 14px;
  color: $text-color !important;
  font-weight: $font-weight-medium;
  padding: 6px !important;
  font-family: $font-primary;
  margin-left: 40px;
  @include transition(all ease 0.2s);
  @include screen-xl() {
    margin-left: 30px;
  }
  @include screen-lg() {
    margin-left: 16px;
    font-size: 12px;
  }

  &:hover {
    color: $app-brand-primary !important;
  }
}

.user-tag-avatar {
  @include border-radius(50%);
  display: block;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  overflow: hidden;
  background: #ed8294;

  &.text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 40px;
  }
}

.user-tag-texting {
  display: block;
  margin-right: 16px;
  text-align: right;

  @include screen-sm() {
    display: none;
  }

  &.text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .white-texting {
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: auto;
  }
}

.create-preview {
  .icon-home {
    font-size: 8px !important;
  }
  max-width: 80px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: auto;
}

.white-texting,
.primary-texting {
  line-height: 14.63px;
  font-size: $text-md;
  font-family: $font-primary;
  font-weight: $font-weight-semibold;
  color: $white;
  display: block;
  margin: 4px 0px;
}

.userName-tag {
  &.white-texting {
    max-width: 90px;
    @extend %truncate;
    text-align: right;
    text-transform: capitalize;
    padding: 0 6px;
    margin-left: auto;
  }
}

button {
  &.primary-texting {
    background-color: transparent;
    text-align: right;
  }
}

.primary-texting {
  color: $app-brand-primary;
}

.memlo-header--logo {
  @extend %flex-align-center-justify-start-row;

  .logo-text {
    margin: 0px 7.84px;

    @include screen-md() {
      display: none;
    }
  }
}

.preview {
  cursor: auto !important;
}
.logo-shape {
  @include screen-sm {
    margin-right: auto;
    padding-right: 10px;
  }
}
