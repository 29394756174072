.simplebar-track {
  @include screen-sm() {
    display: inherit;
  }
  &.simplebar-horizontal {
    .simplebar-scrollbar {
      height: 8px !important;
      top: 2px !important;
    }
  }
}
